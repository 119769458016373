<script setup>

import { ref, watch, onMounted, reactive, defineProps } from 'vue'
import axios from 'axios'
import qs from 'qs'
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'
import VueMultiselect from 'vue-multiselect'

const props = defineProps({
  chatbotId: String,
  appointmentConfirmationMessage: String,
  eventTitle: String,
  eventDescription: String,
  currentSelectedCalendars: Array,
  calendarOptions: Array,
  toggleTrigger: Boolean
})

const selectedCalendars = ref([])

onMounted(() => {
  selectedCalendars.value = props.currentSelectedCalendars
})

watch(props, (newProps) => {
  if (newProps.toggleTrigger !== minimized.value) {
    minimized.value = !minimized.value
  }
})

const savingStates = reactive({
  appointmentConfirmationMessage: false,
  eventDescription: false,
  eventTitle: false,
  selectedCalendars: false
})

const editModes = reactive({
  appointmentConfirmationMessage: false,
  eventDescription: false,
  eventTitle: false,
  selectedCalendars: false
})

const state = reactive({
  appointmentConfirmationMessage: props.appointmentConfirmationMessage,
  eventDescription: props.eventDescription,
  eventTitle: props.eventTitle,
  selectedCalendars: selectedCalendars.value
})

const initialState = reactive({
  appointmentConfirmationMessage: props.appointmentConfirmationMessage,
  eventDescription: props.eventDescription,
  eventTitle: props.eventTitle,
  selectedCalendars: selectedCalendars.value
})

const toggleEditMode = (field) => {
  editModes[field] = !editModes[field]
  if (!editModes[field]) {
    state[field] = initialState[field]
  }
}

const updateField = async (field, value) => {
  savingStates[field] = true

  let payloadValue = value;
  if (field === 'selectedCalendars') {
    payloadValue = value.map(calendar => calendar.value);
  }

  try {
    const response = await axios.post(`/ajax/chatbots/${props.chatbotId}/update_fields`, {
      field: field,
      value: payloadValue
    })

    if (response.status === 200) {
      initialState[field] = value
      editModes[field] = false
      mdtoast.success(`${field} updated.`, { timeout: 10000 })
    } else {
      console.error("Failed to update field:", response.data)
    }
  } catch (error) {
    console.error("Error updating field:", error)
  } finally {
    savingStates[field] = false
  }
}

const saveChanges = (field) => {
  if (field === 'selectedCalendars') {
    updateField(field, selectedCalendars.value);
  } else {
    updateField(field, state[field]);
  }
}

const minimized = ref(false)

const minimize = () => {
  minimized.value = !minimized.value
}

</script>

<template>
  <div class="w-96 max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    <div class="absolute top-2 right-2 group z-10">
      <!-- Trigger -->
      <div class="cursor-pointer w-6 text-right relative">
        ...
        <div class="absolute inset-0 z-0 group-hover:block"></div>
      </div>

      <!-- Content -->
      <div class="absolute right-0 mt-0 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 hidden group-hover:block">
        <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <a href="#" @click.prevent="minimize()" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Toggle</a>
        </div>
      </div>
    </div>
    <div class="flex flex-col  p-3">
      <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white pt-8 text-center">🗓️ Appointment Intent</h5>
      <span class="text-sm text-gray-500 dark:text-gray-400 text-center">Scheduling Appointments</span>
      <div class='text-center'>

        <label class="relative inline-flex items-center cursor-pointer mt-4">
          <input type="checkbox" value="" class="sr-only peer" checked>
          <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">⚡️</span>
        </label>
      </div>

      <div class="p-3" v-if="!minimized">
        <label for="appt_confirmation_message" class="pt-4 block mb-2 text-sm font-medium text-gray-400 dark:text-white">Appointment Confirmation Message <a @click="toggleEditMode('appointmentConfirmationMessage')" class="text-blue-500 cursor-pointer">Edit</a></label>
        <textarea v-if="editModes.appointmentConfirmationMessage" v-model="state.appointmentConfirmationMessage" id="appt_confirmation_message" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Your appointment is confirmed. You will receive an email shortly."></textarea>
        <p class="py-0 my-0" v-else>{{ state.appointmentConfirmationMessage }}</p>
        <div v-if="!savingStates.appointmentConfirmationMessage">
          <button  class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2" v-if="editModes.appointmentConfirmationMessage" @click="saveChanges('appointmentConfirmationMessage')">Save</button><br v-if="editModes.appointmentConfirmationMessage">
        </div>
        <div v-if="savingStates.appointmentConfirmationMessage">
          <button disabled  v-if="editModes.appointmentConfirmationMessage" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2">
            <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
            </svg>
            Saving...
          </button>
        </div>

        <small class='text-gray-400 pb-6'>The confirmation message the bot says to the user after scheduling.</small>
      </div>

      <div class="p-3" v-if="!minimized">
        <label for="event_description"  class="pt-4 block mb-2 text-sm font-medium font-medium text-gray-400 dark:text-white">Event Description <a @click="toggleEditMode('eventDescription')" class="text-blue-500 cursor-pointer">Edit</a></label>
          <textarea v-if="editModes.eventDescription" v-model="state.eventDescription" id="event_description" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="The event description that attendees will seein their calendar."></textarea>
          <p v-else>{{ state.eventDescription }}</p>
          <div v-if="!savingStates.eventDescription">
            <button  class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2" v-if="editModes.eventDescription" @click="saveChanges('eventDescription')">Save</button><br v-if="editModes.eventDescription">
          </div>
          <div v-if="savingStates.eventDescription">
            <button disabled  v-if="editModes.eventDescription" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2">
              <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
              </svg>
              Saving...
            </button>
          </div>
          <small class='text-gray-400 pb-6'>This will be the calendar event description/summary.</small>
      </div>

      <div class="p-3" v-if="!minimized">
        <label for="event_title" class="pt-4 block mb-2 text-sm font-medium text-gray-400 dark:text-white">Event Title <a @click="toggleEditMode('eventTitle')" class="text-blue-500 cursor-pointer">Edit</a></label>
        <input v-if="editModes.eventTitle" v-model="state.eventTitle" type="text" name="event_title" id="event_title" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Type Chatbot name" required="">
        <p v-else>{{ state.eventTitle }}</p>
        <div v-if="!savingStates.eventTitle">
            <button  class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2" v-if="editModes.eventTitle" @click="saveChanges('eventTitle')">Save</button><br v-if="editModes.eventTitle">
          </div>
          <div v-if="savingStates.eventTitle">
            <button disabled  v-if="editModes.eventTitle" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2">
              <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
              </svg>
              Saving...
            </button>
          </div>
        <small class='text-gray-400'>The title of the calendar event.</small>
      </div>

      <div class="p-3" v-if="!minimized">
        <label for="appt_confirmation_message" class="pt-4 block mb-2 text-sm font-medium text-gray-400 dark:text-white">Calendars <a @click="toggleEditMode('selectedCalendars')" class="text-blue-500 cursor-pointer">Edit</a></label>
        <VueMultiselect
          v-if="editModes.selectedCalendars"
          class="mt-2"
          v-model="selectedCalendars"
          :options="props.calendarOptions"
          multiple="true"
          label="name"
          track-by="value"
          >
        </VueMultiselect>
        <p v-else>{{ selectedCalendars.map((s) => s.name).join(", ") }}</p>
        <div v-if="!savingStates.selectedCalendars">
            <button  class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2" v-if="editModes.selectedCalendars" @click="saveChanges('selectedCalendars')">Save</button><br v-if="editModes.selectedCalendars">
          </div>
          <div v-if="savingStates.selectedCalendars">
            <button disabled  v-if="editModes.selectedCalendars" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2">
              <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
              </svg>
              Saving...
            </button>
          </div>
        <small class='text-gray-400'>The calendar(s) you'll schedule appointments onto via the bot.</small>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
