<script setup>
import { ref, onMounted, defineProps } from 'vue'
import axios from 'axios'
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

const daysAgo = (days) => {
  const d = new Date()
  d.setDate(d.getDate() - days - 3)
  return d
}

const monthAgo = () => {
  const d = daysAgo(0)
  d.setMonth(d.getMonth() - 1)
  return d
}

const props = defineProps({
  locationId: String
})

const since = ref(monthAgo().toISOString())

const chartLoading = ref(true)
const statCards = ref({})

const chartData = ref({
  labels: ['January', 'February', 'March'],
  datasets: [  { data: [40, 20, 12] } ],
})

const chartOptions = ref({
  responsive: true
})


onMounted(() => {
  getPerformanceStats(since.value)
})

const getPerformanceStats = (isoDateStr) => {
  axios.get(`/ajax/locations/${props.locationId}/daily_metrics?since=${isoDateStr}`).then((response) => {
    chartData.value = response.data.chart
    statCards.value = response.data.cards
    chartLoading.value = false
  }).catch((error) => {
    console.log(error)
  })
}

const changePeriod = (date) => {
  let isoDateStr = date.toISOString()

  axios.get(`/ajax/locations/${props.locationId}/daily_metrics?since=${isoDateStr}`).then((response) => {
    chartData.value = response.data.chart
    statCards.value = response.data.cards
    chartLoading.value = false
    since.value = isoDateStr
  }).catch((error) => {
    console.log(error)
  })
}

const metricsKeys = [{
  key: 'businessImpressionsDesktopMaps',
  title: 'Desktop Maps Impressions'
}, {
  key: 'businessImpressionsDesktopSearch',
  title: 'Desktop Search Impressions'
}, {
  key: 'businessImpressionsMobileMaps',
  title: 'Mobile Map Impressions'
}, {
  key: 'businessImpressionsMobileSearch',
  title: 'Mobile Search Impressions'
}, {
  key: 'callClicks',
  title: 'Call Clicks'
}, {
  key: 'websiteClicks',
  title: 'Website Clicks'
}, {
  key: 'businessConversations',
  title: 'Business Messages'
}, {
  key: 'businessDirectionRequests',
  title: 'Direction Requests'
}]
</script>

<template>
  <div class='px-4 mx-4'>
    <div v-if="chartLoading">
      <div role="status">
        <svg aria-hidden="true" class="mr-auto ml-auto w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
        </svg>
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div class="inline-flex rounded-md float-right p-6">
        <a @click.prevent="changePeriod(monthAgo())" href="#" aria-current="page" class="px-4 py-2 text-sm font-medium text-blue-700 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
          1 Month
        </a>
        <a @click.prevent="changePeriod(daysAgo(60))" href="#" class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
          60 Days
        </a>
        <a @click.prevent="changePeriod(daysAgo(90))" href="#" class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
          90 Days
        </a>
      </div>
      <Line
        id="my-chart-id"
        :options="chartOptions"
        :data="chartData" />
    </div>
    <div class='p-4 m-4'>
      <h3 class="text-lg font-medium leading-6 text-gray-900">Since {{ new Date(since).toDateString() }}</h3>
      <dl class="grid grid-cols-1 md:grid-cols-5 gap-[1px] mt-5 ">
        <div v-for="{ title, key } in metricsKeys" :key="key" class="px-4 py-5 sm:p-6 metrics-item">
          <dt class="text-base font-normal text-gray-900">{{ title }}</dt>
          <dd class="mt-1 flex items-baseline justify-between md:block lg:flex">
            <div class="flex items-baseline text-2xl font-semibold text-gray-900">
              {{ statCards[key] }}
            </div>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<style>
.metrics-item {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
}
</style>
