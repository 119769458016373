import { Controller } from '@hotwired/stimulus'
import securedAxios from '../init/axios'
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'

export default class extends Controller {
  static values = {
    organizationId: {
      type: String,
      default: ''
    },
    locationId: {
      type: String,
      default: ''
    }
  }

  connect(_context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
  }

  disconnect() {
  }

  sync(evt) {
    evt.preventDefault()
    const button = this.element
    const spinner = button.querySelector('svg:last-child')

    button.disabled = true
    spinner.classList.remove('hidden')

    const csrf = document.querySelector('meta[name="csrf-token"]')
    // I don't really understand why in this particular case we need to use set CSRF token manually,
    // and why it is not set automatically by our securedAxios instance, and I don't have a time
    // to debug that. So I just set it manually here.
    securedAxios.defaults.headers.common['X-CSRF-Token'] = csrf.getAttribute('content')

    securedAxios.post(`/ajax/locations/${this.locationIdValue}/sync_geogrids`).then((_response) => {
      mdtoast.info('Geogrid Configs and Results sync is done!', { duration: 10000 })
      button.disabled = false
      spinner.classList.add('hidden')
    }).catch((error) => {
      console.log(error)
      mdtoast.error('Geogrids sync failed', { duration: 10000 })
      button.disabled = false
      spinner.classList.add('hidden')
    })
  }
}
