<script setup>

import { ref, watch, onMounted, defineProps } from 'vue'
import axios from 'axios'

const props = defineProps({
  leadCampaignId: String
})

onMounted(() => {
  getLeadInfo()
})

const leadInfo = ref({})
const infoLoading = ref(true)

const getLeadInfo = () => {
  axios.get(`/ajax/lead_campaigns/${props.leadCampaignId}/leads/stats`).then((response) => {
    leadInfo.value = response.data
    infoLoading.value = false
  }).catch((error) => {
    console.log(error)
  })
}


</script>

<template>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-1 py-6">
    <div>
      <h2 class='text-center'>Phone Calls</h2>

      <div v-if="infoLoading">
        <div role="status" class="space-y-8 animate-pulse md:space-y-0 md:space-x-6 md:flex md:items-center">
          <div class="flex items-center justify-center w-full h-48 bg-gray-300 rounded sm:w-96 dark:bg-gray-700">
              <svg class="w-12 h-12 text-gray-200" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
          </div>
        </div>
      </div>
      <div v-else>
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        Segment
                    </th>
                    <th scope="col" class="px-6 py-3">
                        7d
                    </th>
                    <th scope="col" class="px-6 py-3">
                        30d
                    </th>
                    <th scope="col" class="px-6 py-3">
                        90d
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                     <th scope="row" class="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white">
                        All
                    </th>
                    <td class="px-6 py-4  font-bold">
                      {{ leadInfo['phoneLeadsLastWeek'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                      {{ leadInfo['phoneLeadsLastMonth'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                      {{ leadInfo['phoneLeadsLastQuarter'] }}
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white">
                        Answered
                    </th>
                    <td class="px-6 py-4  font-bold">
                        {{ leadInfo['answeredPhoneLeadsLastWeek'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                        {{ leadInfo['answeredPhoneLeadsLastMonth'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                        {{ leadInfo['answeredPhoneLeadsLastQuarter'] }}
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white">
                        Missed
                    </th>
                    <td class="px-6 py-4  font-bold">
                        {{ leadInfo['missedPhoneLeadsLastWeek'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                        {{ leadInfo['missedPhoneLeadsLastMonth'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                      {{ leadInfo['missedPhoneLeadsLastQuarter'] }}
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white">
                        1st Time
                    </th>
                    <td class="px-6 py-4  font-bold">
                      {{ leadInfo['firstTimePhoneLeadsLastWeek'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                      {{ leadInfo['firstTimePhoneLeadsLastMonth'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                        {{ leadInfo['firstTimePhoneLeadsLastQuarter'] }}
                    </td>
                </tr>
                <tr class="">
                    <th scope="row" class="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white">
                        Repeat
                    </th>
                    <td class="px-6 py-4  font-bold">
                        {{ leadInfo['repeatPhoneLeadsLastWeek'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                        {{ leadInfo['repeatPhoneLeadsLastMonth'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                      {{ leadInfo['repeatPhoneLeadsLastQuarter'] }}
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
    </div>
    <div>
      <h2 class='text-center'>Form Submits</h2>

      <div v-if="infoLoading">
        <div role="status" class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center">
          <div class="flex items-center justify-center w-full h-48 bg-gray-300 rounded sm:w-96 dark:bg-gray-700">
              <svg class="w-12 h-12 text-gray-200" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
          </div>
        </div>
      </div>
      <div v-else>
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        Segment
                    </th>
                    <th scope="col" class="px-6 py-3">
                        7d
                    </th>
                    <th scope="col" class="px-6 py-3">
                        30d
                    </th>
                    <th scope="col" class="px-6 py-3">
                        90d
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white">
                        Form Submits
                    </th>
                    <td class="px-6 py-4 font-bold">
                        {{ leadInfo['formLeadsLastWeek'] }}
                    </td>
                    <td class="px-6 py-4 font-bold">
                        {{ leadInfo['formLeadsLastMonth'] }}
                    </td>
                    <td class="px-6 py-4 font-bold">
                        {{ leadInfo['formLeadsLastQuarter'] }}
                    </td>
                </tr>

            </tbody>
        </table>

      </div>
    </div>
  </div>
</template>

