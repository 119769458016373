import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'
import axios from 'axios' 

const chartData = {} 

export default class extends Controller {
  static targets = [
    'impressionsChart', 
    'clicksChart'
  ]

  static values = {
    clientId: String,
    reportId: String,
    enabledMetrics: Array
  }

  canvasContext(target) { 
    return this[`${target}Target`].getContext('2d')
  }

  drawCharts() {
    axios.get(`/ajax/clients/${this.clientIdValue}/reports/${this.reportIdValue}/search_console_charts_data`).then((response) => {
      this.chartData = response.data['results']
      for (const chartMetric of this.constructor.targets) {

        if (!this.enabledMetricsValue.includes(`${chartMetric.replace('Chart', '')}`)) {
          continue
        }

        new Chart(this.canvasContext(chartMetric), {
          type: 'line',
          data: this.chartData[`${chartMetric.replace('Chart', '')}`],
          options: {
            responsive: true,
            maintainAspectRatio: false,
          }
        }); 
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
    this.drawCharts()
  }

  disconnect() {
  }
}
