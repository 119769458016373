import { Controller } from '@hotwired/stimulus'
import { createApp } from 'vue'
import App from '../vue_widgets/campaign_template_form.vue'
import axios from 'axios'
import { plugin, defaultConfig } from '@formkit/vue'
import { generateClasses } from '@formkit/tailwindcss'
import '@formkit/themes/genesis'

export default class extends Controller {
  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    this.vue = createApp(App).use(plugin, defaultConfig({}))

    this.vue.mount(this.element)
  }

  disconnect() {
    this.vue?.unmount()
  }
}
