import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['card', 'name', 'error', 'form']

  connect() {
    const stripeMeta = document.querySelector('meta[name="stripe-key"]')
    if (stripeMeta === null) { return }

    const stripeKey = stripeMeta.getAttribute('content')
    this.stripe = window.Stripe(stripeKey)
    const elements = this.stripe.elements()

    // Setup Intents are used for adding new cards to be charged in the future
    this.setup_intent = this.data.get('setup-intent')

    // Payment intents are for processing payments that require action
    this.payment_intent = this.data.get('payment-intent')

    // Setup regular payments
    this.card = elements.create('card', {
      style: {
        base: {
          // eslint-disable-next-line max-len
          fontFamily: 'Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased'
        }
      }
    })
    this.card.mount(this.cardTarget)
    this.card.addEventListener('change', this.changed.bind(this))
  }

  changed(event) {
    if (event.error) {
      this.errorTarget.textContent = event.error.message
    } else {
      this.errorTarget.textContent = ''
    }
  }

  submit(event) {
    event.preventDefault()
    Rails.disableElement(this.formTarget)

    this.errorTargets.forEach((target) => {
      target.textContent = ''
    })

    if (this.nameTarget.value === '') {
      this.showError('Name on card is required.', 1)
      return
    }

    // One time payments
    if (this.payment_intent) {
      this.handleCardPayment()

      // Updating card with setup intent
    } else if (this.setup_intent) {
      this.setupNewCard()

    // Subscriptions simply tokenize the payment method and redirect to payment page if SCA required
    } else {
      this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
          name: this.nameTarget.value
        }
      }).then(this.stripeResponseHandler.bind(this))
    }
  }

  setupNewCard() {
    const data = {
      payment_method: {
        card: this.card,
        billing_details: {
          name: this.nameTarget.value
        }
      }
    }

    this.stripe.confirmCardSetup(this.setup_intent, data).then(this.stripeResponseHandler.bind(this))
  }

  stripeResponseHandler(result) {
    if (result.error) {
      this.showError(result.error.message)
    } else if (this.setup_intent) {
      this.handlePaymentMethod(result.setupIntent.payment_method)
    } else {
      this.handlePaymentMethod(result.paymentMethod.id)
    }
  }

  handlePaymentMethod(paymentMethodId) {
    this.addHiddenField('processor', 'stripe')
    this.addHiddenField('payment_method_token', paymentMethodId)
    this.formTarget.submit()
  }

  addHiddenField(name, value) {
    const hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', name)
    hiddenInput.setAttribute('value', value)
    this.formTarget.appendChild(hiddenInput)
  }

  handleCardPayment() {
    // Handle an existing payment that needs confirmation
    this.stripe.confirmCardPayment(this.payment_intent).then((result) => {
      if (result.error) {
        this.showError(result.error.message)
      } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
        window.location.assign('/')
      }
    })
  }

  showError(message, errorTargetIndex = 0) {
    this.errorTargets[errorTargetIndex].textContent = message
    setTimeout(() => {
      Rails.enableElement(this.formTarget)
    }, 100)
  }
}
