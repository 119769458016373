<template>
  <div class="table-responsive-wrapper">
    <div class="table-responsive">
      <input v-model="search" type="text" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="Search..." style="width: auto">

      <VueSlimTables
        ref="table"
        :source="tableSource"
        :columns="columns"
        class="table"
        :per-page="10">
        <template #cell:id="{ value }">
          <a :href="`/agency/clients/${this.clientId}/locations/${value}`">
            {{ value }}
          </a>
        </template>
      </VueSlimTables>
    </div>
  </div>
</template>

<script>
import qs from 'qs'
import axios from 'axios'
import VueSlimTables from 'vue-slim-tables'
import 'vue-slim-tables/stylesheets';

export default {
  name: 'LocationsTable',
  components: { VueSlimTables },
  props: { clientId: String },
  data() {
    return {
      search: ''    }
  },
  watch: {
    search(val) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.$refs.table.refetch, 500)
    }
  },
  methods: {
    reload() {
      this.$refs.table.fetchData(this.$refs.table.page)
    },
    tableSource(params) {
      return axios.get(
        `/ajax/clients/${this.clientId}/locations/table_index`,
        {
          params: { ...params, query: this.search },
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
        }
      )
        .then((res) => res.data)
    },
  },
  computed: {
  },
  created() {
    this.columns = [
      { title: 'id', key: 'id' },
      { title: 'Created', key: 'createdAt' },
      { title: 'Location Name', key: 'name' },
      { title: 'GMB URL', key: 'gmbUrl' },
      { title: 'Place ID', key: 'placeId' },
      { title: 'Remote ID', key: 'remoteId' }
    ]
  }
}

</script>
