import { Controller } from '@hotwired/stimulus'
import axios from 'axios'
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'

const APPROVAL_MESSAGE = 'I confirm I am ready.'
const IGNORED_FEATURES = ['listings']

export default class extends Controller {
  static targets = [
    'locationConfiguredLabel',
    'chatConfiguredLabel',
    'leadConfiguredLabel',
    'repConfiguredLabel',
    'continueBtn'
  ]

  static values = {
    locationConfigured: { type: Boolean, default: false },
    locationHelp: { type: Boolean, default: false },
    chatConfigured: { type: Boolean, default: false },
    chatHelp: { type: Boolean, default: false },
    leadConfigured: { type: Boolean, default: false },
    leadHelp: { type: Boolean, default: false },
    repConfigured: { type: Boolean, default: false },
    repHelp: { type: Boolean, default: false },
    features: { type: Array, default: []},
    organizationId: { type: String, default: '' }
  }

  connect(_context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
    if (this.locationConfiguredValue) {
      this.setSectionApproved('location')
    } else if (this.locationHelpValue) {
      this.setSectionHelp('location')
    }

    if (this.chatConfiguredValue) {
      this.setSectionApproved('chat')
    } else if (this.chatHelpValue) {
      this.setSectionHelp('chat')
    }

    if (this.leadConfiguredValue) {
      this.setSectionApproved('lead')
    } else if (this.leadHelpValue) {
      this.setSectionHelp('lead')
    }

    if (this.repConfiguredValue) {
      this.setSectionApproved('rep')
    } else if (this.repHelpValue) {
      this.setSectionHelp('rep')
    }

    if (this.allSectionsSet()) {
      this.continueBtnTarget.classList.remove('invisible')
    }
  }

  disconnect() {
  }

  sectionApproval(evt) {
    evt.preventDefault()
    axios.post(`/ajax/organizations/${this.organizationIdValue}/user_configured_section`, {
      section: { name: `${evt.params.section}` }
    }).then((_response) => {
      this.setSectionApproved(evt.params.section)
      mdtoast.success('Section completed.', { duration: 10000 })
      if (this.allSectionsSet()) {
        this.continueBtnTarget.classList.remove('invisible')
      }
    }).catch((error) => {
      console.log(error) /* eslint-disable-line no-console */
    })
  }

  sectionHelp(evt) {
    evt.preventDefault()
    axios.post(`/ajax/organizations/${this.organizationIdValue}/user_help_requested`, {
      section: { name: `${evt.params.section}` }
    }).then((_response) => {
      this.setSectionHelp(evt.params.section)
      mdtoast.warning('Help requested.', { duration: 10000 })
      if (this.allSectionsSet()) {
        this.continueBtnTarget.classList.remove('invisible')
      }
    }).catch((error) => {
      console.log(error) /* eslint-disable-line no-console */
    })
  }

  /* eslint-disable max-len */
  setSectionApproved(section) {
    this[`${section}ConfiguredLabelTarget`].classList.remove('bg-yellow', 'text-yellow-800', 'text-sm', 'dark:bg-yellow-900', 'dark:text-yellow-300')
    this[`${section}ConfiguredLabelTarget`].classList.remove('bg-orange-100', 'text-orange-800', 'text-xs', 'rounded', 'border', 'dark:bg-gray-700', 'dark:text-orange-400', 'border-orange-40')
    this[`${section}ConfiguredLabelTarget`].classList.add('bg-green-100', 'text-green-800', 'text-xs', 'rounded', 'border', 'border-green-400')
    this[`${section}ConfiguredLabelTarget`].textContent = APPROVAL_MESSAGE

    this[`${section}ConfiguredValue`] = true
  }

  setSectionHelp(section) {
    this[`${section}ConfiguredLabelTarget`].classList.remove('bg-yellow', 'text-yellow-800', 'text-sm', 'dark:bg-yellow-900', 'dark:text-yellow-300')
    this[`${section}ConfiguredLabelTarget`].classList.remove('bg-green-100', 'text-green-800', 'text-xs', 'rounded', 'border', 'border-green-400')
    this[`${section}ConfiguredLabelTarget`].classList.add('bg-orange-100', 'text-orange-800', 'text-xs', 'rounded', 'border', 'dark:bg-gray-700', 'dark:text-orange-400', 'border-orange-40')
    this[`${section}ConfiguredLabelTarget`].textContent = 'I need help with this section.'

    this[`${section}ConfiguredValue`] = true
  }
  /* eslint-enable max-len */

  allSectionsSet() {
    const featuresMapping = {
      locations: this.locationConfiguredValue || this.locationHelpValue,
      chats: this.chatConfiguredValue || this.chatHelpValue,
      reputation: this.repConfiguredValue || this.repHelpValue,
      leads: this.leadConfiguredValue || this.leadHelpValue
    }
    const features = this.featuresValue.filter((v) => !IGNORED_FEATURES.includes(v))

    return features.map((feature) => featuresMapping[feature]).every(Boolean)
  }
}
