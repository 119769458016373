<template>
  <div class="table-responsive-wrapper">
    <div class="table-responsive">
      <span> Search Clients </span>
      <input v-model="search" type="text" class="form-control mb-5" style="width: auto">

      <VueSlimTables
        ref="table"
        :source="tableSource"
        :columns="columns"
        class="table"
        :per-page="50">

        <template #cell:businessName="{ row }">
          <a :href="`/agency/clients/${row.id}`">{{ value }}</a>
          {{ businessTitle(row) }}
          <!--<p> {{ row.city }}, {{ row.state }} {{ row.zip }}</p>-->
        </template>
        <template #cell:campaignState="{ value }">
          <div :class="campaignStateClass(value)">
            {{ value }}
          </div>
        </template>
      </VueSlimTables>
    </div>
  </div>
</template>

<script>
import qs from 'qs'
import axios from 'axios'
import VueSlimTables from 'vue-slim-tables'
import 'vue-slim-tables/stylesheets';

export default {
  name: 'ClientsTable',
  components: { VueSlimTables },
  props: { clientId: String },
  data() {
    return {
      search: ''
    }
  },
  watch: {
    search(val) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.$refs.table.refetch, 500)
    }
  },
  methods: {
    reload() {
      this.$refs.table.fetchData(this.$refs.table.page)
    },
    tableSource(params) {
      return axios.get(
        `/ajax/clients`,
        {
          params: { ...params, query: this.search },
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
        }
      ).then((res) => res.data)
    },

    businessTitle(row) {
      // debugger
      if (row.businessName) {
        return row.businessName
      } else {
        return [row.firstName, row.lastName, `<${row.email}>`]
          .filter((str) => str !== '')
          .join(' ')
      }
    },

    campaignStateClass(state) {
      if (state == "onboarding") {
        return "py-1 px-4 bg-primary-500 text-xs inline-block text-white font-bold leading-normal uppercase mr-2"
      } else if (state == "live") {
        return "py-1 px-4 bg-secondary-500 text-xs inline-block text-white font-bold leading-normal uppercase mr-2"
      } else if (state == "triage") {
        return "py-1 px-4 bg-yellow-100 text-xs inline-block text-dark font-bold leading-normal uppercase mr-2"
      } else if (state == "payment_failed") {
        return "py-1 px-4 bg-pink-600 text-xs inline-block text-white font-bold leading-normal uppercase mr-2"
      } else if (state == "canceled") {
        return "py-1 px-4 bg-transparent border-2 border-orange-500 text-xs inline-block text-orange-500 font-bold leading-normal uppercase mr-2"
      }
    }
  },
  computed: {
  },
  created() {
    this.columns = [
      { title: 'Client', key: 'businessName' },
      { title: 'Campaign Status', key: 'campaignState'},
      { title: 'Contact', key: 'contactEmail' },
      { title: 'Campaign Start Date', key: 'campaignStartDate' }
    ]
  }
}

</script>
