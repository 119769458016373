<script setup>

import { ref, watch, onMounted, defineProps } from 'vue'
import axios from 'axios'
import CountryFlag from 'vue-country-flag-next'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

const props = defineProps({
  locationId: String
})

onMounted(() => {
  getLocationInfo()
  getGeogridImages()
  getLocationActivity()
})

const locationInfo = ref({})
const infoLoading = ref(true)
const gridsLoading = ref(true)
const activityLoading = ref(true)
const latestGeogridImages = ref([])
const locationActivity = ref({})

const getLocationInfo = () => {
  axios.get(`/ajax/locations/${props.locationId}`).then((response) => {
    locationInfo.value = response.data
    infoLoading.value = false
  }).catch((error) => {
    console.log(error)
  })
}

const getGeogridImages = () => {
  axios.get(`/ajax/locations/${props.locationId}/geogrid_keywords/latest_images`).then((response) => {
    latestGeogridImages.value = response.data
    gridsLoading.value = false
  }).catch((error) => {
    console.log(error)
  })
}

const getLocationActivity = () => {
  axios.get(`/ajax/locations/${props.locationId}/activity`).then((response) => {
    locationActivity.value = response.data
    activityLoading.value = false
  }).catch((error) => {
    console.log(error)
  })
}

const displayAddress = () => {
  return locationInfo.value['fullAddress']
}

const displayName = () => {
  return locationInfo.value['name']
}

const displayPhone = () => {
  return locationInfo.value['primaryPhone']
}

const displayCountry = () => {
  return locationInfo.value['isoCountry']
}

const displayCategory = () => {
  return locationInfo.value.category?.name
}

const isCarouselLoaded = ref(false)
</script>

<template>
  <div class="grid grid-cols-1 md:grid-cols-3 gap-4 py-6">
    <div>
      <dl>
        <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Location</dt>
        <dd v-if="infoLoading" class="mb-4 text-gray-500 dark:text-gray-400 sm:mb-5">
          <div role="status" class="space-y-2.5 animate-pulse max-w-lg">
              <div class="flex items-center w-full space-x-2">
                  <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                  <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                  <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              </div>
              <span class="sr-only">Loading...</span>
          </div>
            <!-- <span class="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-primary-200 dark:text-primary-800">
              SUBSCRIPTION_STATUS
            </span> -->
        </dd>
        <dd v-else class="mb-4 text-gray-500 dark:text-gray-400 sm:mb-5">
          {{ displayName() }}
        </dd>
        <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Address</dt>
        <dd v-if="infoLoading" class="mb-4 text-gray-500 dark:text-gray-400 sm:mb-5">

          <div role="status" class="space-y-2.5 animate-pulse max-w-lg">
              <div class="flex items-center w-full space-x-2">
                  <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                  <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                  <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              </div>
              <span class="sr-only">Loading...</span>
          </div>

        </dd>
        <dd v-else class="mb-4 text-gray-500 dark:text-gray-400 sm:mb-5">
          {{ displayAddress() }}
        </dd>

        <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Country</dt>
        <dd v-if="infoLoading" class="mb-4 text-gray-500 dark:text-gray-400 sm:mb-5">
          <div role="status" class="space-y-2.5 animate-pulse max-w-lg">
              <div class="flex items-center w-full space-x-2">
                  <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                  <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                  <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              </div>
              <span class="sr-only">Loading...</span>
          </div>
        </dd>
        <dd v-else class="mb-4 text-gray-500 dark:text-gray-400 sm:mb-5">
          <country-flag :country="displayCountry()" size='normal'/>
        </dd>

        <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Phone</dt>
        <dd v-if="infoLoading" class="mb-4 text-gray-500 dark:text-gray-400 sm:mb-5">
          <div role="status" class="space-y-2.5 animate-pulse max-w-lg">
              <div class="flex items-center w-full space-x-2">
                  <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                  <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                  <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              </div>
              <span class="sr-only">Loading...</span>
          </div>
        </dd>
        <dd v-else class="mb-4 text-gray-500 dark:text-gray-400 sm:mb-5">
          {{ displayPhone() }}
        </dd>

        <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Category</dt>
        <dd v-if='infoLoading' class="mb-4 text-gray-500 dark:text-gray-400 sm:mb-5">
          <div role="status" class="space-y-2.5 animate-pulse max-w-lg">
              <div class="flex items-center w-full space-x-2">
                  <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
                  <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                  <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              </div>
              <span class="sr-only">Loading...</span>
          </div>
        </dd>
        <dd v-else class="mb-4 text-gray-500 dark:text-gray-400 sm:mb-5">
          <div role="status" class="space-y-2.5 animate-pulse max-w-lg">
             {{ displayCategory() }}
          </div>
        </dd>
      </dl>
    </div>

    <div>
      <h2>Visibility</h2>

      <div v-show="gridsLoading || !isCarouselLoaded" role="status" class="w-full h-96 p-4 animate-pulse">
        <div class="flex items-center justify-center bg-gray-300 rounded animate-pulse w-full h-full dark:bg-gray-700">
          <svg class="w-12 h-12 text-gray-200" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" fill="currentColor" viewBox="0 0 640 512"><path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z"/></svg>
        </div>
      </div>
      <Carousel :class="{ 'invisible h-0': !isCarouselLoaded }">
        <Slide v-for="slide in latestGeogridImages" :key="slide">
          <div class="carousel__item bg-gray-300">
            <img :src="slide['url']" @load="isCarouselLoaded = true">
          </div>
        </Slide>

        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>
    </div>
    <div>
      <h2>Activity</h2>
      <div v-if="activityLoading">
        <div role="status" class="space-y-2.5 py-4 animate-pulse max-w-lg">
          <div class="flex items-center w-full space-x-2">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[400px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[440px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[360px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <span class="sr-only">Loading...</span>
      </div>
      </div>
      <div v-else>
         <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        Activity
                    </th>
                    <th scope="col" class="px-6 py-3">
                        7d
                    </th>
                    <th scope="col" class="px-6 py-3">
                        30d
                    </th>
                    <th scope="col" class="px-6 py-3">
                        90d
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Posts
                    </th>
                    <td class="px-6 py-4">
                        {{ locationActivity['postStats']['lastWeek'] }}
                    </td>
                    <td class="px-6 py-4">
                        {{ locationActivity['postStats']['lastMonth'] }}
                    </td>
                    <td class="px-6 py-4">
                        {{ locationActivity['postStats']['lastQuarter'] }}
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Photos
                    </th>
                    <td class="px-6 py-4">
                        {{ locationActivity['mediaStats']['lastWeek']}}
                    </td>
                    <td class="px-6 py-4">
                        {{ locationActivity['mediaStats']['lastMonth']}}
                    </td>
                    <td class="px-6 py-4">
                        {{ locationActivity['mediaStats']['lastQuarter']}}
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Moments
                    </th>
                    <td class="px-6 py-4">
                        {{ locationActivity['momentStats']['lastWeek']}}
                    </td>
                    <td class="px-6 py-4">
                        {{ locationActivity['momentStats']['lastMonth']}}
                    </td>
                    <td class="px-6 py-4">
                        {{ locationActivity['momentStats']['lastQuarter']}}
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Phone Calls
                    </th>
                    <td class="px-6 py-4">
                        {{ locationActivity['callStats']['lastWeek']}}
                    </td>
                    <td class="px-6 py-4">
                        {{ locationActivity['callStats']['lastMonth']}}
                    </td>
                    <td class="px-6 py-4">
                        {{ locationActivity['callStats']['lastQuarter']}}
                    </td>
                </tr>
                <tr class="">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Directions
                    </th>
                    <td class="px-6 py-4">
                        {{ locationActivity['directionStats']['lastWeek']}}
                    </td>
                    <td class="px-6 py-4">
                        {{ locationActivity['directionStats']['lastMonth']}}
                    </td>
                    <td class="px-6 py-4">
                        {{ locationActivity['directionStats']['lastQuarter']}}
                    </td>
                </tr>
            </tbody>
          </table>
      </div>

    </div>
  </div>
</template>
<style>
.carousel__item {
  min-height: 200px;
  width: 100%;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 1rem;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>
