import { Controller } from '@hotwired/stimulus'
import { createApp } from 'vue'
import App from '../vue_widgets/lead_management_widget.vue'

export default class extends Controller {

  static values = { leadCampaignId: String }

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
    console.log('location mgmt widget controller connected.')

    const props = {
      leadCampaignId: this.leadCampaignIdValue
    }

    this.vue = createApp(App, props)
    this.vue.mount(this.element)
  }

  disconnect() {
    this.vue?.unmount()
  }
}
