import axios from 'axios'
import camelcaseKeys from 'camelcase-keys-deep'
import snakecaseKeys from 'snakecase-keys'
import Qs from 'qs'

const JSON_TYPE = 'application/json'

/**
 * Global axios settings.
 *
 * We should not use global axios instance but create new one with special config, for example securedAxios
 * from below, to not produce unexpected side effects.
 */

axios.defaults.headers.common.Accept = JSON_TYPE
axios.defaults.headers.common['Content-Type'] = JSON_TYPE
axios.defaults.headers.post['Content-Type'] = JSON_TYPE
axios.defaults.headers.put['Content-Type'] = JSON_TYPE
axios.defaults.headers.patch['Content-Type'] = JSON_TYPE
axios.interceptors.response.use((response) => {
  if (response.data && response.headers['content-type'].includes(JSON_TYPE)) {
    response.data = camelcaseKeys(response.data)
  }
  return response
})
axios.interceptors.request.use((request) => {
  const csrf = document.querySelector('meta[name="csrf-token"]')
  axios.defaults.headers.common['X-CSRF-Token'] = csrf.getAttribute('content')

  const contentType = request.headers['Content-Type'] ||
    request.headers[request.method]['Content-Type'] ||
    request.headers.common['Content-Type']

  if (request.data && contentType.includes(JSON_TYPE)) {
    request.data = snakecaseKeys(request.data, { deep: true })
  }

  return request
})

/**
 * Secured axios instance.
 *
 * As @fla suggested we should not use global axios instance but create new one with special config
 * to avoid passing each time the same paramsSerializer and other stuff again and again.
 */
const securedAxios = axios.create({
  withCredentials: false,
  headers: {
    Accept: JSON_TYPE,
    'Content-Type': JSON_TYPE
  }
})

securedAxios.defaults.paramsSerializer = (prms) => Qs.stringify(snakecaseKeys(prms, { deep: true }), { arrayFormat: 'brackets' })

securedAxios.defaults.transformResponse = [(data, headers) => {
  if (data && headers && (headers['Content-Type'] + headers['content-type']).toString().includes(JSON_TYPE)) {
    return camelcaseKeys(JSON.parse(data), { deep: true })
  }
  return data
}]

securedAxios.defaults.transformRequest = [(data, headers) => {
  const csrf = document.querySelector('meta[name="csrf-token"]')
  securedAxios.defaults.headers.common['X-CSRF-Token'] = csrf.getAttribute('content')

  if (data && headers && typeof headers['Content-Type'] === 'string' && headers['Content-Type'].includes(JSON_TYPE)) {
    return JSON.stringify(snakecaseKeys(data, { deep: true }))
  }
  return data
}]

export default securedAxios
