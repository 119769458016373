<script> 
import { token } from '@formkit/utils'
import axios from 'axios'

export default {
  mounted() {
    this.getTrelloLists()
  },
  data() {
    return {
      formData: {},
      loading: false,
      remoteTrelloLists: [],
      trelloListId: null,
      scheduleType: 'recurring',
      dynamicPricing: false
    }
  },
  methods: {
    submitHandler(evt) {
      
      axios.post(`/ajax/products`, this.formData)
        .then((response) => {
          
          window.location = `/products/${response.data['productId']}`
        }).catch((error) => {
          console.log(error)
        })
    },

    getTrelloLists() {
       axios.get(`/ajax/trello_lists`).then((response) => {
        this.remoteTrelloLists = response.data['results']
      }).catch((error) => {
        console.log(error)
      })
    }
  },
  watch: {
  },
  computed: {
    console: () => console,

    scheduleTypeOneOff() {
      return this.scheduleType == "one_off"
    }
  }
}
</script>

<template>
  <div v-if="!this.loading" class='wrapper w-full'>
    <FormKit
      type="form"
      v-model="formData"
      :classes="{
        outer: '$reset w-full'
      }"
      submit-label="Create Product"
      :form-class="submitted ? 'hide' : 'show'"
      @submit="submitHandler">
    
      <FormKit
        type="text"
        name="title"
        label="Product Title"
        placeholder="Press Release Product"
        help="Title for this product"
        validation="required"
        outer-class="mb-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"
      />

      <FormKit
        v-model="dynamicPricing"
        type="checkbox"
        label="Dynamic Pricing"
        help="Should the cost of this product change with campaign budget?"
        name="dynamic_pricing"
      />

      <FormKit
        v-if="this.dynamicPricing"
        type="number"
        name="price_threshold"
        label="Price Threshold"
        placeholder="1500"
        help="Dynamic pricing threshold"
        validation="required"
        outer-class="mb-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"
      />

      <FormKit
        v-if="this.dynamicPricing"
        type="number"
        name="low_price_multiplier"
        label="Lower Pricing Multiplier"
        placeholder="25"
        help="Enter 1-100 for percentage based multiplier when a campaign is at or below the threshold"
        validation="required"
        outer-class="mb-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"
      />

      <FormKit
        v-if="this.dynamicPricing"
        type="number"
        name="high_price_multiplier"
        label="Higher Pricing Multiplier"
        placeholder="25"
        help="Enter 1-100 for percentage based multiplier when a campaign is above the threshold"
        validation="required"
        outer-class="mb-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"
      />

      <FormKit
        type="number"
        v-if="!this.dynamicPricing"
        help="Enter a cost for this product in USD greater than 1"
        label="Product Cost"
        name="cost"
        value="1"
        step="1"
        validation="required"
        outer-class="mb-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"
      />



      <FormKit
        type="textarea"
        label="Product Description"
        name="description"
        rows="10"
        placeholder="Write a description for this product."
        help="Write a clear description for this product."
        validation="required"
        outer-class="mb-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"
      />

      <FormKit
        type="select"
        label="Product Type"
        v-model="scheduleType"
        name="schedule_type"
        :options="[
          { label: 'Recurring', value: 'recurring'},
          { label: 'One Off', value: 'one_off' }
        ]"
        validation="required"
        outer-class="mb-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"
      />

      <FormKit
        v-if="scheduleTypeOneOff"
        type="number"
        help="Enter the month that this product should be executed in."
        label="Product Month"
        name="one_off_month"
        value="1"
        step="1"
        max="12"
        min="1"
        validation="required"
        outer-class="mb-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"
      />

      <FormKit
        v-if="!scheduleTypeOneOff"
        type="number"
        help="Enter the month that this product should start in."
        label="Recurring Month Start"
        name="recurring_month_start"
        value="1"
        step="1"
        max="12"
        min="1"
        validation="required"
        outer-class="mb-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"
      />

      <FormKit
        v-if="!scheduleTypeOneOff"
        type="number"
        help="Enter the month that this product should stop in. (0 for permanently recurring)"
        label="Recurring Month Stop"
        name="recurring_month_stop"
        value="0"
        step="1"
        max="60"
        min="0"
        validation="required"
        outer-class="mb-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"
      />

      <FormKit
        v-model="trelloListId"
        type="select"
        label="Trello List"
        name="trello_list_id"
        placeholder="Select a Trello list"
        :options="remoteTrelloLists"
        help="Select the trello list for this campaign."
        outer-class="my-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class=" border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="px-3 border-none text-base text-gray-700 placeholder-gray-400"
      />


    </FormKit>
  </div>
  <div v-else>
    <div class="flex justify-center items-center">
      <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<style>
</style>
