<template>
  <div class="table-responsive-wrapper">
    <div class="table-responsive">
      <input v-model="search" type="text" class="form-control mb-2" placeholder="Search Keywords" style="width: auto">

      <VueSlimTables
        ref="table"
        :source="tableSource"
        :columns="columns"
        class="table"
        :per-page="50">

        <template #cell:techStackEnabled="{ row, value }">
          <div v-if="value">
            <span :id="`label_enabled_${row['id']}`" class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Enabled</span>

          </div>
          <div v-else>
            <span :id="`label_disabled_${row['id']}`" class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">Disabled</span>
          </div>
        </template>

        <template #cell:text="{ row, value }">
          <p class='pb-6'>{{ value }}</p>
        </template>

        <template #cell:id="{ row, value }">
          <a @click="toggleGeogridKeyword(value)" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
            Toggle
          </a><br>
          <small>{{ row['owner'] }}</small>
        </template>

      </VueSlimTables>
    </div>
  </div>
</template>

<script>
import qs from 'qs'
import axios from 'axios'
import VueSlimTables from 'vue-slim-tables'
import 'vue-slim-tables/stylesheets';

export default {
  name: 'GeogridSettingsTable',
  components: { VueSlimTables },
  props: { locationId: String },
  data() {
    return {
      search: ''
    }
  },
  watch: {
    search(val) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.$refs.table.refetch, 500)
    }
  },
  methods: {
    reload() {
      this.$refs.table.fetchData(this.$refs.table.page)
    },
    tableSource(params) {
      return axios.get(
        `/ajax/locations/${this.locationId}/geogrid_keywords`,
        {
          params: { ...params, query: this.search },
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
        }
      ).then((res) => res.data)
    },

    toggleGeogridKeyword(id) {
      axios.post(`/ajax/locations/${this.locationId}/geogrid_keywords/${id}/toggle_enabled`).then((response) => {

          if (response.data.status) {
            let el = document.getElementById(`label_disabled_${id}`)
            el.classList.remove('bg-red-100', 'text-red-800', 'dark:bg-red-900', 'dark:text-red-300')
            el.classList.add('bg-green-100', 'text-green-800', 'dark:bg-green-900', 'dark:text-green-300')
            el.innerText = 'Enabled'
            el.setAttribute("id", `label_enabled_${id}`)
          } else {
            let el = document.getElementById(`label_enabled_${response.data.id}`)
            el.classList.remove('bg-green-100', 'text-green-800', 'dark:bg-green-900', 'dark:text-green-300')
            el.classList.add('bg-red-100', 'text-red-800', 'dark:bg-red-900', 'dark:text-red-300')
            el.innerText = 'Disabled'
            el.setAttribute("id", `label_disabled_${id}`)
          }

        }).catch((error) => {
          console.log(error)
        })
    }

  },
  computed: {
  },
  created() {
    this.columns = [
      { title: 'Enabled', key: 'techStackEnabled'},
      { title: 'Keyword', key: 'text' },
      { title: 'Grids', key: 'geogridsCount'},
      { title: '', key: 'id' },


    ]
  }
}

</script>
