import { Controller } from '@hotwired/stimulus'
import axios from 'axios' 

export default class extends Controller {

  static targets = ['state'] 
  static values = { clientId: String } 

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
  }

  toggle(val) {
    let selected_campaign_state = this.element.options[this.element.selectedIndex].value
    
    axios.post(`/ajax/clients/${this.clientIdValue}/change_campaign_state`, {
      campaign_state: selected_campaign_state 
    }).then((response) => {
      console.log('campaign state changed')
    }).catch((error) => {
      console.log(error)
    })
  }

  disconnect() {
  }
}
