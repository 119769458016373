<template>
  <div class="flex-1 p:2 sm:p-6 justify-between flex flex-col h-96">
    <!-- Header -->
    <div class="flex sm:items-center justify-between py-3 border-b-2 border-gray-200">
      <!-- Profile Info -->
      <div class="relative flex items-center space-x-4">
        <div class="relative">
          <span class="absolute text-green-500 right-0 bottom-0">
            <svg width="20" height="20">
              <circle cx="8" cy="8" r="8" fill="currentColor"></circle>
            </svg>
          </span>
          <img :src="`/avatars/${props.chatbotId}.png`" alt="" class="w-10 sm:w-16 h-10 sm:h-16 rounded-full">
        </div>
        <div class="flex flex-col leading-tight">
          <div class="text-2xl mt-1 flex items-center">
            <span class="text-gray-700 mr-3">{{ props.chatbotName }}</span>
          </div>
          <span class="text-lg text-gray-600"><small>Inbox</small> {{ props.inboxName }}</span>
        </div>
      </div>
      <div class="flex items-center space-x-2">
        <!-- Icons could go here -->
      </div>
    </div>
    <div id="messages" ref="messageContainer"  class="flex flex-col space-y-4 p-3 overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
    <div v-if="messagesLoading" role="status">
      <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
    <div v-if="!messagesLoading" v-for="message in messages" :key="message.id"  class="chat-message">
        <div class="flex items-end" :class="{'justify-end': message.isUser }">
            <div class="flex flex-col space-y-2 text-xs max-w-xs mx-2" :class="{'order-1': message.isUser, 'order-2': !message.isUser}">
                <div>
                    <span :class="{'rounded-br-none bg-blue-400 text-white': message.isUser, 'rounded-bl-none bg-gray-300 text-gray-600': !message.isUser}" class="px-4 py-2 rounded-lg inline-block">
                        {{ message.content }}
                    </span>
                </div>
            </div>
            <img v-if="message.isUser" src="https://static.thenounproject.com/png/4035892-200.png" alt="Profile" class="w-6 h-6 rounded-full" :class="{'order-2': message.isUser, 'order-1': !message.isUser}">

            <img v-if="!message.isUser" :src="`/avatars/${props.chatbotId}.png`" alt="Profile" class="w-6 h-6 rounded-full" :class="{'order-2': message.isUser, 'order-1': !message.isUser}">
        </div>
    </div>
    <div v-if="messageLoading" role="status">
      <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
      </svg>
      <span class="sr-only">Loading...</span>
  </div>
</div>
    <!-- Input -->
    <div class="border-t-2 border-gray-200 px-4 pt-4 mb-2 sm:mb-0">
      <div class="relative flex">
         <span class="absolute inset-y-0 flex items-center">
            <button type="button" class="inline-flex items-center justify-center rounded-full h-12 w-12 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 18">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 5h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1h-2v3l-4-3H8m4-13H2a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h2v3l4-3h4a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"/>
          </svg>

            </button>
        </span>
         <input type="text"
       @keyup.enter="sendMessage"
       v-model="currentMessage"
       placeholder="Write your message!"
       class="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-12 bg-gray-200 rounded-md focus:ring-4 focus:outline-none focus:ring-blue-300 py-3" style='padding-right:7em;'>
        <!-- ^^^^ Added pr-20 (assuming you're using TailwindCSS) to reserve space for the button ^^^^ -->

        <div class="absolute right-0 items-center inset-y-0 hidden sm:flex">
            <button @click.prevent="sendMessage" type="button" class="inline-flex items-center justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white text-white bg-blue-700 hover:bg-blue-800">
                <span class="font-bold">Send</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-6 w-6 ml-2 transform rotate-90">
                    <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                </svg>
            </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, nextTick, onBeforeUnmount, onMounted, computed } from 'vue'
import consumer from "../channels/consumer"
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'
import axios from 'axios'
import qs from 'qs'

const messages = ref([]);

let subscription = null;
const currentMessage = ref("")

const props = defineProps({
  chatbotId: String,
  chatbotName: String,
  inboxId: String,
  inboxName: String,
  clientId: String
})


onMounted(() => {
  fetchMessages()
  initializeActionCable()
})

onBeforeUnmount(() => {
  if (subscription) {
    consumer.subscriptions.remove(subscription);
  }
})

const fetchMessages = () => {
  axios.get(`/ajax/chatbots/${props.chatbotId}/conversations/demo_messages`)
  .then(response => {
    messagesLoading.value = false
    messages.value.push(...response.data.messages)

  })
  .catch(error => {
    console.error("There was an error sending the message:", error);
    messagesLoading.value = false
    //mdtoast("Error sending message!", { type: "error", duration: 3000 });
  })
}

const initializeActionCable = () => {

  subscription = consumer.subscriptions.create({
    channel: `SmsPreviewChannel`,
    chatbot: props.chatbotId
  }, {
    connected: () => {
      console.log("Connected to the Action Cable channel.");
    },
    disconnected: () => {
      console.log("Disconnected from the Action Cable channel.");
    },
    received: (data) => {

      if (data.message) {
        messages.value.push({
          id: Date.now(),
          content: data.message,
          isUser: false,
        });
        messageLoading.value = false
      }
    }
  })
}

const messageLoading = ref(false)
const messageContainer = ref(null)
const messagesLoading = ref(true)

const sendMessage = () => {
  if (currentMessage.value.trim() !== "") {
    // Push the user's message immediately to the UI
    messages.value.push({
      id: Date.now(),
      content: currentMessage.value,
      isUser: true,
    })

    messageLoading.value = true

    // Clear the input field
    let message = currentMessage.value
    currentMessage.value = ""

    // Send the message to the server
    axios.post(`/ajax/chatbots/${props.chatbotId}/conversations/demo_message`, { content: message, inboxId: props.inboxId })
      .then(response => {

        console.log("Message sent!");
      })
      .catch(error => {
        console.error("There was an error sending the message:", error);
        mdtoast("Error sending message!", { type: "error", duration: 3000 });
      })
  }
}

const executeScroll = () => {
  nextTick(() => {
    setTimeout(() => {
      let container = messageContainer.value;
      container.scrollTop = container.scrollHeight;
    }, 100)
  })
}

watch(messages, () => {
  executeScroll()
}, { deep: true })

watch(messageLoading, (newValue) => {
  if (!newValue) { // If messageLoading becomes false
    executeScroll()
  }
})
</script>

<style>
  .scrollbar-w-2::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

.scrollbar-track-blue-lighter::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.scrollbar-thumb-blue::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
}

.scrollbar-thumb-rounded::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}
</style>