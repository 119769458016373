import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'companyMegamenuDropdown',
    'megamenuDropdown',
    //'saasMegamenuDropdown',
    'searchMegamenuDropdown',
    'megamenuLink',
    'searchMegamenuLink',
    'companyMegamenuLink'
  ]

  connect() {
    console.log('megamenu controller connected.')
  }

  toggle(evt) {
    this.searchMegamenuDropdownTarget.classList.add('hidden')
    this.companyMegamenuDropdownTarget.classList.add('hidden')
    this.megamenuDropdownTarget.classList.add('hidden')
  }

  disconnect() {
  }
}
