import { Controller } from "@hotwired/stimulus"
import axios from 'axios'

export default class extends Controller {
  initialize() {
    console.log('init websites_controller')
  }

  performAudit(event) {
    event.preventDefault()
    let websiteId = event.target.dataset.websitesIdParam
    let url = event.target.dataset.websitesUrlParam
    axios.post(url, { website_id: websiteId }).then(res => {
      window.location.reload();
    })
  }
}
