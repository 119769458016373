// noinspection JSUnresolvedReference, JSUnusedGlobalSymbols

import { Controller } from '@hotwired/stimulus'
import { createApp } from 'vue'
import App from '../vue_widgets/client_team_member.vue'

export default class extends Controller {
  static values = {
    planFeatures: Object,
    organizationId: String,
    currentClientId: String,
    clientId: String,
    firstName: String,
    lastName: String,
    email: String,
    mobileAccess: Boolean,
    adminRights: Boolean,
    moderationEnabled: Boolean,
    locationsManagement: Boolean,
    allowAllLocations: Boolean,
    chatManagement: Boolean,
    reputationManagement: Boolean,
    leadsManagement: Boolean,
    listingsManagement: Boolean,
    allowedLocations: Array
  }

  connect() {
    const props = {
      planFeatures: this.planFeaturesValue,
      organizationId: this.organizationIdValue,
      currentClientId: this.currentClientIdValue,
      clientId: this.clientIdValue,
      firstName: this.firstNameValue,
      lastName: this.lastNameValue,
      email: this.emailValue,
      mobileAccess: this.mobileAccessValue,
      adminRights: this.adminRightsValue,
      moderationEnabled: this.moderationEnabledValue,
      locationsManagement: this.locationsManagementValue,
      allowAllLocations: this.allowAllLocationsValue,
      chatManagement: this.chatManagementValue,
      reputationManagement: this.reputationManagementValue,
      leadsManagement: this.leadsManagementValue,
      listingsManagement: this.listingsManagementValue,
      allowedLocations: this.allowedLocationsValue
    }

    this.vue = createApp(App, props)
    this.vue.mount(this.element)
  }

  disconnect() {
    this.vue?.unmount()
  }
}
