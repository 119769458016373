<script setup>
import { ref, onMounted, defineProps } from 'vue'
import axios from 'axios'

const props = defineProps({
  locationId: String
})

const geogridKeywords = ref([])
const keywordsLoading = ref(true)
const selectedResult = ref({})

const selectGrid = (geogridKeyword, geoGridResult) => {
  selectedResult.value[geogridKeyword.id] = geoGridResult
}

const getKeywords = () => {
  axios.get(`/ajax/locations/${props.locationId}/geogrid_keywords/active`).then((response) => {
    geogridKeywords.value = response.data
    geogridKeywords.value.forEach((keyword) => {
      selectedResult.value[keyword.id] = keyword.geogridResults[0]
    })
    keywordsLoading.value = false
    selectGrid()
  }).catch((error) => {
    // eslint-disable-next-line no-console
    console.log(error)
  })
}

onMounted(() => {
  getKeywords()
})
</script>

<template>
  <div class="px-4 py-6">
    <div v-if="keywordsLoading">
      <div role="status">
        <svg
          aria-hidden="true"
          class="mr-auto ml-auto w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50
              0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987
              91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921
              9.08144 50.5908Z"
            fill="currentColor" />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452
              15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843
              41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511
              9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619
              82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill" />
        </svg>
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <template v-else>
      <div
        v-for="geogridKeyword in geogridKeywords"
        :key="geogridKeyword.id"
        class="card grid grid-cols-1 md:grid-cols-3 m-4 px-6 border border-gray-200">
        <div class="col-span-1">
          <h3 class="p-6">
            {{ geogridKeyword.text }}
          </h3>
          <div class="px-6">
            <dl>
              <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
                ATGR
                <button
                  data-popover-target="atgr-description"
                  data-popover-placement="bottom-end"
                  type="button">
                  <svg
                    class="w-4 h-4 text-gray-400 hover:text-gray-500"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0
                        0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="sr-only">Show information</span>
                </button>
                <div
                  id="atgr-description"
                  data-popover
                  role="tooltip"
                  class="absolute z-10 invisible inline-block text-sm font-light text-gray-500
                    transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72
                    dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">
                  <div class="p-3 space-y-2">
                    <h3 class="font-semibold text-gray-900 dark:text-white">
                      Average Total Grid Rank
                    </h3>
                    <p>
                      These metrics show your average business position locally (AGR) or on a wide area (ATGR).
                      AGR uses only search results between 1-20 while ATGR includes ranks
                      when the business can not be found among the first 20 search results.
                      For example, a geogrid search map with AGR = 5 and ATGR = 15 would look like a red square with some greens inside.
                    </p>

                    <a
                      href="#"
                      class="flex items-center font-medium text-blue-600 dark:text-blue-500 dark:hover:text-blue-600 hover:text-blue-700">
                      Read more
                      <svg
                        class="w-4 h-4 ml-1"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1
                            0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd" />
                      </svg>
                    </a>
                  </div>
                  <div data-popper-arrow />
                </div>
              </dt>
              <dd class="mb-4 text-gray-500 dark:text-gray-400 sm:mb-5">
                <p class="text-3xl font-bold">
                  {{ selectedResult[geogridKeyword.id]?.atgr ?? '-' }}
                </p>
              </dd>
              <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
                AGR
                <button
                  data-popover-target="agr-description"
                  data-popover-placement="bottom-end"
                  type="button">
                  <svg
                    class="w-4 h-4 text-gray-400 hover:text-gray-500"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001
                        3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="sr-only">Show information</span>
                </button>
                <div
                  id="agr-description"
                  data-popover
                  role="tooltip"
                  class="absolute z-10 invisible inline-block text-sm font-light text-gray-500 transition-opacity duration-300
                    bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72
                    dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">
                  <div class="p-3 space-y-2">
                    <h3 class="font-semibold text-gray-900 dark:text-white">
                      Average Grid Rank
                    </h3>
                    <p>
                      These metrics show your average business position locally (AGR) or on a wide area (ATGR).
                      AGR uses only search results between 1-20 while ATGR includes ranks
                      when the business can not be found among the first 20 search results.
                      For example, a geogrid search map with AGR = 5 and ATGR = 15 would look like a red square with some greens inside.
                    </p>

                    <a
                      href="#"
                      class="flex items-center font-medium text-blue-600 dark:text-blue-500
                        dark:hover:text-blue-600 hover:text-blue-700">
                      Read more
                      <svg
                        class="w-4 h-4 ml-1"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0
                            011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd" />
                      </svg>
                    </a>
                  </div>
                  <div data-popper-arrow />
                </div>
              </dt>
              <dd class="mb-4 text-gray-500 dark:text-gray-400 sm:mb-5">
                <p class="text-3xl font-bold">
                  {{ selectedResult[geogridKeyword.id]?.agr ?? '-' }}
                </p>
              </dd>
              <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
                SoLV
                <button
                  data-popover-target="solv-description"
                  data-popover-placement="bottom-end"
                  type="button">
                  <svg
                    class="w-4 h-4 text-gray-400 hover:text-gray-500"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113
                        8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                      clip-rule="evenodd" />
                  </svg>
                  <span class="sr-only">Show information</span>
                </button>
                <div
                  id="solv-description"
                  data-popover
                  role="tooltip"
                  class="absolute z-10 invisible inline-block text-sm font-light text-gray-500 transition-opacity
                    duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72
                    dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400">
                  <div class="p-3 space-y-2">
                    <h3 class="font-semibold text-gray-900 dark:text-white">
                      Share of Local Voice
                    </h3>
                    <p>
                      SoLV describes the advertising market share your business has through google maps for this location.
                      SoLV measures your potential exposure to search traffic given your visibility at a particular moment in time.
                    </p>
                    <p>SoLV scores are best when closest to 1 vs 0.</p>
                    <a
                      href="#"
                      class="flex items-center font-medium text-blue-600
                        dark:text-blue-500 dark:hover:text-blue-600 hover:text-blue-700">
                      Read more
                      <svg
                        class="w-4 h-4 ml-1"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0
                            011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd" />
                      </svg>
                    </a>
                  </div>
                  <div data-popper-arrow />
                </div>
              </dt>
              <dd class="mb-4 text-gray-500 dark:text-gray-400 sm:mb-5">
                <p class="text-3xl font-bold">
                  {{ selectedResult[geogridKeyword.id]?.solv ?? '-' }}
                </p>
              </dd>
              <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">
                Generated At
              </dt>
              <dd class="mb-4 text-gray-500 dark:text-gray-400 sm:mb-5">
                <p class="text-lg font-bold">
                  {{ selectedResult[geogridKeyword.id]?.createdAt ?? '-' }}
                </p>
              </dd>
            </dl>
          </div>
        </div>
        <div class="col-span-2">
          <div class="scroll hor whitespace-nowrap pb-4">
            <div
              v-for="geogridResult in geogridKeyword.geogridResults"
              :key="geogridResult.id"
              class="inline-block px-8">
              <div
                class="rounded p-2 border-transparent border-2 hover:border-gray-300 hover:cursor-pointer"
                @click="selectGrid(geogridKeyword, geogridResult)">
                <img
                  :src="geogridResult.imageUrl"
                  class="shadow m-2 max-h-96 inline-block">
                <p class="text-lg py-4">
                  {{ geogridResult.shortCreatedAt }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
