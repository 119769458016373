<script setup> 
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import { ref, watch } from 'vue'; 

const props = defineProps({
  context: Object
})

const values = ref({})

watch(values, (val) => {
  props.context.node.input({})
  props.context.node.input(val)
})
</script>

<template>
  <label class="block mb-1 font-bold text-sm">Date Range</label>
  <date-picker class="pt-2" v-model:value="values" range></date-picker>
  <p class="text-xs text-gray-500 mt-0 pt-0 mb-5"> Select date range for the report.</p>
</template>
