<template>
  <div class="onboarding-notes bg-white dark:bg-gray-800 p-6  relative">
    <!-- Overlay for initial loading -->
    <div v-if="isInitialLoading" class="absolute inset-0 bg-white dark:bg-gray-800 bg-opacity-75 dark:bg-opacity-75 flex justify-center items-center z-50">
      <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
    </div>

    <!-- Main content -->
    <div :class="{ 'opacity-50': isAnyLoading && !isInitialLoading }">
      <div class="flex justify-between items-center mb-6">
        <h2 class="text-2xl font-bold text-gray-800 dark:text-white">
          Onboarding Notes for {{ props.name }}
        </h2>
        <button @click="emits('onClosePanel')" class="text-blue-500 hover:text-blue-600 dark:text-blue-400 dark:hover:text-blue-300 transition duration-300">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>

      <div class="mb-8">
        <h3 class="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">Onboarding State</h3>
        <div class="relative">
          <select
            v-model="onboardingState"
            @change="updateOnboardingState"
            :disabled="isUpdatingState"
            class="w-full p-2 border border-gray-300 rounded-md bg-white dark:bg-gray-700 text-gray-800 dark:text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300"
          >
            <option value="pending_contact">Pending Contact</option>
            <option value="contacted">Contacted</option>
            <option value="onboarded">Onboarded</option>
          </select>
          <div v-if="isUpdatingState" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <svg class="animate-spin h-5 w-5 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </div>
      </div>

      <div class="mb-8">
        <h3 class="text-lg font-semibold mb-4 text-gray-700 dark:text-gray-300">Historical Notes</h3>
        <transition-group name="list" tag="ul" class="space-y-4">
          <li
            v-for="note in notes"
            :key="note.id"
            class="bg-gray-100 list-none dark:bg-gray-700 p-4 rounded-md shadow transition duration-300 hover:shadow-md"
          >
            <p class="text-gray-800 dark:text-gray-200 mb-2">{{ note.content }}</p>
            <span class="text-sm text-gray-500 dark:text-gray-400">
              {{ new Date(note.createdAt).toLocaleString() }}
            </span>
          </li>
        </transition-group>
      </div>

      <div>
        <h3 class="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">Add New Note</h3>
        <textarea
          v-model="newNote"
          rows="4"
          :disabled="isAddingNote"
          class="w-full p-2 border border-gray-300 rounded-md bg-white dark:bg-gray-700 text-gray-800 dark:text-white focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-300 mb-4"
          placeholder="Enter your note here..."
        ></textarea>
        <button
          @click="addNote"
          :disabled="isAddingNote"
          class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed relative"
        >
          <span :class="{ 'opacity-0': isAddingNote }">Add Note</span>
          <div v-if="isAddingNote" class="absolute inset-0 flex items-center justify-center">
            <svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
        </button>
      </div>
    </div>


  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import axios from 'axios'
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'

const emits = defineEmits(['onClosePanel', 'stateUpdated'])
const props = defineProps({
  id: Number,
  name: String
})

const notes = ref([])
const newNote = ref('')
const onboardingState = ref('')
const isInitialLoading = ref(true)
const isUpdatingState = ref(false)
const isAddingNote = ref(false)

const isAnyLoading = computed(() => {
  return isInitialLoading.value || isUpdatingState.value || isAddingNote.value
})

onMounted(async () => {
  await fetchInitialData()
})

async function fetchInitialData() {
  isInitialLoading.value = true
  try {
    await Promise.all([fetchNotes(), fetchOnboardingState()])
  } catch (error) {
    console.error('Error fetching initial data:', error)
  } finally {
    isInitialLoading.value = false
  }
}

async function fetchNotes() {
  try {
    const response = await axios.get(`/ajax/organizations/${props.id}/onboarding_notes`)
    notes.value = response.data
  } catch (error) {
    console.error('Error fetching notes:', error)
  }
}

async function fetchOnboardingState() {
  try {
    const response = await axios.get(`/ajax/organizations/${props.id}`)
    onboardingState.value = response.data.onboardingState
  } catch (error) {
    console.error('Error fetching onboarding state:', error)
  }
}

async function updateOnboardingState() {
  isUpdatingState.value = true
  try {
    await axios.patch(`/ajax/organizations/${props.id}/update_onboarding_state`, {
      onboarding_state: onboardingState.value
    })
    mdtoast.success('Updated Onboarding State', { duration: 10000 })
    emits('stateUpdated')
  } catch (error) {
    console.error('Error updating onboarding state:', error)
  } finally {
    isUpdatingState.value = false
  }
}

async function addNote() {
  if (!newNote.value.trim()) return
  isAddingNote.value = true
  try {
    await axios.post(`/ajax/organizations/${props.id}/add_note`, {
      content: newNote.value
    })
    newNote.value = ''
    await fetchNotes()
  } catch (error) {
    console.error('Error adding note:', error)
  } finally {
    isAddingNote.value = false
  }
}
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>