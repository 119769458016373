import { Controller } from '@hotwired/stimulus'
import { createApp } from 'vue'
import App from '../vue_widgets/city_search.vue'
import { plugin, defaultConfig } from '@formkit/vue'

export default class extends Controller {

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    this.vue = createApp(App).use(plugin, defaultConfig)
    this.vue.mount(this.element)
  }

  disconnect() {
    this.vue?.unmount()
  }
}
