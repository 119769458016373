import { Controller } from '@hotwired/stimulus'
import { createApp } from 'vue'
import App from '../vue_widgets/locations_table.vue'

export default class extends Controller {

  static values = { clientId: String }

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    this.vue = createApp(App, { clientId: this.clientIdValue })
    this.vue.mount(this.element)
  }

  disconnect() {
    this.vue?.unmount()
  }
}
