<script setup>

import { ref, watch, onMounted, defineProps } from 'vue'
import axios from 'axios'
import qs from 'qs'
import VueSlimTable from 'vue-slim-tables'
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'

onMounted(() => {
  //getConversations()
  //getTimeZones()
})

const columns = [
  { key: 'createdAt', title: 'Created', orderable: true },
  { key: 'aasmState', title: 'Conversation Control', orderable: true },
  { key: 'sessionId', title: 'Session ID' },
  { key: 'sender', title: 'Contact' },
  { key: 'messages', title: 'Msg Count' },
  { key: 'contactGathered', title: 'Contact Created' },
  { key: 'totalPromptTokens', title: 'Tokens'},
  { key: 'id', title: 'Actions' }
  //{ key: 'show_page_url', title: 'View' }
]

const searchValue = ref('')
const botLeadSearchValue = ref('')
const calendarEventSearchValue = ref('')
const table = ref(null)
const callSequenceTable = ref(null)
const botLeadsTable = ref(null)
const calendarEventsTable = ref(null)
let timeout = null

const tableSource = (params) => {
  const basePath = `/ajax/inboxes/${props.inboxId}/conversations`
  const path = `${basePath}?${qs.stringify({ ...params, search: searchValue.value })}`
  return axios.get(path).then((res) => res.data)
}

const botLeadColumns = [
  { key: 'id', title: 'ID', orderable: true },
  { key: 'company', title: 'Company', orderable: true },
  { key: 'email', title: 'Email' },
  { key: 'name', title: 'Name' },
  { key: 'summary', title: 'Summary' },
  { key: 'acas', title: 'ACAS'}
  //{ key: 'show_page_url', title: 'View' }
]

const botLeadTableSource = (params) => {
  const basePath = `/ajax/chatbots/${props.chatbotId}/bot_leads`
  const path = `${basePath}?${qs.stringify({ ...params, search: botLeadSearchValue.value })}`
  return axios.get(path).then((res) => res.data)
}

const calendarEventsColumns = [
  { key: 'id', title: 'ID', orderable: true },
  { key: 'aasmState', title: 'State', orderable: true },
  { key: 'eventTime', title: 'Event Time' },
  { key: 'attendeeEmails', title: 'Attendees' },
  { key: 'googleCalendarEmail', title: 'GCal' },
  { key: 'confirmationNumber', title: 'Conf' }
]

const callSequenceColumns = [
  { key: 'id', title: 'ID', orderable: true },
  { key: 'leadName', title: 'Lead Name' },
  { key: 'leadNumber', title: 'Lead Phone' },
  { key: 'recordingUrl', title: 'Recording' },
]

const calendarEventTableSource = (params) => {
  const basePath = `/ajax/inboxes/${props.inboxId}/calendar_events`
  const path = `${basePath}?${qs.stringify({ ...params, search: calendarEventSearchValue.value })}`
  return axios.get(path).then((res) => res.data)
}

const callSequenceTableSource = (params) => {
  const basePath = `/ajax/chatbots/${props.chatbotId}/lead_dialer_call_sequences`
  const path = `${basePath}?${qs.stringify({ ...params, search: calendarEventSearchValue.value })}`
  return axios.get(path).then((res) => res.data)
}

const destroyConversation = (conversationId) => {
  const conversationPath = `/conversations/${conversationId}`
  axios.delete(conversationPath)
  .then((res) => {
    mdtoast.success('Conversation destroyed', { duration: 10000 })
    refetchTableData()
  })
  .catch((error) => {
    console.error(error);
  });

}

const refetchTableData = () => {
  table.value?.refetch()
}

const refetchBotLeadTableData = () => {
  botLeadsTable.value?.refetch()
}

const refetchCalendarEventsTableData = () => {
  calendarEventsTable.value?.refetch()
}

const refetchConferenceCallsTableData = () => {
  callSequenceTable.value?.refetch()
}

watch(searchValue, () => {
  clearTimeout(timeout)
  timeout = setTimeout(refetchTableData, 300)
})

watch(botLeadSearchValue, () => {
  clearTimeout(timeout)
  timeout = setTimeout(refetchBotLeadTableData, 300)
})

watch(calendarEventSearchValue, () => {
  clearTimeout(timeout)
  timeout = setTimeout(refetchCalendarEventsTableData, 300)
})

const props = defineProps({
  chatbotId: String,
  inboxId: String
})


</script>

<template>
  <div>
    <div class="pb-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
        Conversations
        <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">These conversations are generated via the AI chatbot.</p>
    </div>
    <div class="form-group w-1/3 mb-3">
      <label for="search-websites" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search Conversations</label>
    <div class="relative">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
        </div>
        <input v-model="searchValue" type="search" id="search-websites" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search conversations..." required>

    </div>
  </div>

    <VueSlimTable
      ref="table"
      class="table w-full text-sm text-left text-gray-500 dark:text-gray-400"
      :columns="columns"
      :source="tableSource">
      <template #cell:createdAt="{ row, value }">
        <a :href="`/conversations/${row['id']}`">{{ value }}</a>
      </template>
      <template #cell:aasmState="{row, value}">
        <div v-if="value === 'openai_control'">
          🤖 AI
        </div>
        <div v-if="value === 'user_control'">
          👨🏼‍🦰 Human
        </div>
        <div v-if="value === 'appointment_control'">
          🗓️ Scheduling
        </div>
        <div v-if="value === 'calendar_event_control'">
          🗓️ Scheduling
        </div>
        <div v-if="value === 'event_update_control'">
          🗓️ + Updating Event
        </div>
         <div v-if="value === 'event_cancelation_control'">
          🗓️ ❌ Canceling Event
        </div>
        <div v-if="value === 'dynamic_intent_params_control'">
          🦾 Dynamic Intent
        </div>
        <div v-if="value === 'dynamic_intent_control'">
          🦾 Dynamic Intent
        </div>

      </template>
      <template #cell:contactGathered="{ row, value }">
        <div v-if="value == true">
          <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">Yes</span>

        </div>
        <div v-if="value == false">
           <span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">No</span>

        </div>
      </template>

      <template #cell:totalPromptTokens="{ row, value }">
        {{ row['totalPromptTokens'] }} / {{ row['totalCompletionTokens'] }}
      </template>

      <template #cell:id="{ row, value }">
        <a @click.prevent="destroyConversation(row['id'])"
         href="#" class="pb-2 text-xs text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg px-5 py-2.5 inline-flex items-center mr-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900 text-xs">
           <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"/>
  </svg>
       </a>
      </template>
      <!-- <template #cell:logo_image_path="{ value }">
        <img
          :src="value"
          alt="">
      </template>
      <template #cell:show_page_url="{ row, value }">
        <a :href="value">{{ row.url }}</a>
      </template> -->
      <template #pagination>
        <div></div>
      </template>
    </VueSlimTable>
  </div>

  <div>
    <div class="pb-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
        Bot Leads
        <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">These Leads are generated via the AI chatbot.</p>
    </div>
    <div class="form-group w-1/3 mb-3">
       <label ffor="search-bot-leads" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search Bot Leads</label>
    <div class="relative">
      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
      </div>
      <input v-model="botLeadSearchValue" type="search" id="search-bot-leads" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search bot leads..." required>
    </div>

    </div>
    <VueSlimTable
      ref="botLeadsTable"
      class="table w-full text-sm text-left text-gray-500 dark:text-gray-400"
      :columns="botLeadColumns"
      :source="botLeadTableSource">

      <!-- <template #cell:logo_image_path="{ value }">
        <img
          :src="value"
          alt="">
      </template>
      <template #cell:show_page_url="{ row, value }">
        <a :href="value">{{ row.url }}</a>
      </template> -->
      <template #pagination>
        <div></div>
      </template>
    </VueSlimTable>
  </div>

  <div>
    <div class="pb-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
        Calendar Events
        <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">These Events are generated via the AI chatbot.</p>
    </div>
    <div class="form-group w-1/3 mb-3">
      <label for="search-calendar-events" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search Calendar Events</label>
    <div class="relative">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
        </div>
        <input v-model="calendarEventSearchValue" type="search" id="search-calendar-events" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search calendar events..." required>

    </div>

    </div>
    <VueSlimTable
      ref="calendarEventsTable"
      class="table w-full text-sm text-left text-gray-500 dark:text-gray-400"
      :columns="calendarEventsColumns"
      :source="calendarEventTableSource">

      <template #cell:aasmState="{ row, value }">
        <div v-if="value == 'canceled'">
          <span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">Canceled</span>
        </div>
        <div v-if="value == 'time_confirmed'">
          <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">Time Confirmed</span>


        </div>
      </template>
      <template #cell:id="{ row, value }">
        <a :href="row['htmlLink']" target="_blank">
          <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 14">
          <g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
            <path d="M10 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
            <path d="M10 13c4.97 0 9-2.686 9-6s-4.03-6-9-6-9 2.686-9 6 4.03 6 9 6Z"/>
          </g>
        </svg>
      </a>
      </template>
      <!-- <template #cell:logo_image_path="{ value }">
        <img
          :src="value"
          alt="">
      </template>
      <template #cell:show_page_url="{ row, value }">
        <a :href="value">{{ row.url }}</a>
      </template> -->
      <template #pagination>
        <div></div>
      </template>
    </VueSlimTable>
  </div>


  <div>
    <div class="pb-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
        Phone Calls
        <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">These Conference Calls are initiated by Linda bot.</p>
    </div>
    <div class="form-group w-1/3 mb-3">
      <label for="search-calendar-events" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search Recordings</label>
    <div class="relative">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
        </div>
        <input v-model="conferenceRecordingSearchValue" type="search" id="search-calendar-events" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search phone calls..." required>

    </div>

    </div>
    <VueSlimTable
      ref="callSequenceTable"
      class="table w-full text-sm text-left text-gray-500 dark:text-gray-400"
      :columns="callSequenceColumns"
      :source="callSequenceTableSource">

      <!-- <template #cell:aasmState="{ row, value }">
        <div v-if="value == 'canceled'">
          <span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">Canceled</span>
        </div>
        <div v-if="value == 'time_confirmed'">
          <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">Time Confirmed</span>


        </div>
      </template> -->
      <template #cell:recordingUrl="{ row, value }">
        <div v-if="row['recordingUrl']" class='pt-2'>
            <audio class="w-full" controls>
                <source :src="value" type="audio/mpeg">
                Your browser does not support the audio element.
            </audio>
        </div>
      </template>
      <!-- <template #cell:logo_image_path="{ value }">
        <img
          :src="value"
          alt="">
      </template>
      <template #cell:show_page_url="{ row, value }">
        <a :href="value">{{ row.url }}</a>
      </template> -->
      <template #pagination>
        <div></div>
      </template>
    </VueSlimTable>
  </div>
</template>

<style scoped>
  .vst-th {
    font-weight: 200;
  }
</style>

