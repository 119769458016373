import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "table", "list" ]

  connect() {
    this.tableTarget.classList.remove('hidden')
  }

  tableView(event) {
    event.preventDefault();
    this.tableTarget.classList.remove('hidden')
    this.listTarget.classList.add('hidden')
  }

  listView(event) {
    event.preventDefault();
    this.listTarget.classList.remove('hidden')
    this.tableTarget.classList.add('hidden')
  }
}