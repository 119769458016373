function findPreviousElementWithSelector(element, selector = '') {
  let prevElement = element.previousElementSibling

  if (!selector) return prevElement

  while (prevElement) {
    if (prevElement.matches(selector)) {
      return prevElement
    }
    prevElement = prevElement.previousElementSibling
  }

  return null
}

document.addEventListener('DOMContentLoaded', () => {
  const passwordVisibilityToggles = document.querySelectorAll('[data-action="password-visibility-toggle"]')

  if (passwordVisibilityToggles?.length > 0) {
    passwordVisibilityToggles.forEach((toggle) => {
      toggle.addEventListener('click', () => {
        const prevElement = findPreviousElementWithSelector(toggle, 'input')
        if (!prevElement) return

        const type = prevElement.getAttribute('type')
        if (type === 'password') {
          prevElement.setAttribute('type', 'text')
          toggle.children[0].classList.remove('fa-eye-low-vision')
          toggle.children[0].classList.add('fa-eye')
        } else {
          prevElement.setAttribute('type', 'password')
          toggle.children[0].classList.remove('fa-eye')
          toggle.children[0].classList.add('fa-eye-low-vision')
        }
      })
    })
  }
})
