<script setup>

import { ref, watch, onMounted, reactive, defineProps } from 'vue'
import axios from 'axios'
import qs from 'qs'
import { token } from '@formkit/utils'
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'
import vue3Datepicker from 'vue3-datepicker';
import VueMultiselect from 'vue-multiselect'
import BotEditor from './bot_editor.vue'

onMounted(() => {
  getCalendars()
  getSelectedCalendars()
  getTimeZones()
  getInboxes()
})

const props = defineProps({
  organizationId: String,
  currentChatbotName: String,
  currentBusinessName: String,
  currentBusinessCity: String,
  currentBusinessState: String,
  currentDescription: String,
  currentGptModel: String,
  currentTimeZone: String,
  chatbotId: String,
  currentGoogleCalendars: Array,
  currentAppointmentConfirmation: String,
  currentServiceSystemPrompt: String,
  currentLeadValues: Array,
  currentOfficeHoursCallsStart: Number,
  currentOfficeHoursCallsEnd: Number,
  currentOutOfOfficeCallsMessage: String,
  currentAgentNumber: String,
  currentRecordingOption: Boolean,
  currentOfficeHoursStart: Number,
  currentOfficeHoursEnd: Number,
  currentOutOfOfficeMessage: String,
  currentEventDescription: String,
  currentEventTitle: String,
  officeIntent: Boolean,
  callIntent: Boolean,
  appointmentIntent: Boolean,
  serviceIntent: Boolean,
  currentWebhookUrl: String,
  currentPhoneRequired: Boolean,
  currentEmailRequired: Boolean,
  currentCompanyRequired: Boolean,
  currentSlackWebhookUrl: String,
  currentSlackEventNotifications: Boolean,
  currentSlackMessageNotifications: Boolean,
  currentTemperature: Number,
  currentRateLimitMessage: String,
  currentMinuteRateLimit: Number,
  currentDaysOff: Array,
  currentBusinessScheduleDescription: String,
  currentHolidayScheduleDescription: String,
  currentBotType: String,
  currentCurrentPromotions: String,
  currentServiceAreaDescription : String,
  currentServicesOfferedDescription: String,
  currentPricingDescription: String,
  currentKnowledgeSeekingPrompt: String,
  currentInbox: Object,
  apiKey: String,
  apiKeyRequired: Boolean,
  currentContentModeration: Boolean,
  phoneCapable: Boolean
})

onMounted(() => {
  chatbotName.value = props.currentChatbotName
  businessCity.value = props.currentBusinessCity
  businessState.value = props.currentBusinessState
  gptModel.value = props.currentGptModel
  description.value = props.currentDescription
  selectedTimeZone.value = props.currentTimeZone
  appointmentConfirmationMessage.value = props.currentAppointmentConfirmation
  serviceSystemPrompt.value = props.currentServiceSystemPrompt
  leadValues.value = props.currentLeadValues
  officeHoursCallsStart.value = props.currentOfficeHoursCallsStart
  officeHoursCallsEnd.value = props.currentOfficeHoursCallsEnd
  officeHoursStart.value = props.currentOfficeHoursStart
  officeHoursEnd.value = props.currentOfficeHoursEnd
  outOfOfficeCallsMessage.value = props.currentOutOfOfficeCallsMessage
  phoneNumber.value = props.currentAgentNumber
  callRecording.value = props.currentRecordingOption
  outOfOfficeMessage.value = props.currentOutOfOfficeMessage
  businessName.value = props.currentBusinessName
  selectedCalendars.value = props.currentGoogleCalendars
  eventDescription.value = props.currentEventDescription
  eventTitle.value = props.currentEventTitle
  webhookUrl.value = props.currentWebhookUrl
  phoneRequired.value = props.currentPhoneRequired
  emailRequired.value = props.currentEmailRequired
  companyRequired.value = props.currentCompanyRequired
  slackWebhookUrl.value = props.currentSlackWebhookUrl
  slackEventNotifications.value = props.currentSlackEventNotifications
  slackMessageNotifications.value = props.currentSlackMessageNotifications
  temperature.value = props.currentTemperature
  rateLimitMessage.value = props.currentRateLimitMessage
  minuteRateLimit.value = props.currentMinuteRateLimit
  businessScheduleDescription.value = props.currentBusinessScheduleDescription
  holidayScheduleDescription.value = props.currentHolidayScheduleDescription
  botType.value = props.currentBotType
  currentPromotions.value = props.currentCurrentPromotions
  servicesOfferedDescription.value = props.currentServicesOfferedDescription
  serviceAreaDescription.value= props.currentServiceAreaDescription
  pricingDescription.value = props.currentPricingDescription
  knowledgeSeekingPrompt.value = props.currentKnowledgeSeekingPrompt
  selectedInbox.value = props.currentInbox
  apptChecked.value = props.appointmentIntent
  officeChecked.value = props.officeIntent
  phoneChecked.value = props.callIntent
  serviceChecked.value = props.serviceIntent
  openaiApiKey.value = props.apiKey
  contentModeration.value = props.currentContentModeration
})

const openaiApiKey = ref('')

const selectedInbox = ref({})
const inboxes = ref([])
const botType = ref('')
const currentPromotions = ref('')

const servicesOfferedDescription = ref('')
const serviceAreaDescription = ref('')
const pricingDescription = ref('')

const businessScheduleDescription = ref('')
const holidayScheduleDescription = ref('')
const daysOff = reactive(props.currentDaysOff || [])
const selectedDate = ref(null)
const newDaysOff = ref([])
const temperature = ref(1.0)

const slackEventNotifications = ref(false)
const slackMessageNotifications = ref(false)
const slackWebhookUrl = ref('')

const calendars = ref([])
const loadingCalendars = ref(true)
const selectedCalendars = ref([])

const appointmentConfirmationMessage = ref('')
const eventDescription = ref('')
const eventTitle = ref('')
const webhookUrl = ref('')

const phoneRequired = ref(false)
const emailRequired = ref(false)
const companyRequired = ref(false)

const timeZones = ref([])
const selectedTimeZone = ref()
// TODO: implement business location support for full platform users
// const businessLocations = ref([])
// const loadingBusinessLocations = ref(true)
// const selectedBusinessLocation = ref()

// checkbox states
const apptChecked = ref(false)
const phoneChecked = ref(false)
const serviceChecked = ref(false)
const officeChecked = ref(false)

const officeHoursStart = ref(0)
const officeHoursEnd = ref(23)
const officeHoursCallsStart = ref(0)
const officeHoursCallsEnd = ref(23)

const outOfOfficeMessage = ref('')
const outOfOfficeCallsMessage = ref('')

const serviceSystemPrompt = ref('')

const callRecording = ref()
const phoneNumber = ref('')

const chatbotName = ref('')
const description = ref('')
const businessName = ref('')
const businessCity = ref('')
const businessState = ref('')
const gptModel = ref('')
const rateLimitMessage = ref('')
const minuteRateLimit = ref('')
const formLoading = ref(false)

const contentModeration = ref('')

const getInboxes = () => {
  axios.get(`/ajax/organizations/${props.organizationId}/inboxes`).then((response) => {

    inboxes.value = response.data.result
  }).catch((error) => {
    console.log(error)
  })
}

const getTimeZones = () => {
  axios.get(`/ajax/time_zones`).then((response) => {
    timeZones.value = response.data
  }).catch((error) => {
    console.log(error)
  })
}

const getCalendars = () => {
  axios.get(`/ajax/organizations/${props.organizationId}/google_calendars`).then((response) => {

    loadingCalendars.value = false
    calendars.value = response.data.results
  }).catch((error) => {
    console.log(error)
  })
}

const getSelectedCalendars = () => {
  axios.get(`/ajax/chatbots/${props.chatbotId}/google_calendars`).then((response) => {

    selectedCalendars.value = response.data.calendars
  }).catch((error) => {
    console.log(error)
  })
}

const leadValues = ref([{ name: '', type: '', description: '' }])

const addLeadValue = () => {
  leadValues.value.push({ name: '', type: '', description: '' })
}

const removeLeadValue = (index) => {
  leadValues.value.splice(index, 1)
}

const localAttributesLoading = ref(false)

const updateLocalAttributes = async () => {
  localAttributesLoading.value = true
  axios.post(`/ajax/chatbots/${props.chatbotId}/update_local_attributes`, {
    chatbot_name: chatbotName.value,
    description: description.value,
    gpt_model: gptModel.value,
    temperature: temperature.value,
    rate_limit_message: rateLimitMessage.value,
    minute_rate_limit: minuteRateLimit.value,
    slack_webhook_url: slackWebhookUrl.value,
    slack_event_notifications: slackEventNotifications.value,
    slack_message_notifications: slackMessageNotifications.value,
    bot_type: botType.value,
    selected_inbox: selectedInbox.value,
    api_key: openaiApiKey.value,
    content_moderation: contentModeration.value
  }).then((response) => {
    localAttributesLoading.value = false
    mdtoast.info('Local attributes updated.', { duration: 10000 })
  }).catch((error) => {
    console.log(error)
  })
}


const requiredAttributesLoading = ref(false)

const updateRequiredAttributes = async () => {
  requiredAttributesLoading.value = true
  axios.post(`/ajax/chatbots/${props.chatbotId}/update_required_attributes`, {
    phone_required: phoneRequired.value,
    email_required: emailRequired.value,
    company_required: companyRequired.value,
    time_zone: selectedTimeZone.value
  }).then((response) => {
    requiredAttributesLoading.value = false
    mdtoast.info('Intent attributes updated.', { duration: 10000 })
  }).catch((error) => {
    console.log(error)
  })
}

const calendarAttributesLoading = ref(false)

const updateCalendarAttributes = async () => {
  calendarAttributesLoading.value = true
  axios.post(`/ajax/chatbots/${props.chatbotId}/update_calendar_attributes`, {
    google_calendar_ids: selectedCalendars.value.map(calendar => calendar['value']),
    appointment_confirmation_message: appointmentConfirmationMessage.value,
    event_description: eventDescription.value,
    event_title: eventTitle.value
  }).then((response) => {
    calendarAttributesLoading.value = false
    mdtoast.info('Appointment settings updated.', { duration: 10000 })
  }).catch((error) => {
    console.log(error)
  })
}

const serviceAttributesLoading = ref(false)

const updateServiceAttributes = async () => {
  serviceAttributesLoading.value = true
  axios.post(`/ajax/chatbots/${props.chatbotId}/update_service_attributes`, {
    business_name: businessName.value,
    business_city: businessCity.value,
    business_state: businessState.value,
    service_system_prompt: serviceSystemPrompt.value,
    webhook_url: webhookUrl.value,
    lead_values: leadValues.value
  }).then((response) => {
    serviceAttributesLoading.value = false
    mdtoast.info('Lead qualification settings updated.', { duration: 10000 })
  }).catch((error) => {
    console.log(error)
  })
}

const callAttributesLoading = ref(false)

const updateCallAttributes = async () => {
  callAttributesLoading.value = true
  axios.post(`/ajax/chatbots/${props.chatbotId}/update_call_attributes`, {
    office_hours_calls_start: officeHoursCallsStart.value,
    office_hours_calls_end: officeHoursCallsEnd.value,
    agent_number: phoneNumber.value,
    out_of_office_calls_message: outOfOfficeCallsMessage.value,
    call_recording: callRecording.value,
  }).then((response) => {
    callAttributesLoading.value = false
    mdtoast.info('Phone settings updated.', { duration: 10000 })
  }).catch((error) => {
    console.log(error)
  })
}

const officeAttributesLoading = ref(false)

const updateOfficeAttributes = async () => {
  officeAttributesLoading.value = true
  axios.post(`/ajax/chatbots/${props.chatbotId}/update_office_attributes`, {
    office_hours__start: officeHoursStart.value,
    office_hours__end: officeHoursEnd.value,
    out_of_office_message: outOfOfficeMessage.value,
  }).then((response) => {
    officeAttributesLoading.value = false
    mdtoast.info('Huamn Handoff settings updated.', { duration: 10000 })
  }).catch((error) => {
    console.log(error)
  })
}

const knowledgeAttributesLoading = ref(false)
const knowledgeSeekingPrompt = ref('')
const updateKnowledgeAttributes = async () => {
  knowledgeAttributesLoading.value = true
  axios.post(`/ajax/chatbots/${props.chatbotId}/update_knowledge_attributes`, {
    prompt: knowledgeSeekingPrompt.value
  }).then((response) => {
    knowledgeAttributesLoading.value = false
    mdtoast.info('Knowledge base settings updated.', { duration: 10000 })
  }).catch((error) => {
    console.log(error)
  })
}

const businessHourAttributesLoading = ref(false)

const updateHourAttributes = async () => {
  businessHourAttributesLoading.value = true
  saveNewDaysOff()
  axios.post(`/ajax/chatbots/${props.chatbotId}/update_hour_attributes`, {
    days_off: daysOff,
    businessScheduleDescription: businessScheduleDescription.value,
    holidayScheduleDescription: holidayScheduleDescription.value
  }).then((response) => {
    businessHourAttributesLoading.value = false
    mdtoast.info('Hour settings updated.', { duration: 10000 })
  }).catch((error) => {
    console.log(error)
  })
}

const promoAttributesLoading = ref(false)

const updatePromoAttributes = async () => {
  promoAttributesLoading.value = true
  axios.post(`/ajax/chatbots/${props.chatbotId}/update_promo_attributes`, {
    current_promotions: currentPromotions.value
  }).then((response) => {
    promoAttributesLoading.value = false
    mdtoast.info('Promo settings updated.', { duration: 10000 })
  }).catch((error) => {
    console.log(error)
  })
}

const servicesAttributesLoading = ref(false)

const updateServicesAttributes = async () => {
  servicesAttributesLoading.value = true
  axios.post(`/ajax/chatbots/${props.chatbotId}/update_services_attributes`, {
    services_offered_description: servicesOfferedDescription.value
  }).then((response) => {
    servicesAttributesLoading.value = false
    mdtoast.info('Service settings updated.', { duration: 10000 })
  }).catch((error) => {
    console.log(error)
  })
}


const serviceAreaAttributesLoading = ref(false)

const updateServiceAreaAttributes = async () => {
  serviceAreaAttributesLoading.value = true
  axios.post(`/ajax/chatbots/${props.chatbotId}/update_service_area_attributes`, {
    service_area_description: serviceAreaDescription.value
  }).then((response) => {
    serviceAreaAttributesLoading.value = false
    mdtoast.info('Service Area settings updated.', { duration: 10000 })
  }).catch((error) => {
    console.log(error)
  })
}

const pricingAttributesLoading = ref(false)

const updatePricingAttributes = async () => {
  pricingAttributesLoading.value = true
  axios.post(`/ajax/chatbots/${props.chatbotId}/update_pricing_attributes`, {
    pricing_description: pricingDescription.value
  }).then((response) => {
    pricingAttributesLoading.value = false
    mdtoast.info('Price settings updated.', { duration: 10000 })
  }).catch((error) => {
    console.log(error)
  })
}

const saveNewDaysOff = () => {
  daysOff.push(...newDaysOff.value.map(date => ({ date: date })))
  newDaysOff.value = [];
}


const activeContent = ref('settings')


const removeDayOff = (index) => {
  daysOff.splice(index, 1)
}

const removeNewDayOff = (index) => {
  newDaysOff.value.splice(index, 1)
}

const addDayOff = () => {
  newDaysOff.value.push(null)
}

const updateIntent = (intent) => {
  setLoadingAttribute(intent, true)
  axios.post(`/ajax/chatbots/${props.chatbotId}/toggle_intents`, { intent: intent })
  .then((response) => {
    setLoadingAttribute(intent, false)
    if (response.data.response[0] === 'on') {
      toggleIntent(intent, true)
      mdtoast.success('Intent on.', { duration: 10000 })
    } else {
      toggleIntent(intent, false)
      mdtoast.info('Intent off.', { duration: 10000 })
    }
  }).catch((error) => {
    console.log(error)
  })
}

const setLoadingAttribute = (intent, bool) => {
  if (intent === 'office.initiate') {
    officeAttributesLoading.value = bool
  } else if (intent === 'schedule.appointment') {
    calendarAttributesLoading.value = bool
  } else if (intent === 'call.initiate') {
    callAttributesLoading.value = bool
  } else if (intent === 'service.initiate') {
    serviceAttributesLoading.value = bool
  }
}

const toggleIntent = (intent, bool) => {
  if (intent === 'office.initiate') {
    officeChecked.value = bool
  } else if (intent === 'schedule.appointment') {
    apptChecked.value = bool
  } else if (intent === 'call.initiate') {
    phoneChecked.value = bool
  } else if (intent === 'service.initiate') {
    serviceChecked.value = bool
  }
}


const menuItems = [
  { text: "⚙️ Settings", content: "settings" },
  { text: "🕰️ Timezone", content: "timezone" },
  { text: "🗓️ Appointments", content: "appointments" },
  { text: "🤖 Qualification", content: 'qualification' },
  { text: "📚 Knowledge", content: 'knowledge' },
  { text: "🏷️ Promotions", content: 'promotions' },
  { text: "⏰ Hours", content: 'hours' },
  { text: "⌨️ Hand Off", content: 'handoff' },
  { text: "📱 Phone", content: 'phone' },
  { text: "🧳 Services Offered", content: 'services_offered' },
  { text: "🗺️ Service Area", content: 'service_area' },
  { text: "💰 Pricing", content: 'pricing' },

];

const setActiveContent = (content) => {
  activeContent.value = content
}
</script>

<template>
<section class="bg-white dark:bg-gray-900">
  <div class="max-w-screen-xl px-4 items-center mx-auto">

    <div class="flex flex-wrap rounded-md  mb-4" role="group">
      <div class="relative">
    <!-- Dropdown Trigger -->
    <button id="dropdownMenuButton" data-dropdown-toggle="dropdownMenu" data-dropdown-placement="bottom" class="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
      {{ activeContent }}
      <svg class="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
      </svg>
    </button>

    <!-- Dropdown Menu -->
    <div id="dropdownMenu" class="z-10 hidden bg-white rounded-lg shadow w-60 dark:bg-gray-700">
      <ul class="list-none  py-2 overflow-y-auto text-gray-700 dark:text-gray-200">
        <!-- Looping through the menu items -->
        <li v-for="item in menuItems" :key="item.text">
          <button
            @click="setActiveContent(item.content)"
            class="w-full flex items-center px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
            {{ item.text }}
          </button>
        </li>
      </ul>
    </div>
  </div>
    </div>

      <transition name="fade">
        <div v-show="activeContent === 'settings'">
          <h6 class="text-lg font-bold dark:text-white">Settings</h6>
          <div id="alert-additional-content-5" class="p-4 my-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-800" role="alert">
            <div class="flex items-center">
              <svg class="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span class="sr-only">Info</span>
              <h3 class="text-lg font-medium text-gray-800 dark:text-gray-300">Bot Settings</h3>
            </div>
            <div class="mt-2 mb-4 text-sm text-gray-800 dark:text-gray-300">
              These values control critical OpenAI settings and your (optional) Slack integration.
            </div>
            <div class="flex">
              <button data-modal-target="extralarge-modal" data-modal-toggle="extralarge-modal" type="button" class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-gray-600 dark:hover:bg-gray-500 dark:focus:ring-gray-800">
                <svg class="-ml-0.5 mr-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                  <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"/>
                </svg>
                Visual Editor
              </button>


            </div>
          </div>
          <div v-if="!localAttributesLoading" class="py-4 sm:col-span-2">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
            <input v-model="chatbotName" type="text" name="name" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"  required="">
            <small class='text-gray-400'>The name of the chatbot will be seen during conversations with customers.</small>
          </div>

          <div class="sm:col-span-2" v-if="!localAttributesLoading">
              <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
              <textarea v-model="description" id="description" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Your chatbot description"></textarea>
              <small class='text-gray-400'>The description is for internal usage only.</small>
          </div>

          <div class="sm:col-span-2 py-4" v-if="props.apiKeyRequired && !localAttributesLoading">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">OpenAI API Key</label>
            <input v-model="openaiApiKey" type="text" name="name" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="API Key" required="">
            <small class='text-gray-400'>The OpenAI API key you want to use with this bot.</small>
          </div>

          <div class="sm:col-span-2 pt-4 pb-2" v-if="!localAttributesLoading">

            <label>
              GPT Model
              <select v-model="gptModel" required>
                <option value='' selected>
                  Select a Model
                </option>
                <option value='gpt-3.5-turbo-0613'>
                  gpt-3.5-turbo-0613
                </option>
                <option value='gpt-4-0613'>
                  gpt-4-0613
                </option>
              </select>
            </label>
            <small class='text-gray-400'>Choose between the latest GPT3.5 and GPT4 models.</small>
          </div>

          <div class="sm:col-span-2" v-if="!localAttributesLoading">
              <label for="inbox_id" class="pt-4 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Inbox</label>
              <VueMultiselect
                  class=""
                  v-model="selectedInbox"
                  :options="inboxes"
                  placeholder="Select inbox"
                  label="name"
                  track-by="value"
                  >
              </VueMultiselect>
          </div>

          <div class="sm:col-span-2 py-4" v-if="!localAttributesLoading">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bot Type {{botType.value}} </label>
            <ul class="list-none grid w-full gap-6 md:grid-cols-3">
              <li>
                  <input v-model="botType" type="radio" id="chatbot-hybrid" value="hybrid" class="hidden peer">
                  <label for="chatbot-hybrid" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                      <div class="block">
                        🤖 📚

                          <div class="pt-4 w-full text-lg font-semibold">Hybrid</div>
                          <div class="w-full text-sm">Knowledge Enriched + Lead Qual</div>
                      </div>
                  </label>
                  <small class='text-gray-400'>Dual handling of both intents.</small>
              </li>
              <li>
                  <input v-model="botType" type="radio" id="chatbot-know" value="knowledge" class="hidden peer">
                  <label for="chatbot-know" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                      <div class="block">
                          📖 🤓
                          <div class="pt-4 w-full text-lg font-semibold">Knowledge Enriched</div>
                          <div class="w-full text-sm">Document backed answers</div>
                      </div>
                  </label>
                  <small class='text-gray-400'>Answers from your documents only.</small>
              </li>
              <li>
                  <input v-model="botType" type="radio" id="chatbot-lead" value="lead_qualification" class="hidden peer">
                  <label for="chatbot-lead" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                      <div class="block">
                          💬 🔍
                          <div class="pt-4 w-full text-lg font-semibold">Lead Qualification</div>
                          <div class="w-full text-sm">Qualifying Lead as primary goal</div>
                      </div>
                  </label>
                  <small class='text-gray-400'>Dynamically qualify your intake leads.</small>
              </li>
            </ul>
          </div>


          <div class="sm:col-span-2 py-4" v-if="!localAttributesLoading">
            <label for="content-moderation-on" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Content Moderation</label>
            <ul class="list-none grid w-full gap-6 md:grid-cols-3">
              <li>
                  <input v-model="contentModeration" type="radio" id="content-moderation-on" value="true" class="hidden peer">
                  <label for="content-moderation-on" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                      <div class='block'>
                       🤬 ✅


                          <div class="pt-4 w-full text-lg font-semibold">On</div>
                          <div class="w-full text-sm">Messages are filtered</div>
                      </div>
                  </label>
                  <small class='text-gray-400'>Profane language is automatically filtered.</small>
              </li>
              <li>
                  <input v-model="contentModeration" type="radio" id="content-moderation-off" value="false" class="hidden peer">
                  <label for="content-moderation-off" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                      <div class="block">
                          🤬 ❌
                          <div class="pt-4 w-full text-lg font-semibold">Off</div>
                          <div class="w-full text-sm">Messages are unfiltered</div>
                      </div>
                  </label>
                  <small class='text-gray-400'>Profane language is not automatically filtered.</small>
              </li>

            </ul>
          </div>

          <div class="sm:col-span-2" v-if="!localAttributesLoading">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Temperature </label>
            <input v-model.number="temperature" type="range" min="0" max="2" step="0.1" id="slider" class="w-full h-1 mb-2 bg-gray-200 rounded-lg appearance-none cursor-pointer range-sm dark:bg-gray-700">
            <small class='text-gray-400'>Selected Temperature: {{ temperature }}</small><br>
            <small class='text-gray-400'>Temperature values control the creativity of your bot. 0 = Reserved, 2 = Creative. </small>
          </div>


          <div class="sm:col-span-2 py-4" v-if="!localAttributesLoading">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Rate Limit </label>
            <input v-model.number="minuteRateLimit" type="number" name="name" id="minute_rate_limit" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="10">
            <small class='text-gray-400'>How many messages a unique conversation may have over a 60 second period. </small>
          </div>

          <div class="sm:col-span-2" v-if="!localAttributesLoading">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Rate Limit Message </label>
            <input v-model="rateLimitMessage" type="text" name="name" id="rate_limit_message" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Rate limit reached.">
            <small class='text-gray-400'>Error message the user receives when rate limited. </small>
          </div>


           <div class="sm:col-span-2 py-4" v-if="!localAttributesLoading">
              <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Slack Webhook URL</label>
              <input v-model="slackWebhookUrl" type="text" name="name" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="https://yoursubdomain.slack.com/.......">
              <small class='text-gray-400'>The webhook URL for slack notifications (optional).</small>
          </div>

          <div class="sm:col-span-2" v-if="!localAttributesLoading">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Slack Notifications </label>
            <ul class="list-none grid w-full gap-6 md:grid-cols-3">
                <li>
                  <input v-model="slackMessageNotifications" type="checkbox" id="slack-messages" value="" class="hidden peer">
                  <label for="slack-messages" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                      <div class="block">
                          💬 ✅
                          <div class="pt-4 w-full text-lg font-semibold">Messages</div>
                          <div class="w-full text-sm">All Messages</div>
                      </div>
                  </label>
                  <small class='text-gray-400'>Send every message (both user and bot) to Slack.</small>
              </li>
              <li>
                  <input v-model="slackEventNotifications"  type="checkbox" id="slack-events" value="" class="hidden peer">
                  <label for="slack-events" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                      <div class="block">
                          ⚡️ ✅
                          <div class="pt-4 w-full text-lg font-semibold">Events</div>
                          <div class="w-full text-sm">All Events</div>
                      </div>
                  </label>
                  <small class='text-gray-400'>Send Events to Slack.</small>
              </li>
            </ul>
          </div>

          <div v-if="localAttributesLoading">
              <div role="status">
                <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span class="sr-only">Loading...</span>
            </div>
          </div>



          <div class="sm:col-span-2">
            <button @click.prevent="updateLocalAttributes()" class="py-4 my-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Update Settings</button>

           </div>
        </div>
      </transition>

      <transition name="fade">
        <div v-show="activeContent === 'appointments'">
          <ul class='list-none'>
            <li class='inline-block'>
              <h6 class="text-lg font-bold dark:text-white">Appointments</h6>
            </li>
            <li class='inline-block ml-4 pt-2'>
              <label class="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" v-model="apptChecked" @change="updateIntent('schedule.appointment')" class="sr-only peer">
                <div class="w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-blue-600"></div>
                <div class="after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:after:translate-x-full peer-checked:after:border-white"></div>
                <span v-if="!calendarAttributesLoading && apptChecked" class="ml-2 bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 rounded dark:bg-green-900 dark:text-green-300">Live</span>
                <span v-if="!calendarAttributesLoading && !apptChecked" class="ml-2 bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">Off</span>
                <div v-if="calendarAttributesLoading" role="status">
                  <svg aria-hidden="true" class="inline w-6 h-6 mr-2 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                  </svg>
                  <span class="sr-only">Loading...</span>
              </div>
              </label>
            </li>
          </ul>
          <div v-if="apptChecked" id="alert-additional-content-5" class="p-4 my-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-800" role="alert">
            <div class="flex items-center">
              <svg class="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span class="sr-only">Info</span>
              <h3 class="text-lg font-medium text-gray-800 dark:text-gray-300">Calendar Event Settings</h3>
            </div>
            <div class="mt-2 mb-4 text-sm text-gray-800 dark:text-gray-300">
              Set your Calendar Event formatting settings and which Calendars you schedule events to here.
            </div>
            <div class="flex">

            </div>
          </div>
          <div class="sm:col-span-2 py-4" v-if="apptChecked">
            <div class="col-span-2" v-if="!calendarAttributesLoading">
              <div v-if="loadingCalendars" class="col-span-2">
                Loading Calendars...
              </div>
              <div class="col-span-2">
                <label for="appt_confirmation_message" class="pt-4 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Calendars</label>
                <VueMultiselect
                    class="mt-2"
                    v-model="selectedCalendars"
                    :options="calendars"
                    multiple="true"
                    label="name"
                    track-by="value"
                    >
                </VueMultiselect>
                <small class='text-gray-400'>The calendar(s) you'll schedule appointments onto via the bot.</small>

                <div v-if="!calendarAttributesLoading">
                  <label for="appt_confirmation_message" class="pt-4 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Appointment Confirmation Message</label>
                  <textarea v-model="appointmentConfirmationMessage" id="appt_confirmation_message" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Your appointment is confirmed. You will receive an email shortly."></textarea>
                  <small class='text-gray-400'>The message the bot says after a customer successfully schedules an appointment.</small>
                </div>

                <div v-if="!calendarAttributesLoading" >
                  <label for="event_description" class="pt-4 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Event Description</label>
                  <textarea v-model="eventDescription" id="event_description" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="The event description that attendees will seein their calendar."></textarea>
                  <small class='text-gray-400'>This will be the calendar event description/summary.</small>
                </div>

                <div class='pt-4'>
                  <label for="event_title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Event Title</label>
                  <input v-model="eventTitle" type="text" name="event_title" id="event_title" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Type Chatbot name" required="">
                  <small class='text-gray-400'>The title of the calendar event.</small>
                </div>

                <div v-if="calendarAttributesLoading">
                  <div role="status">
                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>

                <div class="sm:col-span-2 my-2">
                <button @click.prevent="updateCalendarAttributes()" class="py-4 my-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Update Calendar</button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>



      <transition name="fade">
        <div v-show="activeContent === 'timezone'">
          <h6 class="text-lg font-bold dark:text-white">Timezone</h6>
          <div id="alert-additional-content-5" class="p-4 my-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-800" role="alert">
            <div class="flex items-center">
              <svg class="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span class="sr-only">Info</span>
              <h3 class="text-lg font-medium text-gray-800 dark:text-gray-300">TZ Settings</h3>
            </div>
            <div class="mt-2 mb-4 text-sm text-gray-800 dark:text-gray-300">
              Set your Bot's Timezone.
            </div>
            <div class="flex">
              <!-- <button type="button" class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-gray-600 dark:hover:bg-gray-500 dark:focus:ring-gray-800">
                <svg class="-ml-0.5 mr-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                  <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"/>
                </svg>
                View help
              </button> -->
            </div>
          </div>

          <div class="sm:col-span-2"  v-if="!requiredAttributesLoading">
            <label for="phone-required-option" class="pb-2 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Required Contact Details</label>
            <ul class="list-none grid w-full gap-6 md:grid-cols-3">
                <li>
                    <input v-model="phoneRequired" type="checkbox" id="phone-required-option" value="" class="hidden peer" required="">
                    <label :class="{'selected': phoneRequired}" for="phone-required-option" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                        <div class="block">
                            ☎️ 📱
                <div class="pt-4 w-full text-lg font-semibold">Phone Number</div>
                            <div class="w-full text-sm">Contact Phone</div>
                        </div>
                    </label>
                </li>
                <li>
                    <input v-model="emailRequired"  type="checkbox" id="email-option" value="" class="hidden peer">
                    <label for="email-option" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                        <div class="block">
                            📧 ✉️
                            <div class="pt-4 w-full text-lg font-semibold">Email</div>
                            <div class="w-full text-sm">Contact Email Address</div>
                        </div>
                    </label>
                </li>
                <li>
                    <input v-model="companyRequired"  type="checkbox" id="company-option" value="" class="hidden peer">
                    <label for="company-option" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                        <div class="block">
                            🏤 🏢
                            <div class="pt-4 w-full text-lg font-semibold">Company</div>
                            <div class="w-full text-sm">Contact Company</div>
                        </div>
                    </label>
                </li>
            </ul>
          </div>

          <div class="sm:col-span-2 py-4" v-if="!requiredAttributesLoading">
            <label>
              Time Zones
              <select v-model="selectedTimeZone" required>
                <option v-for="timeZone in timeZones" :key="timeZone" :value="timeZone">
                  {{ timeZone }}
                </option>
              </select>
            </label>
            <small class='text-gray-400'>The time zone of the chatbot controls when human hand off and conference call generation work.</small>
          </div>

          <div v-if="requiredAttributesLoading">
              <div role="status">
                <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div class="sm:col-span-2">
            <button @click.prevent="updateRequiredAttributes()" class="py-4 my-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Update Menu</button>

           </div>
        </div>
      </transition>

      <transition name="fade">
        <div v-show="activeContent === 'qualification'">
          <ul class='list-none'>
            <li class='inline-block'>
              <h6 class="text-lg font-bold dark:text-white">Lead Qualification</h6>
            </li>
            <li class='inline-block ml-4 pt-2'>
              <label class="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" v-model="serviceChecked" @change="updateIntent('service.initiate')" class="sr-only peer">
                <div class="w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-blue-600"></div>
                <div class="after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:after:translate-x-full peer-checked:after:border-white"></div>
                <span v-if="!serviceAttributesLoading && serviceChecked" class="ml-2 bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 rounded dark:bg-green-900 dark:text-green-300">Live</span>
                <span v-if="!serviceAttributesLoading && !serviceChecked" class="ml-2 bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">Off</span>
                <div v-if="serviceAttributesLoading" role="status">
                  <svg aria-hidden="true" class="inline w-6 h-6 mr-2 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                  </svg>
                  <span class="sr-only">Loading...</span>
              </div>
              </label>
            </li>
          </ul>
        <div id="alert-additional-content-5" class="p-4 my-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-800" role="alert">
            <div class="flex items-center">
              <svg class="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span class="sr-only">Info</span>
              <h3 class="text-lg font-medium text-gray-800 dark:text-gray-300">Lead Qualification Settings</h3>
            </div>
            <div class="mt-2 mb-4 text-sm text-gray-800 dark:text-gray-300">
              Using natural language, set the values that your Bot should extract from every conversation. Dynamically build your lead qualification.
            </div>
            <div class="flex">

            </div>
          </div>
        <div class="col-span-2 py-4" v-if="serviceChecked">

              <div class="col-span-2">
                <div v-if="!serviceAttributesLoading">
                <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Business Name</label>
                  <input v-model="businessName" type="text" name="business_name" id="business_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Business Name" required="">
                  <small class='text-gray-400'>The name of the business the Chatbot is representing.</small>
                </div>
                <div v-if="!serviceAttributesLoading" class="mt-4">
                  <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Business City</label>
                  <input v-model="businessCity" type="text" name="business_city" id="business_state" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Business City" required="">
                  <small class='text-gray-400'>The business' city.</small>
                </div>
                <div v-if="!serviceAttributesLoading" class="mt-4">
                  <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Business State</label>
                  <input v-model="businessState" type="text" name="business_state" id="business_state" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Business State" required="">
                  <small class='text-gray-400'>The business' state.</small>
                </div>
                <div v-if="!serviceAttributesLoading" class="mt-4">
                  <label for="service_system_prompt" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Service System Prompt</label>
                  <textarea v-model="serviceSystemPrompt" id="service_system_prompt" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="You are a helpful, polite chatbot for Growth Foundry, a Digital Marketing firm in Hanover, PA (USA)."></textarea>
                  <small class='text-gray-400'>The initial portion of the OpenAI prompt where you can control tone and conversational characteristics.</small>
                </div>
                <div class='py-4' v-if="!serviceAttributesLoading">

                  <transition-group name="list">
                    <div v-for="(value, index) in leadValues" :key="index" class="grid grid-cols-3 gap-4 mb-4 py-4">
                      <div>
                      <label :for="`lead_value_${index}`" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Lead Qualifier</label>
                      <input v-model="value.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" type="text" placeholder="Name">
                      <small class='text-gray-400'>The name of the Lead Qualifier to extract.</small>
                    </div>
                    <div>
                      <label :for="`lead_option_${index}`" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Qualification Type</label>
                      <select v-model="value.dataType" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option disabled value="">Select type</option>
                        <option value="string">String</option>
                        <option value="boolean">Boolean</option>
                      </select>
                      <small class='text-gray-400'>The answer type.</small>
                    </div>
                    <div>
                      <label :for="`lead_description_${index}`" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Qualifier Description</label>
                      <textarea v-model="value.description" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Description"></textarea>
                      <small class='text-gray-400'>A description of the lead qualifier for Linda Bot to extract from the conversation.</small>
                    </div>
                      <button @click.prevent="removeLeadValue(index)" class="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs w-1/3 px-5 py-2.5 text-center mr-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">
                        <svg class="w-4 h-4 my-0 mx-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"/>
                        </svg>
                      </button>
                    </div>

                    <button @click.prevent="addLeadValue" class="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
                      Add Lead Value
                    </button>
                    <div>
                    <label for="name" class="block mb-2 pt-4 text-sm font-medium text-gray-900 dark:text-white">Webhook Url</label>
                    <input v-model="webhookUrl" type="text" name="webhook_url" id="webhook_url" class="mb-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="https://webhook-url.com/3323-121321-123123-12312" required="">
                  </div>
                </transition-group>
                </div>

                <div v-if="serviceAttributesLoading">
                  <div role="status">
                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span class="sr-only">Loading...</span>
                </div>
              </div>

              <div class="sm:col-span-2">
                <button @click.prevent="updateServiceAttributes()" class="py-4 my-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Update Lead Qualification</button>

               </div>

              </div>
            </div>
        </div>
      </transition>

      <transition name="fade">
        <div v-show="activeContent === 'knowledge'">
        <h6 class="text-lg font-bold dark:text-white">Knowledge Base</h6>
        <div id="alert-additional-content-5" class="p-4 my-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-800" role="alert">
            <div class="flex items-center">
              <svg class="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span class="sr-only">Info</span>
              <h3 class="text-lg font-medium text-gray-800 dark:text-gray-300">Knowledge Base Settings</h3>
            </div>
            <div class="mt-2 mb-4 text-sm text-gray-800 dark:text-gray-300">
              This intent is triggered with the same action as your Lead Qualification triggers and your Linda Bot Type settings determine bot behavior.
            </div>
            <div class="flex">

            </div>
          </div>
          <div class="sm:col-span-2" v-if="!knowledgeAttributesLoading">
                  <label for="knowledge_prompt" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Knowledge Seeking Prompt</label>
                  <textarea v-model="knowledgeSeekingPrompt" id="knowledge_prompt" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Knowledge seeking prompt."></textarea>
                  <small class='text-gray-400'>The prompt for your Bot during Knowledge Seeking.</small>
                </div>
               <div class="col-span-2 py-4">


                <div v-if="knowledgeAttributesLoading">
            <div role="status">
              <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
              <span class="sr-only">Loading...</span>
          </div>
        </div>

                <div class="sm:col-span-2">
                <button @click.prevent="updateKnowledgeAttributes()" class="py-4 my-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Update Knowledge Base</button>

               </div>
            </div>
        </div>
      </transition>

      <transition name="fade">
        <div v-show="activeContent === 'handoff'">
          <ul class='list-none'>
            <li class='inline-block'>
              <h6 class="text-lg font-bold dark:text-white">Human Handoff</h6>
            </li>
            <li class='inline-block ml-4 pt-2'>
              <label class="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" v-model="officeChecked" @change="updateIntent('office.initiate')" class="sr-only peer">
                <div class="w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-blue-600"></div>
                <div class="after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:after:translate-x-full peer-checked:after:border-white"></div>
                <span v-if="!officeAttributesLoading && officeChecked" class="ml-2 bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 rounded dark:bg-green-900 dark:text-green-300">Live</span>
                <span v-if="!officeAttributesLoading && !officeChecked" class="ml-2 bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">Off</span>
                <div v-if="officeAttributesLoading" role="status">
                  <svg aria-hidden="true" class="inline w-6 h-6 mr-2 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                  </svg>
                  <span class="sr-only">Loading...</span>
              </div>
              </label>
            </li>
          </ul>
          <div id="alert-additional-content-5" class="p-4 my-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-800" role="alert">
            <div class="flex items-center">
              <svg class="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span class="sr-only">Info</span>
              <h3 class="text-lg font-medium text-gray-800 dark:text-gray-300">Human Handoff Settings</h3>
            </div>
            <div class="mt-2 mb-4 text-sm text-gray-800 dark:text-gray-300">
              Set the time(s) that your team is in the office or available on the platform and the message a user receives if they try to initiate a hand off in off hours.
            </div>
             <div class="flex">

            </div>
          </div>
          <div class="col-span-2 py-4" v-if="officeChecked">
            <div class="col-span-2">
              <div v-if="!officeAttributesLoading">
              <label for="office_hours_start" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Office Hours Start</label>
                <input v-model="officeHoursStart" min="0" max="23" type="number" name="office_hours_start" id="office_hours_start" class=" bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Business Name" required="">
                <small class='text-gray-400 mb-4'>The start time that a conversation can be handed off to a human chat operator.</small>
              </div>
              <div v-if="!officeAttributesLoading" class='mt-4'>
                <label for="office_hours_end" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Office Hours End</label>
                <input v-model="officeHoursEnd" min="0" max="23" type="number" name="office_hours_end" id="office_hours_end" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Business City" required="">
                <small class='text-gray-400'>The end time that a conversation can be handed off to a human chat operator.</small>
              </div>

              <div v-if="!officeAttributesLoading" class='mt-4'>
                <label for="out_of_office_message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Out of Office Message</label>
                <textarea v-model="outOfOfficeMessage" id="out_of_office_message" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="We are out of the office. Please try again at {{office_hours_start}} AM"></textarea>
                <small class='text-gray-400'>The message the user will receive when trying to initiate a chat with a human out of hours.</small>
              </div>

              <div v-if="officeAttributesLoading">
                <div role="status">
                  <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </div>

              <div class="sm:col-span-2 my-2">
                <button @click.prevent="updateOfficeAttributes()" class="py-4 my-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Update Human Handoff</button>

              </div>
            </div>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div v-show="activeContent === 'phone'">


          <ul class='list-none'>
            <li class='inline-block'>
              <h6 class="text-lg font-bold dark:text-white">Phone Calls</h6>
            </li>
            <li class='inline-block ml-4 pt-2' v-if="props.phoneCapable">
              <label class="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" v-model="phoneChecked" @change="updateIntent('call.initiate')" class="sr-only peer">
                <div class="w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-blue-600"></div>
                  <div class="after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:after:translate-x-full peer-checked:after:border-white">
                  </div>
                  <span v-if="!callAttributesLoading && phoneChecked" class="ml-2 bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5  rounded dark:bg-green-900 dark:text-green-300">Live</span>
                  <span v-if="!callAttributesLoading && !phoneChecked" class="ml-2 bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">Off</span>
                  <div v-if="callAttributesLoading" role="status">
                    <svg aria-hidden="true" class="inline w-6 h-6 mr-2 ml-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
              </label>
            </li>
          </ul>
          <div id="alert-additional-content-5" class="p-4 my-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-800" role="alert">
            <div class="flex items-center">
              <svg class="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span class="sr-only">Info</span>
              <h3 class="text-lg font-medium text-gray-800 dark:text-gray-300">Phone Settings</h3>
            </div>
            <div class="mt-2 mb-4 text-sm text-gray-800 dark:text-gray-300">
              Lindabot's Conference Call settings. Schedule when conference calls may be generated or what the user receivees as a message if they try to initiate a call outside of hours.
            </div>
            <div class="flex">

          </div>
        </div>
           <div v-if="!props.phoneCapable" class="col-span-2 py-4">
            <div id="alert-2" class="flex items-center p-4 mb-4 text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
              <svg class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span class="sr-only">Info</span>
              <div class="ml-3 text-sm font-medium">
                To enable phone calls for this Agent <a href="mailto:team@linda.co" class="font-semibold underline hover:no-underline">Contact Us</a>. Thank you.
              </div>
              <button type="button" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700" data-dismiss-target="#alert-2" aria-label="Close">
                <span class="sr-only">Close</span>
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
              </button>
            </div>
           </div>
           <div v-if="props.phoneCapable" class="col-span-2 py-4">
              <div v-if="phoneChecked" class="col-span-2">
                <div v-if="!callAttributesLoading">
                  <label for="office_hours_calls_start" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Office Hours Start</label>
                    <input v-model="officeHoursCallsStart" min="0" max="23" type="number" name="office_hours_calls_start" id="office_hours_calls_start" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required="">
                    <small class='text-gray-400 mb-4'>The start time that a conversation can initiate a conference call.</small>
                  </div>
                  <div v-if="!callAttributesLoading" class="mt-4">
                    <label for="office_hours_calls_end" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Office Hours End</label>
                    <input v-model="officeHoursCallsEnd" min="0" max="23" type="number" name="office_hours_calls_end" id="office_hours_calls_end" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required="">
                    <small class='text-gray-400 mb-4'>The latest time that a conversation can initiate a conference call.</small>
                  </div>

                  <div v-if="!callAttributesLoading" class="mt-4">
                    <label for="out_of_office_calls_message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Out of Office Message</label>
                    <textarea v-model="outOfOfficeCallsMessage" id="out_of_office_calls_message" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="We are out of the office. Please try again at {{office_hours_call_start}} AM"></textarea>
                    <small class='text-gray-400'>The message the user receives when trying to initiate a conference call out of hours.</small>
                  </div>

                  <div class="pt-8" v-if="!callAttributesLoading">
                    <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
                    <input v-model="phoneNumber" type="text" name="phone" id="phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Enter Your Phone Number (+13105551234 e64 format)">
                    <small class='text-gray-400'>The phone number to dial for your conference call.</small>
                  </div>

                <div class='pt-8' v-if="!callAttributesLoading">
                  <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Record Calls</label>
                  <div class="flex items-center pl-4 border border-gray-200  my-2 rounded dark:border-gray-700">
                      <input v-model="callRecording" id="bordered-radio-1" type="radio" value="false" name="record-calls" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                      <label for="bordered-radio-1" class="w-full py-4 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Do Not Record</label>
                  </div>
                  <div class="flex items-center pl-4 border border-gray-200 my-2 rounded dark:border-gray-700">
                      <input v-model="callRecording" id="bordered-radio-2" type="radio" value="true" name="record-calls" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                      <label for="bordered-radio-2" class="w-full py-4 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Record All Calls</label>

                  </div>
                  <small class='text-gray-400'>Set whether or not Linda Bot generated conference calls are recorded.</small>
                </div>

                <div id="call-drawer-right" class="fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto transition-transform translate-x-full bg-white w-80 dark:bg-gray-800">
                    <h5 id="drawer-right-label" class="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400">
                        <svg class="w-4 h-4 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                        </svg>
                        Call Intent Phrases
                    </h5>
                    <div id="alert-additional-content-3" class="p-4 mb-4 text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800" role="alert">


                      <div class="mt-2 mb-4 text-sm">
                        Linda Bot matches these phrases (and others like it) to the call action. When looking through your Linda bot logs, if you see a customer say something that should be matched but isn't, add it here and your Linda Bot will get it right next time.
                      </div>

                    </div>
                    <p class="mb-6 text-sm text-gray-500 dark:text-gray-400"></p>
                    <button type="button" data-drawer-hide="call-drawer-right" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close menu</span>
                    </button>
                    <div class="my-4">
                        <button @click="addCallPhrase" class="w-full py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Add Phrase</button>
                        <table class="w-full">
                            <transition-group name="phrase" tag="tbody">
                                <tr v-for="(phrase, index) in callIntentPhrases" :key="index">
                                  <td class="border p-2 phrase-item">
                                    <div v-if="intentPhrasesLoading">
                                      <div role="status" class="space-y-2.5 p-2 m-1 animate-pulse max-w-lg">
                                        <div class="flex items-center w-full space-x-2">
                                            <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
                                            <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div v-if="!intentPhrasesLoading">
                                      <input v-model="callIntentPhrases[index]" placeholder="Enter phrase" class="p-2 m-1 rounded border border-gray-200">
                                    </div>
                                  </td>
                                    <td class="border text-center p-2">
                                        <a v-if="!intentPhrasesLoading" href="#" @click.prevent="deleteCallPhrase(index)" class="py-1 px-3 text-red-600 hover:bg-gray-50"><svg class="w-4 h-4 my-0 mx-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"/>
                        </svg></a>
                                        <p v-if="intentPhrasesLoading">Loading...</p>
                                    </td>
                                </tr>
                            </transition-group>
                        </table>

                        <button v-if="!intentPhrasesLoading" @click="saveTrainingPhrases('call')" class=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 py-4 my-4 w-full">Update Phrases</button>

                        <button v-if="intentPhrasesLoading" disabled type="button" class="text-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center py-4 my-4 w-full">
                          <svg aria-hidden="true" role="status" class="text-center inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                          </svg>
                          Updating...
                        </button>

                        <button v-if="!botTraining" @click="trainBot()" type="button" class="w-full my-4 text-center mx-auto text-gray-900 bg-gray-200 hover:bg-gray-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        <svg class="w-3.5 h-3.5 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 19">
                              <path d="M7.324 9.917A2.479 2.479 0 0 1 7.99 7.7l.71-.71a2.484 2.484 0 0 1 2.222-.688 4.538 4.538 0 1 0-3.6 3.615h.002ZM7.99 18.3a2.5 2.5 0 0 1-.6-2.564A2.5 2.5 0 0 1 6 13.5v-1c.005-.544.19-1.072.526-1.5H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h7.687l-.697-.7ZM19.5 12h-1.12a4.441 4.441 0 0 0-.579-1.387l.8-.795a.5.5 0 0 0 0-.707l-.707-.707a.5.5 0 0 0-.707 0l-.795.8A4.443 4.443 0 0 0 15 8.62V7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.12c-.492.113-.96.309-1.387.579l-.795-.795a.5.5 0 0 0-.707 0l-.707.707a.5.5 0 0 0 0 .707l.8.8c-.272.424-.47.891-.584 1.382H8.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1.12c.113.492.309.96.579 1.387l-.795.795a.5.5 0 0 0 0 .707l.707.707a.5.5 0 0 0 .707 0l.8-.8c.424.272.892.47 1.382.584v1.12a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1.12c.492-.113.96-.309 1.387-.579l.795.8a.5.5 0 0 0 .707 0l.707-.707a.5.5 0 0 0 0-.707l-.8-.795c.273-.427.47-.898.584-1.392h1.12a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5ZM14 15.5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5Z"/>
                            </svg>
                        Train Bot
                      </button>



                        <button v-if="botTraining" disabled type="button" class="text-center text-white text-gray-900 bg-gray-400 hover:bg-gray-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 py-4 my-4 w-full">
                          <svg aria-hidden="true" role="status" class="text-center inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                          </svg>
                          Training...
                        </button>
                    </div>
                </div>

                <div v-if="callAttributesLoading">
                  <div role="status">
                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>

              <div class="sm:col-span-2">
                <button @click.prevent="updateCallAttributes()" class="py-4 my-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Update Phone Settings</button>

               </div>
              </div>
            </div>
          </div>
      </transition>

      <transition name="fade">
        <div v-show="activeContent === 'hours'">
          <h6 class="text-lg font-bold dark:text-white">Business Hours</h6>
          <div id="alert-additional-content-5" class="p-4 my-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-800" role="alert">
            <div class="flex items-center">
              <svg class="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span class="sr-only">Info</span>
              <h3 class="text-lg font-medium text-gray-800 dark:text-gray-300">Business Hours</h3>
            </div>
            <div class="mt-2 mb-4 text-sm text-gray-800 dark:text-gray-300">
              Set your business' operating hours and holiday schedule here so Lindabot knows how to respond when customers ask.
            </div>
          </div>
          <div class="col-span-2 py-4">
            <div class="col-span-2">
              <div>
                <div class="sm:col-span-2" v-if="!businessHourAttributesLoading">
                  <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Business Hours</label>
                  <textarea v-model="businessScheduleDescription" id="business_hours" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="We are open Monday through Friday 8am to 5pm EST and Sunday from 10am to 4pm EST."></textarea>
                  <small class='text-gray-400'>A written description of your company's operating hours.</small>
                </div>

              <div class="sm:col-span-2 pt-4" v-if="!businessHourAttributesLoading">
                    <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Holiday Hours</label>
                    <textarea v-model="holidayScheduleDescription" id="holiday_hours" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="We are closed on Thanksgiving and Federal Holidays but open on Christmas and New Years."></textarea>
                    <small class='text-gray-400'>A written description of your company's holiday operating hours.</small>
                </div>
              </div>

              <div class="col-span-2" v-if="!businessHourAttributesLoading">
                  <h4 class="py-4 text-lg font-medium text-gray-900 dark:text-white">Days Off Selection</h4>
                  <transition-group name="list">
                    <div>
                      <table class="w-full py-4 divide-y divide-gray-200 dark:divide-gray-700">
                        <thead>
                          <tr>
                            <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider dark:bg-gray-800 dark:text-gray-400">
                              Date
                            </th>
                            <th class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider dark:bg-gray-800 dark:text-gray-400">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-800">
                          <tr v-for="(dayOff, index) in daysOff" :key="index">
                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                              {{ dayOff.date }}
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              <a @click.prevent="removeDayOff(index)" href="#" class="text-red-600 hover:text-indigo-900 dark:text-indigo-400 dark:hover:text-indigo-600">Delete</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div class="mt-4">
                        <div v-for="(dayOff, index) in newDaysOff" :key="'new-' + index" class="my-4">
                          <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date</label>
                          <vue3-datepicker v-model="newDaysOff[index]" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 pb-2"></vue3-datepicker>
                          <a @click.prevent="removeNewDayOff(index)" href="#" class="text-red-600 mt-2 text-small hover:text-indigo-900 dark:text-indigo-400 dark:hover:text-indigo-600">Delete</a>
                        </div>
                        <button @click.prevent="addDayOff" class="mt-2 text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">Add Day Off</button>

                      </div>
                    </div>
                </transition-group>
              </div>

              <div v-if="businessHourAttributesLoading">
                <div role="status">
                  <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </div>


              <div class="sm:col-span-2">
                <button @click.prevent="updateHourAttributes()" class="py-4 my-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Update Hour Settings</button>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div v-show="activeContent === 'promotions'">
          <h6 class="text-lg font-bold dark:text-white">Promotions</h6>
          <div id="alert-additional-content-5" class="p-4 my-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-800" role="alert">
            <div class="flex items-center">
              <svg class="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span class="sr-only">Info</span>
              <h3 class="text-lg font-medium text-gray-800 dark:text-gray-300">Discounts and Promotions</h3>
            </div>
            <div class="mt-2 mb-4 text-sm text-gray-800 dark:text-gray-300">
              Tell your bot about specific promotions and discounts your business is offering.
            </div>
           <!--  <div class="flex">
              <button data-drawer-target="appointment-drawer-right" data-drawer-show="appointment-drawer-right" data-drawer-placement="right" aria-controls="appointment-drawer-right" type="button" class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-gray-600 dark:hover:bg-gray-500 dark:focus:ring-gray-800">
                <svg class="-ml-0.5 mr-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                  <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"/>
                </svg>
                View Intent Phrases
              </button>
            </div> -->
          </div>

          <div class="sm:col-span-2 py-2" v-if="!promoAttributesLoading">
              <label for="current_promotions" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Current Promotions</label>
              <textarea v-model="currentPromotions" id="current_promotions" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="We are currently offering 25% off when you refer a friend and 10% for all returning customers."></textarea>
              <small class='text-gray-400'>What should the bot say when a user is looking for your current discounts/promotions.</small>
          </div>

          <div v-if="promoAttributesLoading">
            <div role="status">
              <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div class="sm:col-span-2">
            <button @click.prevent="updatePromoAttributes()" class="py-4 my-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Update Promo Settings</button>

           </div>

        </div>
      </transition>

      <transition name="fade">
        <div v-show="activeContent === 'services_offered'">
          <h6 class="text-lg font-bold dark:text-white">Services</h6>
          <div id="alert-additional-content-5" class="p-4 my-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-800" role="alert">
            <div class="flex items-center">
              <svg class="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span class="sr-only">Info</span>
              <h3 class="text-lg font-medium text-gray-800 dark:text-gray-300">Services and Products</h3>
            </div>
            <div class="mt-2 mb-4 text-sm text-gray-800 dark:text-gray-300">
              Tell your bot about specific services and products your business is offering.
            </div>
           <!--  <div class="flex">
              <button data-drawer-target="appointment-drawer-right" data-drawer-show="appointment-drawer-right" data-drawer-placement="right" aria-controls="appointment-drawer-right" type="button" class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-gray-600 dark:hover:bg-gray-500 dark:focus:ring-gray-800">
                <svg class="-ml-0.5 mr-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                  <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"/>
                </svg>
                View Intent Phrases
              </button>
            </div> -->
          </div>

          <div class="sm:col-span-2 py-2" v-if="!servicesAttributesLoading">
              <label for="services_offered_description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Products & Services</label>
              <textarea v-model="servicesOfferedDescription" id="services_offered_description" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Add custom context about your products and services."></textarea>
              <small class='text-gray-400'>Add extra context about your products and services.</small>
          </div>

          <div v-if="servicesAttributesLoading">
            <div role="status">
              <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div class="sm:col-span-2">
            <button @click.prevent="updateServicesAttributes()" class="py-4 my-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Update Services Settings</button>

           </div>

        </div>
      </transition>

      <transition name="fade">
        <div v-show="activeContent === 'service_area'">
          <h6 class="text-lg font-bold dark:text-white">Service Area</h6>
          <div id="alert-additional-content-5" class="p-4 my-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-800" role="alert">
            <div class="flex items-center">
              <svg class="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span class="sr-only">Info</span>
              <h3 class="text-lg font-medium text-gray-800 dark:text-gray-300">Service Area</h3>
            </div>
            <div class="mt-2 mb-4 text-sm text-gray-800 dark:text-gray-300">
              Tell your bot about your business' Service Area.
            </div>
           <!--  <div class="flex">
              <button data-drawer-target="appointment-drawer-right" data-drawer-show="appointment-drawer-right" data-drawer-placement="right" aria-controls="appointment-drawer-right" type="button" class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-gray-600 dark:hover:bg-gray-500 dark:focus:ring-gray-800">
                <svg class="-ml-0.5 mr-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                  <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"/>
                </svg>
                View Intent Phrases
              </button>
            </div> -->
          </div>

          <div class="sm:col-span-2 py-2" v-if="!serviceAreaAttributesLoading">
              <label for="service_area_description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Service Area</label>
              <textarea v-model="serviceAreaDescription" id="service_area_description" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Description of your Service Area."></textarea>
              <small class='text-gray-400'>Describe your business' Service Area.</small>
          </div>

          <div v-if="serviceAreaAttributesLoading">
            <div role="status">
              <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div class="sm:col-span-2">
            <button @click.prevent="updateServiceAreaAttributes()" class="py-4 my-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Update Service Area Settings</button>

           </div>

        </div>
      </transition>

      <transition name="fade">
        <div v-show="activeContent === 'pricing'">
          <h6 class="text-lg font-bold dark:text-white">Pricing</h6>
          <div id="alert-additional-content-5" class="p-4 my-4 border border-gray-300 rounded-lg bg-gray-50 dark:border-gray-600 dark:bg-gray-800" role="alert">
            <div class="flex items-center">
              <svg class="flex-shrink-0 w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span class="sr-only">Info</span>
              <h3 class="text-lg font-medium text-gray-800 dark:text-gray-300">Pricing</h3>
            </div>
            <div class="mt-2 mb-4 text-sm text-gray-800 dark:text-gray-300">
              Tell your bot about your business' Pricing.
            </div>
           <!--  <div class="flex">
              <button data-drawer-target="appointment-drawer-right" data-drawer-show="appointment-drawer-right" data-drawer-placement="right" aria-controls="appointment-drawer-right" type="button" class="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-xs px-3 py-1.5 mr-2 text-center inline-flex items-center dark:bg-gray-600 dark:hover:bg-gray-500 dark:focus:ring-gray-800">
                <svg class="-ml-0.5 mr-2 h-3 w-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 14">
                  <path d="M10 0C4.612 0 0 5.336 0 7c0 1.742 3.546 7 10 7 6.454 0 10-5.258 10-7 0-1.664-4.612-7-10-7Zm0 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"/>
                </svg>
                View Intent Phrases
              </button>
            </div> -->
          </div>

          <div class="sm:col-span-2 py-2" v-if="!pricingAttributesLoading">
              <label for="pricing_description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Pricing</label>
              <textarea v-model="pricingDescription" id="pricing_description" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Description of your Pricing."></textarea>
              <small class='text-gray-400'>Describe your business' Pricing.</small>
          </div>

          <div v-if="pricingAttributesLoading">
            <div role="status">
              <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div class="sm:col-span-2">
            <button @click.prevent="updatePricingAttributes()" class="py-4 my-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Update Pricing Settings</button>

           </div>

        </div>
      </transition>


    </div>
  </section>
  <div id="extralarge-modal" tabindex="-1" class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-full max-w-7xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal header -->
            <div class="flex items-center justify-between p-5 border-b rounded-t dark:border-gray-600">
                <h3 class="text-xl font-medium text-gray-900 dark:text-white">
                    Configure Chatbot
                </h3>
                <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="extralarge-modal">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
            </div>
            <!-- Modal body -->
            <div class="p-6 space-y-6">
               <BotEditor
                 :chatbotId="props.chatbotId"
                 :chatbotName="chatbotName"
                 :appointmentConfirmationMessage="appointmentConfirmationMessage"
                 :eventTitle="eventTitle"
                 :eventDescription="eventDescription"
                 :calendarOptions="calendars"
                 :selectedCalendars="selectedCalendars"
                 :officeHoursCallsStart="officeHoursCallsStart"
                 :officeHoursCallsEnd="officeHoursCallsEnd"
                 :outOfOfficeCallsMessage="outOfOfficeCallsMessage"
                 :officeHoursStart="officeHoursStart"
                 :officeHoursEnd="officeHoursEnd"
                 :outOfOfficeMessage="outOfOfficeMessage"
                 :agentNumber="phoneNumber"
                 :callRecording="callRecording"
                 :businessName="businessName"
                 :businessCity="businessCity"
                 :businessState="businessState"
                 :serviceSystemPrompt="serviceSystemPrompt"
                 :leadValues="leadValues"
               ></BotEditor>
            </div>
            <!-- Modal footer -->
            <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button data-modal-hide="extralarge-modal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Close</button>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>

.checked {
  border-color: blue;
  /* add more styles for the highlighted state */
}

.list-item {
  transition: all 0.5s ease;
}


.list-move {
  transition: transform 0.5s;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s cubic-bezier(1, .5, .8, 1);
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.phrase-enter-active, .phrase-leave-active {
  transition: all 1s;
}
.phrase-enter, .phrase-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

</style>
