<script setup>

import { ref, watch, onMounted } from 'vue'
import axios from 'axios'

const states = ref([])
const counties = ref([])
const cities = ref([])
const selectedState = ref('')
const selectedCounty = ref('')

onMounted(() => {
  getStates()
})

const getStates = () => {
  axios.get(`/ajax/geo_locations/states`).then((response) => {
    states.value = response.data['states']
  }).catch((error) => {
    console.log(error)
  })
}

const getCounties = (state) => {
  axios.get(`/ajax/geo_locations/counties?state=${state}`).then((response) => {
    counties.value = response.data['counties']
  }).catch((error) => {
    console.log(error)
  })
}

const getCities = (county) => {
  axios.get(`/ajax/geo_locations/cities?state=${selectedState.value}&county=${county}`).then((response) => {
    cities.value = response.data['cities']
  }).catch((error) => {
    console.log(error)
  })
}

watch(selectedState, (val) => {
  getCounties(val)
})

watch(selectedCounty, (val) => {
  getCities(val)
})

</script>

<template>


    <FormKit
      v-model="selectedState"
      type="select"
      label="State"
      name="state"
      placeholder="Select a state"
      :options="states"
      help="Select the state you want to find county wide cities in."

    />

    <FormKit
      v-model="selectedCounty"
      type="select"
      label="County"
      name="county"
      placeholder="Select a county"
      :options="counties"

    />

    <ul>
      <li v-for="city in cities" :key="city">{{city}}</li>
    </ul>

</template>
