import { Controller } from '@hotwired/stimulus'
import { createApp } from 'vue'
import App from '../vue_widgets/work_order_form.vue'
import { plugin, defaultConfig } from '@formkit/vue'
import { generateClasses } from '@formkit/tailwindcss'
import '@formkit/themes/genesis'

export default class extends Controller {

  static values = { 
    clientName: String, 
    clientId: String, 
    trelloListId: String,
    workOrderId: String,
    orderEmail: String,
    defaultTargetCity: String,
    defaultPrimaryKeyword: String,
    defaultTargetAnchors: Array,
    defaultTargetKeywords: Array,
    defaultMainProductsServices: Array, 
    defaultAreasServed: Array 
  }

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    this.vue = createApp(App, { 
      clientName: this.clientNameValue, 
      clientId: this.clientIdValue, 
      editTrelloListId: this.trelloListIdValue,
      workOrderId: this.workOrderIdValue,
      orderEmail: this.orderEmailValue,
      defaultTargetCity: this.defaultTargetCityValue,
      defaultPrimaryKeyword: this.defaultPrimaryKeywordValue,
      defaultTargetKeywords: this.defaultTargetKeywordsValue,
      defaultMainProductsServices: this.defaultMainProductsServicesValue,
      defaultAreasServed: this.defaultAreasServedValue,
      defaultTargetAnchors: this.defaultTargetAnchorsValue,
    }).use(plugin, defaultConfig({}))
  
    this.vue.mount(this.element)
  }

  disconnect() {
    this.vue?.unmount()
  }
}
