<template>
  <div class="table-responsive-wrapper">
    <div class="table-responsive">
      <input v-model="search" type="text" placeholder="Search..." class="form-control mb-5" style="width: auto">

      <VueSlimTables
        ref="table"
        :source="tableSource"
        :columns="columns"
        class="table"
        :per-page="10">
        <template #cell:id="{ row, value }">
          <a :href="workOrderHref(row, value)">
            view
          </a>
        </template>
      </VueSlimTables>
    </div>
  </div>
</template>

<script>
import qs from 'qs'
import axios from 'axios'
import VueSlimTables from 'vue-slim-tables'
import 'vue-slim-tables/stylesheets';

export default {
  name: 'WorkOrderTable',
  components: { VueSlimTables },
  props: { clientId: String, campaignId: String, month: Number },
  data() {
    return {
      search: ''    }
  },
  watch: {
    search(val) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.$refs.table.refetch, 500)
    }
  },
  methods: {
    reload() {
      this.$refs.table.fetchData(this.$refs.table.page)
    },
    tableSource(params) {
      return axios.get(
        `/ajax/campaigns/${this.campaignId}/work_orders/by_month?month=${this.month}`,
        {
          params: { ...params, query: this.search },
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
        }
      )
        .then((res) => res.data)
    },

    workOrderHref(row, workOrderId) {
      if (row['aasmState'] == 'requires_input') {
        return `/clients/${this.clientId}/work_orders/${workOrderId}/edit`
      } else {
        return `/clients/${this.clientId}/work_orders/${workOrderId}`
      }
    }
  },
  computed: {
  },
  created() {
    this.columns = [
      { title: 'id', key: 'id' },
      { title: 'Type', key: 'workType' },
      { title: 'Name', key: 'trelloName' },
      { title: 'State', key: 'aasmState' },
      { title: 'Created', key: 'createdAt' },
      { title: 'Custom Name', key: 'name' }
    ]
  }
}

</script>
