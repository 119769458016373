<script setup>

import { ref, watch, onMounted, reactive, defineProps, defineEmits } from 'vue'
import axios from 'axios'
import qs from 'qs'
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'

onMounted(() => {

})

const emit = defineEmits()

const props = defineProps({
  chatbotId: String,
  name: String,
  slotProps: Object,
  enabled: Boolean,
  customIntentId: String,
  description: String,
  intentType: String,
  requestType: String,
  url: String,
  requestHeaders: Array,
  requestParameters: Array,
  fulfillmentResponse: String,
  toggleTrigger: Boolean

})

onMounted(() => {
  active.value = props.enabled
})

watch(props, (newProps) => {
  if (newProps.toggleTrigger !== minimized.value) {
    minimized.value = !minimized.value
  }
})

const active = ref(false)
const stateLoading = ref(false)

const toggleState = () => {
  stateLoading.value = true
  active.value = !active.value

  axios.post(`/ajax/chatbots/${props.chatbotId}/custom_intents/${props.customIntentId}/toggle_enabled`, { enabled: active.value })
    .then((response) => {
      stateLoading.value = false
      if (response.data.updated) {
        mdtoast.success('Intent toggled on.', { duration: 10000 })
      } else {
        mdtoast.warning('Intent toggled off.', { duration: 10000 })
      }

    }).catch((error) => {
      console.log(error)
    })
}

const savingStates = reactive({
  name: false,
  description: false,
  requestType: false,
  url: false,
  requestHeaders: false,
  requestParameters: false,
  fulfillmentResponse: false,

})

const editModes = reactive({
  name: false,
  description: false,
  requestType: false,
  url: false,
  requestHeaders: false,
  requestParameters: false,
  fulfillmentResponse: false
})

const state = reactive({
  name: props.name,
  description: props.description,
  requestType: props.requestType,
  url: props.url,
  requestHeaders: props.requestHeaders,
  requestParameters: props.requestParameters,
  fulfillmentResponse: props.fulfillmentResponse,
})

const initialState = reactive({
  name: props.name,
  description: props.description,
  requestType: props.requestType,
  url: props.url,
  requestHeaders: props.requestHeaders,
  requestParameters: props.requestParameters,
  fulfillmentResponse: props.fulfillmentResponse,
})

const toggleEditMode = (field) => {
  editModes[field] = !editModes[field]
  if (!editModes[field]) {
    state[field] = initialState[field]
  }
}

const truncate = (string, maxLength = 50) => {
  if (string.length > maxLength) {
    return string.substring(0, maxLength) + '...';
  }
  return string;
}

const addHeader = () => {
  state.requestHeaders.push({ key: '', value: '' })
}

const addParameter = () => {
  state.requestParameters.push({ key: '', value: '' })
}

const deleteParameter = (index) => {
  state.requestParameters.splice(index, 1)
}

const deleteHeader = (index) => {
  state.requestHeaders.splice(index, 1)
}

const updateField = async (field, value) => {
  savingStates[field] = true

  let payloadValue = value;
  if (field === 'requestHeaders') {
    payloadValue = value.map((header) => ({ key: header.key, value: header.value }))
  }

  if (field === 'requestParameters') {
    payloadValue = value.map((param) => ({ fieldType: param.fieldType, description: param.description, key: param.key }))
  }

  try {
    const response = await axios.post(`/ajax/chatbots/${props.chatbotId}/custom_intents/${props.customIntentId}/update_fields`, {
      field: field,
      value: payloadValue
    })

    if (response.status === 200) {
      initialState[field] = value
      editModes[field] = false
      mdtoast.success(`${field} updated.`, { timeout: 10000 })
    } else {
      console.error("Failed to update field:", response.data)
    }
  } catch (error) {
    console.error("Error updating field:", error)
  } finally {
    savingStates[field] = false
  }
}

const saveChanges = (field) => {
  if (field === 'requestHeaders') {
    updateField(field, state.requestHeaders)
  } else if (field === 'requestParameters') {
    updateField(field, state.requestParameters)
  } else {
    updateField(field, state[field])
  }
}

const destroyIntent = async () => {


  try {
    const response = await axios.delete(`/ajax/chatbots/${props.chatbotId}/custom_intents/${props.customIntentId}`, {
      id: props.customIntentId
    })

    if (response.status === 200) {
      emit('nodeDestroyed', {
        name: props.name,
        intentType: props.intentType,
        customIntentId: props.customIntentId
      })
      mdtoast.success(`Intent destroyed.`, { timeout: 10000 })
    } else {
      console.error("Failed to destroy intent:", response.data)
    }
  } catch (error) {
    console.error("Error updating field:", error)
  }
}

const minimized = ref(false)

const minimize = () => {
  minimized.value = !minimized.value
}

</script>

<template>
  <div class="w-96 max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    <div class="absolute top-2 right-2 group z-10">
      <!-- Trigger -->
      <div class="cursor-pointer w-6 text-right relative">
        ...
        <div class="absolute inset-0 z-0 group-hover:block"></div>
      </div>

      <!-- Content -->
      <div class="absolute right-0 mt-0 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 hidden group-hover:block">
        <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <a href="#" @click.prevent="minimize()" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Toggle</a>
          <a href="#" @click.prevent="destroyIntent()" class="block px-4 py-2 text-sm text-red-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Destroy</a>

        </div>
      </div>
    </div>

    <div class="flex flex-col p-3">
      <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white pt-8 text-center">🦾 {{ props.name }}</h5>
      <span class="text-sm text-gray-500 dark:text-gray-400 text-center">Dynamic</span>
      <div class="text-center">
        <label class="relative inline-flex items-center cursor-pointer mt-4">
          <input @change="toggleState" v-model="props.enabled" type="checkbox" value="" class="sr-only peer">
          <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">⚡️</span>
          <div v-if="stateLoading" class="pl-4" role="status">
            <svg aria-hidden="true" class="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span class="sr-only">Loading...</span>
        </div>
        </label>
      </div>


      <div class="p-3" v-if="!minimized">
        <label for="name" class="pt-4 block mb-2 text-sm font-medium text-gray-400 dark:text-white">Name <a @click="toggleEditMode('name')" class="text-blue-500 cursor-pointer">Edit</a></label>
        <input v-if="editModes.name" v-model="state.name" id="name" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Your appointment is confirmed. You will receive an email shortly."/>
        <p class="py-0 my-0" v-else>{{ state.name }}</p>
        <div v-if="!savingStates.name">
          <button  class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2" v-if="editModes.name" @click="saveChanges('name')">Save</button><br v-if="editModes.name">
        </div>
        <div v-if="savingStates.name">
          <button disabled  v-if="editModes.name" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2">
            <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
            </svg>
            Saving...
          </button>
        </div>

        <small class='text-gray-400 pb-6'>The name of the Custom Intent. This value will be mapped to the intent classifier so use something sensical like 'insurance request', 'Commercial Services Inquiry', etc.</small>
      </div>

      <div class="p-3" v-if="!minimized">
        <label for="description" class="pt-4 block mb-2 text-sm font-medium text-gray-400 dark:text-white">Description <a @click="toggleEditMode('description')" class="text-blue-500 cursor-pointer">Edit</a></label>
        <textarea v-if="editModes.description" v-model="state.description" id="description" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Your appointment is confirmed. You will receive an email shortly."></textarea>
        <p class="py-0 my-0" v-else>{{ state.description }}</p>
        <div v-if="!savingStates.description">
          <button  class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2" v-if="editModes.description" @click="saveChanges('description')">Save</button><br v-if="editModes.description">
        </div>
        <div v-if="savingStates.description">
          <button disabled  v-if="editModes.description" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2">
            <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
            </svg>
            Saving...
          </button>
        </div>

        <small class='text-gray-400 pb-6'>The description of the custom intent. Use phrases like 'User is requesting insurance information' to describe the action that triggers your intent.</small>
      </div>

      <div class="p-3" v-if="intentType === 'api_enriched' && !minimized">
        <label for="description" class="pt-4 block mb-2 text-sm font-medium text-gray-400 dark:text-white">Request Type <a @click="toggleEditMode('requestType')" class="text-blue-500 cursor-pointer">Edit</a></label>
        <label v-if="editModes.requestType">
          Method
          <select v-model="state.requestType" required>
            <option value='' selected>
              Select method
            </option>
            <option value='get'>
              GET
            </option>
            <option value='post'>
              POST
            </option>
            <option value='patch'>
              PATCH
            </option>
            <option value='delete_request'>
              DELETE
            </option>
          </select>
        </label>
        <p v-else>{{ state.requestType.toUpperCase() }}</p>
        <div v-if="!savingStates.requestType">
          <button  class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2" v-if="editModes.requestType" @click="saveChanges('requestType')">Save</button><br v-if="editModes.requestType">
        </div>
        <div v-if="savingStates.requestType">
          <button disabled  v-if="editModes.requestType" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2">
            <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
            </svg>
            Saving...
          </button>
        </div>
        <small class='text-gray-400'>Choose the REST request type.</small>
      </div>

      <div class="p-3" v-if="intentType === 'api_enriched' && !minimized">
        <label for="url" class="pt-4 block mb-2 text-sm font-medium text-gray-400 dark:text-white">URL <a @click="toggleEditMode('url')" class="text-blue-500 cursor-pointer">Edit</a></label>
        <input v-if="editModes.url" v-model="state.url" id="name" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="API endpoint URL."/>
        <p class="py-0 my-0" v-else>{{ truncate(state.url) }}</p>
        <div v-if="!savingStates.url">
          <button  class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2" v-if="editModes.url" @click="saveChanges('url')">Save</button><br v-if="editModes.url">
        </div>
        <div v-if="savingStates.url">
          <button disabled  v-if="editModes.url" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2">
            <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
            </svg>
            Saving...
          </button>
        </div>
        <small class='text-gray-400'>Enter the URL that Linda Bot will enrich her answer with.</small>
      </div>

      <div class="p-3" v-if="intentType === 'api_enriched' && !minimized">
        <label for="request_headers" class="pt-4 block mb-2 text-sm font-medium text-gray-400 dark:text-white">Request Headers <a @click="toggleEditMode('requestHeaders')" class="text-blue-500 cursor-pointer">Edit</a></label>
        <div v-if="editModes.requestHeaders">
          <a href="#" @click.prevent="addHeader">
                  <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 5.757v8.486M5.757 10h8.486M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                  </svg>
                </a>
          <div v-for="(value, index) in state.requestHeaders" :key="index" class="grid grid-cols-2 gap-4 mb-4 py-2">
              <div>
                <label :for="`header_key_${index}`" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Key</label>
                <input v-model="value.key" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" type="text" placeholder="Name">
                <small class='text-gray-400'>Header Key.</small>
              </div>
              <div>
                <label :for="`header_value_${index}`" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Value</label>
                <input v-model="value.value" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pt-2.5 px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" type="text" placeholder="Name">
                <small class='text-gray-400'>Header Value.</small>
              </div>
              <a href="#" @click.prevent="deleteHeader(index)" class="text-red-700 text-xs pt-0 mt-0">Delete</a>
            </div>
        </div>
        <div v-else class="py-0 my-0">
                <dl class="space-y-2">
                    <template v-for="header in state.requestHeaders" :key="header.value">
                        <dt class="text-sm font-medium text-gray-900 dark:text-white">
                            {{ header.key }}
                        </dt>
                        <dd class="ml-2 text-sm text-gray-500 dark:text-gray-400">
                            <strong>Value:</strong> {{ header.value }}<br>
                        </dd>
                    </template>
                </dl>
            </div>
        <div v-if="!savingStates.requestHeaders">
          <button  class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2" v-if="editModes.requestHeaders" @click="saveChanges('requestHeaders')">Save</button><br v-if="editModes.requestHeaders">
        </div>
        <div v-if="savingStates.requestHeaders">
          <button disabled  v-if="editModes.requestHeaders" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2">
            <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
            </svg>
            Saving...
          </button>
        </div>
        <small class='text-gray-400'>Define the Headers for your REST request.</small>
      </div>

      <div class="p-3" v-if="intentType === 'api_enriched' && !minimized">
        <label for="request_parameters" class="pt-4 block mb-2 text-sm font-medium text-gray-400 dark:text-white">Request Parameters <a @click="toggleEditMode('requestParameters')" class="text-blue-500 cursor-pointer">Edit</a></label>
        <div v-if="editModes.requestParameters">
          <a href="#" @click.prevent="addParameter">
                  <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 5.757v8.486M5.757 10h8.486M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                  </svg>
                </a>
            <div v-for="(value, index) in state.requestParameters" :key="index" class="grid grid-cols-1 gap-4 mb-4 py-4">
              <div>
                <label :for="`parameter_key_${index}`" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Key</label>
                <input v-model="value.key" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" type="text" placeholder="Name">
                <small class='text-gray-400'>Parameter Key.</small>
              </div>

              <div>
                <label :for="`parameter_field_type_${index}`" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Field Type</label>
                <input v-model="value.fieldType" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" type="text" placeholder="string or boolean">
                <small class='text-gray-400'>Field Type.</small>
              </div>
              <div>
                <label :for="`parameter_description_${index}`" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Field Type</label>
                <textarea v-model="value.description" id="knowledge_prompt" rows="5" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Description of the parameter in natural language that the bot will extract from the user to use in the API request."></textarea>
                <small class='text-gray-400'>Parameter Value.</small>
              </div>
              <a href="#" @click.prevent="deleteParameter(index)" class="text-red-700 text-xs pt-0 mt-0">Delete</a>
            </div>
        </div>
        <div v-else class="py-0 my-0">
                <dl class="space-y-2">
                    <template v-for="parameter in state.requestParameters" :key="parameter.value">
                        <dt class="text-sm font-medium text-gray-900 dark:text-white">
                            {{ parameter.key }}
                        </dt>
                        <dd class="ml-2 text-sm text-gray-500 dark:text-gray-400">
                            <strong>Field Type:</strong> {{ parameter.fieldType }}<br>
                            <strong>Description:</strong> {{ parameter.description }}<br>
                        </dd>
                    </template>
                </dl>
            </div>
        <div v-if="!savingStates.requestParameters">
          <button  class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2" v-if="editModes.requestParameters" @click="saveChanges('requestParameters')">Save</button><br v-if="editModes.requestParameters">
        </div>
        <div v-if="savingStates.requestParameters">
          <button disabled  v-if="editModes.requestParameters" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2">
            <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
            </svg>
            Saving...
          </button>
        </div>
        <small class='text-gray-400'>Define the Parameters for your REST request.</small>
      </div>

      <div class="p-3" v-if="!minimized">
        <label for="fulfillment_response" class="pt-4 block mb-2 text-sm font-medium text-gray-400 dark:text-white">Fulfillment Response <a @click="toggleEditMode('fulfillmentResponse')" class="text-blue-500 cursor-pointer">Edit</a></label>
        <textarea v-if="editModes.fulfillmentResponse" v-model="state.fulfillmentResponse" id="fulfillment_response" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Use {{response}} to inject the API response and use the prompt to tell Linda's AI how to use that data to respond to a user."></textarea>
        <p class="py-0 my-0" v-else>{{ state.fulfillmentResponse }}</p>
        <div v-if="!savingStates.fulfillmentResponse">
          <button  class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2" v-if="editModes.fulfillmentResponse" @click="saveChanges('fulfillmentResponse')">Save</button><br v-if="editModes.fulfillmentResponse">
        </div>
        <div v-if="savingStates.fulfillmentResponse">
          <button disabled  v-if="editModes.fulfillmentResponse" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2">
            <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
            </svg>
            Saving...
          </button>
        </div>

        <small class='text-gray-400 pb-6'>The fulfillment response to be used to respond to the customer with API enriched data. Use {{response}} to inject the API response and use the prompt to tell Linda's AI how to use that data to respond to a user.</small>
      </div>



    </div>
  </div>
</template>
<style scoped>
.cursor-pointer::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 10px;
  visibility: hidden;
}
.cursor-pointer:hover::after {
  visibility: visible;
}

</style>
