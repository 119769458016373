<template>
  <div class="relative flex justify-center items-center py-8 bg-gray-50">
    <div v-if="loading" role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
        <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
        <span class="sr-only">Loading...</span>
    </div>
    <form class="w-1/2 bg-white p-8 rounded-lg shadow-md" @submit.prevent="submitForm">
      <div id="alert-border-2" class="flex items-center p-4 mb-4 text-yellow-800 border-t-4 border-yellow-300 bg-yellow-50 dark:text-red-400 dark:bg-gray-800 dark:border-yellow-800" role="alert">
      <svg class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <div class="ml-3 text-sm font-medium">
        Work Flows are in beta. Please test!
      </div>

    </div>
      <div class="flex justify-between">
        <div class="mb-4 w-full mr-2">
          <label for="name" class="block text-gray-600 font-bold">Name</label>
          <input required v-model="name" type="text" id="name" class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" placeholder="Enter Name" />
          <small class='text-gray-400'>The name Work Flow for internal usage.</small>
        </div>

      </div>

      <!-- New section -->
      <div class="border border-gray-400 rounded-lg mt-4 p-4">

        <h3 class="text-xl font-bold text-gray-900 pb-4">When this happens</h3>
        <div v-if="eventSelected">
          <p class="text-gray-600">{{ selectedEventText }}</p>
          <a href="#" @click.prevent="toggleEdit" class="text-blue-500">Edit</a>
        </div>
        <div v-else>
          <div>
            <label for="event" class="block text-gray-600 font-bold">Edit: When this happens</label>
            <select v-model="selectedEvent" id="event" class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none">
              <option v-for="event in events" :key="event.id" :value="event.id">{{ event.name }}</option>
            </select>
            <small class='text-gray-400'>Select the event and time parameters that will trigger the actions below.</small>
          </div>
          <div v-if="selectedEvent">
            <div v-if="selectedEvent === 'conversation_stagnated'">
              <label class="py-2 block text-gray-600 font-bold">Time since last user message</label>
              <div class="flex">
                <input v-model="timeSinceLastMessage" type="number" class="w-1/2 px-3 mr-2 py-2 text-gray-700 border rounded-lg focus:outline-none" placeholder="Enter time" />
                <select v-model="timeUnitSinceLastMessage" class="w-1/2 px-3 py-2 ml-2 text-gray-700 border rounded-lg focus:outline-none">
                  <option value="minutes">Minutes</option>
                  <option value="hours">Hours</option>
                  <option value="days">Days</option>
                </select>
              </div>
              <div class="mt-4 flex flex-col">
                <label class="block text-gray-600 font-bold">Retry attempts</label>
                <input v-model="retryAttempts" type="number" class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" placeholder="Enter retry attempts" />
                <small class='text-gray-400 ml-2'>The number of times to attempt to re-engage with a lead before stopping.</small>
              </div>
              <div class="mt-4 flex flex-col">
                <label class="block text-gray-600 font-bold">Last message</label>
                <textarea v-model="lastMessage" class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" placeholder="Enter last message"></textarea>
                <small class='text-gray-400 ml-2'>Included in message to lead.</small>
              </div>
            </div>

            <div v-if="selectedEvent === 'before_calendar_event'">
              <label class="block text-gray-600 font-bold pt-2">Time Before Event</label>
              <div class="flex">
                <input v-model="timeBeforeEvent" type="number" class="w-1/2 mr-2 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" placeholder="Enter time" />
                <select v-model="timeUnitBeforeEvent" class="w-1/2 px-3 py-2 ml-2 text-gray-700 border rounded-lg focus:outline-none">
                  <option value="minutes">Minutes</option>
                  <option value="hours">Hours</option>
                  <option value="days">Days</option>
                </select>
              </div>
            </div>

            <!-- Existing conditions and inputs for other events -->
            <div v-if="selectedEvent && selectedEvent !== 'conversation_stagnated' && selectedEvent !== 'before_calendar_event'">
              <div class='py-4 flex items-center'>
                <input type="checkbox" v-model="sendImmediately" id="immediately" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label for="immediately" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Send immediately</label>
                <small class='text-gray-400 ml-2'>Perform the Action immediately upon the event occuring.</small>
              </div>
              <div v-if="!sendImmediately" class="flex">
                <label class="block text-gray-600 font-bold">Send After</label>
                <input v-model="time" type="number" class="w-1/3 px-3 py-2 mr-2 text-gray-700 border rounded-lg focus:outline-none" />

                <select v-model="timeUnit" class="w-2/5 ml-2 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none">
                  <option value="days">Days</option>
                  <option value="hours">Hours</option>
                  <option value="minutes">Minutes</option>
                </select>
              </div>
            </div>
          </div>
          <button @click.prevent="selectEvent" class="px-4 py-2 mt-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700">Save</button>
        </div>
      </div>

      <div v-if="selectedActions" class="border border-gray-400 rounded-lg mt-4 p-4">
        <h3 class="text-xl font-bold text-gray-900">Do this</h3>
        <div v-for="(action, index) in selectedActions" :key="index">
          <div v-if="action.isEditing">
            <!-- Editing interface -->
            <div class='py-4 my-4'>
              <label for="action" class="block text-gray-600 font-bold">Edit: Do this</label>
              <select v-model="action.selectedAction" id="action" class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none">
                <template v-if="selectedEvent === 'conversation_stagnated'">
                  <option value="re_engages_customer">Re-engages customer</option>
                </template>
                <template v-else>
                  <option v-for="filterAction in filteredActions" :key="filterAction.id" :value="filterAction.id">{{ filterAction.name }}</option>
                </template>
              </select>
            </div>

            <!-- Re-engagement prompt -->
            <div v-if="action.selectedAction === 're_engages_customer'">
              <label class="block text-gray-600 font-bold mt-4">Re-engagement prompt</label>
              <textarea v-model="action.reEngagementPrompt" class="w-full px-3 pt-2 text-gray-700 border rounded-lg focus:outline-none" placeholder="Enter re-engagement prompt"></textarea>
              <small class='text-gray-400 ml-2'>Included in message to customer during re-engagement.</small>
            </div>

            <!-- Text Message Fields -->
            <div v-if="action.selectedAction === 'send_sms'">

              <small class="form-text text-muted">
                Calendar Event Variables
                <ul class="list-disc pb-4 list-none list-inside">

                    <li>event_time</li>
                    <li>confirmation_number</li>
                    <li>html_link</li>
                    <li>attendee_emails</li>

                </ul>

                Bot Lead Variables
                <ul class="list-disc list-none list-inside">

                    <li>company</li>
                    <li>name</li>
                    <li>phone</li>
                    <li>summary</li>
                    <li>email</li>

                </ul>
              </small>
              <label class="block text-gray-600 font-bold mt-4">Text Message Body</label>
              <textarea v-model="action.smsBody" class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" placeholder="Enter text message body"></textarea>
              <small class='text-gray-400 ml-2'>The message of the text.</small>
              <label class="block text-gray-600 font-bold mt-4">Phone Number</label>
              <input v-model="action.phoneNumber" type="text" class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" placeholder="Enter phone number" />
              <small class='text-gray-400 ml-2'>The phone number to send the text to.</small>
            </div>

            <!-- Email Fields -->
            <div v-if="action.selectedAction === 'send_email'">
              <small class="form-text text-muted">
                Calendar Event Variables
                <ul class="list-disc pb-4 list-none list-inside">

                    <li>event_time</li>
                    <li>confirmation_number</li>
                    <li>html_link</li>
                    <li>attendee_emails</li>

                </ul>

                Bot Lead Variables
                <ul class="list-disc list-none list-inside">

                    <li>company</li>
                    <li>name</li>
                    <li>phone</li>
                    <li>summary</li>
                    <li>email</li>

                </ul>
              </small>
              <label class="block text-gray-600 font-bold mt-4">Email Address</label>
              <input v-model="action.emailAddress" type="email" class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" placeholder="Enter email address" />
              <small class='text-gray-400 ml-2'>Email address where you want this notification to send to.</small>
              <label class="block text-gray-600 font-bold mt-4">Subject Line</label>
              <input v-model="action.emailSubject" type="text" class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" placeholder="Enter subject line" />
              <small class='text-gray-400 ml-2'>The email's subject line.</small>
              <label class="block text-gray-600 font-bold mt-4">Email Body</label>
              <textarea v-model="action.emailBody" class="w-full px-3  text-gray-700 border rounded-lg focus:outline-none" placeholder="Enter email body"></textarea>
              <small class='text-gray-400 ml-2'>The content of the email body.</small>
            </div>

            <button @click.prevent="selectAction(index)" class="px-4 py-2 mt-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700">Save action</button>
            <button @click.prevent="removeAction(index)" class="px-4 py-2 mt-4 ml-4 bg-red-600 text-white rounded-lg hover:bg-red-700">Remove action</button>
          </div>
          <div v-else>
            <p class="text-gray-600">{{ action.selectedActionText }}</p>
            <a href="#" @click.prevent="toggleEditAction(index)" class="text-blue-500">Edit</a>
          </div>
        </div>
        <a href="#" @click.prevent="addAction" class="text-blue-500 my-2">+ add action</a>
        <p class="text-gray-600">{{ selectedActions.length }}/5 actions added</p>
      </div>

      <button type="submit" class="px-4 py-2 my-4 bg-blue-600 text-white rounded-lg hover:bg-blue-700">Submit</button>
    </form>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, onMounted, computed } from 'vue';
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'
import axios from 'axios'
import qs from 'qs'

const props = defineProps({
  chatbotId: String,
  currentSelectedEvent: String,
  currentName: String,
  currentSendImmediately: Boolean,
  currentTimeAfterEvent: Number,
  currentTimeAfterEventUnit: String,
  currentTimeBeforeEvent: Number,
  currentTimeBeforeEventUnit: String,
  currentActions: Array,
  currentRetryAttempts: Number,
  currentLastMessage: String,
  currentTimeSinceLastMessage: Number,
  currentTimeUnitSinceLastMessage: String,
})

const selectedActions = ref([])

onMounted(() => {
  selectedEvent.value = props.currentSelectedEvent
  name.value = props.currentName
  sendImmediately.value = props.currentSendImmediately
  time.value = props.currentTimeAfterEvent
  timeUnit.value = props.currentTimeAfterEventUnit
  selectedActions.value = props.currentActions
  timeSinceLastMessage.value = props.currentTimeSinceLastMessage
  timeUnitSinceLastMessage.value = props.currentTimeUnitSinceLastMessage
  lastMessage.value = props.currentLastMessage
  retryAttempts.value = props.currentRetryAttempts
  console.log(selectedActions.value)
})

const events = [
  { id: 'calendar_event_created', name: 'Calendar Event Created' },
  { id: 'calendar_event_updated', name: 'Calendar Event Updated' },
  { id: 'calendar_event_deleted', name: 'Calendar Event Deleted' },
  { id: 'bot_lead_created', name: 'Bot Lead Created' },
  { id: 'before_calendar_event', name: 'Before Calendar Event' },
  { id: 'conversation_stagnated', name: 'Conversation Stagnated' }
]

const selectedEvent = ref(null);
const eventSelected = ref(false);
const sendImmediately = ref(false);
const time = ref(0)
const timeUnit = ref('hours');
const timeBeforeEvent = ref(0);
const timeUnitBeforeEvent = ref('minutes');
const selectedEventText = ref('');
const retryAttempts = ref('')
const lastMessage = ref('')
const timeUnitSinceLastMessage = ref('minutes')
const timeSinceLastMessage = ref('')

const loading = ref(false)

const actions = [
  { id: 'send_email', name: 'Send Email' },
  { id: 'send_sms', name: 'Send SMS' },
  { id: 're_engages_customer', name: 'Customer Re Engagement' }
]

const filteredActions = computed(() => {
  return actions.filter(action => action.id !== 're_engages_customer')
});
const toggleEdit = () => {
  eventSelected.value = !eventSelected.value
}

const actionSelected = ref(false)
const selectedActionText = ref('')
const actionsCount = ref(0);
const name = ref('')

const addAction = () => {
  if (selectedActions.value.length < 5) {
    selectedActions.value.push({
      selectedAction: null,
      reEngagementPrompt: '',
      smsBody: '',
      phoneNumber: '',
      emailAddress: '',
      emailSubject: '',
      emailBody: '',
      isEditing: true,
      selectedActionText: ''
    });
  } else {
    mdtoast.info('You can only add 5 actions.', { duration: 10000 })
  }
}

const toggleEditAction = (index) => {
  selectedActions.value[index].isEditing = !selectedActions.value[index].isEditing
}

const selectAction = (index) => {
  const selectedActionDetails = { ...selectedActions.value[index] };
  const action = actions.find(a => a.id === selectedActionDetails.selectedAction)

  if (action) {
    selectedActionDetails.selectedActionText = `Do this: ${action.name.toLowerCase()}.`
    selectedActionDetails.isEditing = false;

    selectedActions.value[index] = selectedActionDetails;

    actionsCount.value += 1;
  }
}

const removeAction = (index) => {
  selectedActions.value.splice(index, 1)
}

const selectEvent = () => {
  const event = events.find(e => e.id === selectedEvent.value)

  if (selectedEvent.value === 'conversation_stagnated') {
    selectedEventText.value = `${timeSinceLastMessage.value} ${timeUnitSinceLastMessage.value} since last user message when ${event.name.toLowerCase()}.`
  } else if (selectedEvent.value === 'before_calendar_event') {
    selectedEventText.value = `${timeBeforeEvent.value} ${timeUnitBeforeEvent.value} before calendar event when ${event.name.toLowerCase()}.`
  } else if (sendImmediately.value) {
    selectedEventText.value = `Immediately when ${event.name.toLowerCase()}.`
  } else {
    selectedEventText.value = `${time.value} ${timeUnit.value} after ${event.name.toLowerCase()}.`
  }

  eventSelected.value = true;
}

const submitForm = async () => {
  loading.value = true
 try {
    const payload = {
      selectedEvent: selectedEvent.value,
      sendImmediately: sendImmediately.value,
      time: time.value,
      timeUnit: timeUnit.value,
      timeBeforeEvent: timeBeforeEvent.value,
      timeUnitBeforeEvent: timeUnitBeforeEvent.value,
      retryAttempts: retryAttempts.value,
      lastMessage: lastMessage.value,
      timeUnitSinceLastMessage: timeUnitSinceLastMessage.value,
      timeSinceLastMessage: timeSinceLastMessage.value,
      name: name.value,
      actions: selectedActions.value.map(action => ({
        selectedAction: action.selectedAction,
        reEngagementPrompt: action.reEngagementPrompt,
        smsBody: action.smsBody,
        phoneNumber: action.phoneNumber,
        emailAddress: action.emailAddress,
        emailSubject: action.emailSubject,
        emailBody: action.emailBody
      }))
    }

    const response = await axios.post(`/ajax/chatbots/${props.chatbotId}/work_flows`, payload)
    loading.value = false
    window.location = `/clients/dashboard/chats/chatbots/${props.chatbotId}/work_flows`
  } catch (error) {
    console.error('There was an error submitting the form:', error);
    mdtoast.error('Failed to submit the form. Please try again.', { duration: 5000 })
  }
}

// watch(selectedEvent, (newVal) => {
//   selectedActions.value = []
// })
</script>

<style scoped>
</style>