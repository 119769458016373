import { Controller } from '@hotwired/stimulus'
import { createApp } from 'vue'
import App from '../vue_widgets/api_tokens.vue'
import axios from 'axios'

export default class extends Controller {
  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    document.addEventListener('DOMContentLoaded', () => {
      axios.post(this.element.dataset.apiTokensPath, {
          apiToken: { name: new Date() }
      }).then((response) => {
        console.log(response)
      }).catch((error) => {
        console.log(error)
      })
    }, false)

    this.vue = createApp(App)
    this.vue.mount(this.element)
  }

  disconnect() {
    this.vue?.unmount()
  }
}
