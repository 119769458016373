<script setup>

import { ref, watch, onMounted } from 'vue'
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

const selectedLeadDialer = ref([])
const leadDialerOptions = ref([])

const props = defineProps({
  leadDialerId: String,
  organizationId: String,
  inboxId: String,
  leadDialerFriendlyName: String
})

onMounted(() => {
  getLeadDialers()

  if (props.leadDialerId) {
    selectedLeadDialer.value = { value: props.leadDialerId }
  }
})

const getLeadDialers = () => {
  axios.get(`/ajax/lead_dialers`).then((response) => {
    leadDialerOptions.value = response.data['results']
  }).catch((error) => {
    console.log(error)
  })
}


const updateInbox = () => {
  axios.patch(`/ajax/lead_dialers/${selectedLeadDialer.value['value']}`, {
    inbox_id: props.inboxId
  }).then((response) => {
    if (response.data.success) {
      window.location.reload()
    } else {
      console.log('error on updating')
    }
  }).catch((error) => {
    console.log(error)
  })
}
</script>

<template>

  <h5 v-if="props.leadDialerId">Lead Dialer Selected: {{ props.leadDialerFriendlyName }} {{ props.leadDialerId }}</h5>

  <h4 class="pt-5">
      Lead Dialer for this Inbox
    </h4>
    <label class="typo__label">Select a Lead Dialer.</label>

    <multiselect

    v-model="selectedLeadDialer"
    :options="leadDialerOptions"
    :multiple="false"
    :close-on-select="true"
    placeholder="select Lead Dialer"
    label="name"
    track-by="name"
    />

    <button @click="updateInbox" class="mt-3 btn btn-primary">
      Update Inbox
    </button>
</template>
