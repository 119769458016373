<template>
  <transition name="app-slide-panel">
    <div v-if="active" class="app-slide-panel-backdrop" @click.self="close">
      <div tabindex="-1" :class="['app-slide-panel dark:bg-gray-800', panelPosition]">
        <Suspense>
          <component
            :is="viewComponent"
            v-if="viewComponent"
            v-bind="viewProps"
            v-on="viewEvents"
            @on-close-panel="close" />
        </Suspense>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, markRaw } from 'vue'

const active = ref(false)
const panelPosition = ref('right')
const viewComponent = ref(null)
const viewProps = ref(null)
const viewEvents = ref(null)

const onEsc = (event) => {
  if (event.key === 'Escape') {
    close() // eslint-disable-line no-use-before-define
  }
}

const open = ({
  component, props = {}, events = {}, position = 'right'
}) => {
  active.value = true
  viewComponent.value = markRaw(component)
  viewProps.value = props
  viewEvents.value = events
  panelPosition.value = position

  window.addEventListener('keydown', onEsc)
  document.body.classList.add('overflow-hidden')
}

const close = () => {
  active.value = false

  viewComponent.value = null
  viewProps.value = null
  viewEvents.value = null

  window.removeEventListener('keydown', onEsc)
  document.body.classList.remove('overflow-hidden')
}

defineExpose({ open, close })
</script>
