<script setup>

import { ref, watch, onMounted, defineProps } from 'vue'
import axios from 'axios'
import VueSlimTables from 'vue-slim-tables'
import 'vue-slim-tables/stylesheets';
import qs from 'qs'

const props = defineProps({
  locationId: String
})

onMounted(() => {
  getListingInfo()
})

// const locationInfo = ref({})
const infoLoading = ref(true)
const listingInfo = ref({})
const search = ref('')

const getListingInfo = () => {
  axios.get(`/ajax/locations/${props.locationId}/yext_listings/stats_by_location`).then((response) => {
    listingInfo.value = response.data
    infoLoading.value = false
  }).catch((error) => {
    console.log(error)
  })
}

const columns = [
  { title: 'id', key: 'id' },
  { title: 'Publisher ID', key: 'publisherId' },
  { title: 'status', key: 'status' },
  //{ title: 'Yext ID', key: 'yextId' },
  //{ title: 'Created', key: 'createdAt' },
]
const tableSource = (params) => {
  return axios.get(
    `/ajax/locations/${props.locationId}/yext_listings`,
    {
      params: { ...params, query: search.value },
      paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
    }
  ).then((res) => res.data)
}
</script>

<template>
  <div class="grid grid-cols-5 gap-1 py-6">
    <div class="p-8">
      <h2 class='text-center'>Listings</h2>
      <div v-if="infoLoading">
        <div role="status" class="space-y-2.5 py-4 animate-pulse max-w-lg">
          <div class="flex items-center w-full space-x-2">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[400px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[440px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[360px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div v-else>
        <h1 class="pt-8 order-first text-5xl font-semibold tracking-tight text-gray-900 display-5 text-center">{{ listingInfo['listings'] }}</h1>
      </div>

    </div>

    
    <div class="p-8">
      <h2 class='text-center'>Live</h2>
      <div v-if="infoLoading">
        <div role="status" class="space-y-2.5 py-4 animate-pulse max-w-lg">
          <div class="flex items-center w-full space-x-2">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[400px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[440px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[360px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <span class="sr-only">Loading...</span>
      </div>
      </div>
      <div v-else>
         <h1 class="pt-8 order-first text-5xl font-semibold tracking-tight text-gray-900 display-5 text-center">{{ listingInfo['live'] }}</h1>
      </div>
    </div>

    <div class="p-8">
      <h2 class='text-center'>Pending (Yext)</h2>
      <div v-if="infoLoading">
        <div role="status" class="space-y-2.5 py-4 animate-pulse max-w-lg">
          <div class="flex items-center w-full space-x-2">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[400px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[440px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[360px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div v-else>
        <h1 class="pt-8 order-first text-5xl font-semibold tracking-tight text-gray-900 display-5 text-center">{{ listingInfo['waitingOnYext'] }}</h1>
      </div>

    </div>

    <div class="p-8">
      <h2 class='text-center'>Pending (Pub)</h2>
      <div v-if="infoLoading">
        <div role="status" class="space-y-2.5 py-4 animate-pulse max-w-lg">
          <div class="flex items-center w-full space-x-2">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[400px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[440px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[360px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div v-else>
        <h1 class="pt-8 order-first text-5xl font-semibold tracking-tight text-gray-900 display-5 text-center">{{ listingInfo['waitingOnPublisher'] }}</h1>
      </div>

    </div>
    <div class="p-8">
      <h2 class='text-center'>Pending (You)</h2>
      <div v-if="infoLoading">
        <div role="status" class="space-y-2.5 py-4 animate-pulse max-w-lg">
          <div class="flex items-center w-full space-x-2">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[400px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[440px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[360px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div v-else>
        <h1 class="pt-8 order-first text-5xl font-semibold tracking-tight text-gray-900 display-5 text-center">{{ listingInfo['waitingOnCustomer'] }}</h1>
      </div>

    </div>
  </div>
  <div class="grid grid-cols-1 gap-1">
    <h2 class='px-6'>Listings</h2>
    <VueSlimTables
        ref="table"
        :source="tableSource"
        :columns="columns"
        class="table px-8 py-2"
        :per-page="10">

        <template #cell:id="{ row, value }">
          <a v-if="row['url']" :href="`${row['url']}`" target="_blank"><img :src="`/logos/${row['publisherId']}.svg`"></a>
          <div v-else ><img :src="`/logos/${row['publisherId']}.svg`"></div>
        </template>

        <template #cell:status="{ row, value }">
          <div v-if="value == 'WAITING_ON_PUBLISHER'">
            <span class="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300">{{ value }}</span>

          </div>

          <div v-if="value == 'WAITING_ON_YEXT'">
            <span class="bg-orange-100 text-orange-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-orange-300 border border-orange-300">Waiting On You</span>

          </div>

          <div v-if="value == 'WAITING_ON_CUSTOMER'">
            <span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-300 border border-red-300">{{ value }}</span>

          </div>

          <div v-if="value == 'LIVE'">
            <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-300 border border-green-300">{{ value }}</span>

          </div>

          <div v-if="value == 'UNAVAILABLE' || value == 'OPTED_OUT'">
            <span class="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300 border border-green-300">{{ value }}</span>

          </div>
        </template>

       <!--  <template #cell:leadType="{ row, value }">
          <div v-if="value == 'form_lead'">
            <span class="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">Form</span>

          </div>
          <div v-else>
            <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Call</span>

          </div>
        </template>

        <template #cell:id="{ row, value }">
          <a :href="`/clients/dashboard/leads/${value}`" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">View</a>

        </template> -->

      </VueSlimTables>
  </div>
</template>
