<script> 
import { token } from '@formkit/utils'
import axios from 'axios'

export default {
  mounted() {
    this.getLocations()
    this.getWebsites()   
  },
  data() {
    return {
      uaMetrics: true,
      daterange: null,
      reportTitle: null,
      executiveSummary: null,
      analyticsOptions: null,
      websiteVersions: null,
      locationOptions: null,
      analyticsSummary: null,
      analyticsEnabled: false,
      searchConsoleEnabled: false,
      searchConsoleSummary: null,
      remoteLocations: [],
      remoteWebsites: [],
      website: null,
      geogridsByLocations: [],
      locations: [],
      locationDetails: [],
      searchConsoleOptions: null,
      analyticExtras: [],
      locationExtras: {},
      searchConsoleExtras: [],
      geogridConfig: {},
      formData: {},
      loading: false
    }
  },
  methods: {
    submitHandler(evt) {
      this.loading = true 
      const data = new FormData(document.querySelector('#input_0'))
      data.append("formData", JSON.stringify(this.formData))

      axios.post(`/ajax/clients/${this.clientId}/reports`, data, { headers: { "Content-Type": "multipart/form-data" } })
        .then((response) => {
          this.loading = false
          window.location = `/clients/${this.clientId}/reports/${response.data['reportId']}`
        }).catch((error) => {
          console.log(error)
        })
    },
    onAddSectionClicked(type) {
      if (type === "searchConsole") {
        this.searchConsoleExtras.push({
          key: token(),
          title: '',
          file: '',
          summary: ''
        })
      } else if (type === "analytics") {
        this.analyticExtras.push({
          key: token(),
          title: '',
          file: '',
          summary: ''
        })  
      }
    },

    onAddLocationSectionClicked(id) {
      if (this.locationExtras.hasOwnProperty(id)) {
        this.locationExtras[id].push({
          key: token(),
          title: '',
          file: '',
          summary: ''
        })
      } else {
        this.locationExtras[id] = [{ 
          key: token(),
          title: '',
          file: '',
          summary: '' 
        }]
      }
    },

    onRemoveSectionClicked(type, section) {
      if (type === "analytics") {
        this.analyticExtras = this.analyticExtras.filter((s) => s !== section)
      } else if (type === "searchConsole"){
        this.searchConsoleExtras = this.searchConsoleExtras.filter((s) => s !== section)
      }
    },

    onLocationRemoveSectionClicked(locationId, section) {
      this.locationExtras[locationId] = this.locationExtras[locationId].filter((s) => s !== section)
    },

    getLocations() {
       axios.get(`/ajax/clients/${this.clientId}/locations`).then((response) => {
        this.remoteLocations = response.data['results']
      }).catch((error) => {
        console.log(error)
      })
    },

    getWebsites() {
       axios.get(`/ajax/clients/${this.clientId}/websites`).then((response) => {
        this.remoteWebsites = response.data['results']
        this.websiteVersions = response.data['versions']
      }).catch((error) => {
        console.log(error)
      })
    },

    getGeogrids(locationId) {
      axios.get(`/ajax/clients/${this.clientId}/locations/${locationId}/geogrids`).then((response) => {
        this.geogridsByLocations.push(JSON.parse(response.data.result))
      }).catch((error) => {
        console.log(error)
      })
    },

    locationTitle(id) {
      return this.remoteLocations.find(element => element['value'] === parseInt(id) )['label']
    },

    checkAllAnalyticsMetrics() {
      if (this.uaMetrics) {
        this.analyticsOptions = this.analyticsMetrics().map(el => el['value'])
      } else {
        this.analyticsOptions = this.analyticsv4Metrics().map(el => el['value'])
      }
      
    },

    checkAllLocationMetrics() {
      this.locationOptions = this.locationMetrics().map(el => el['value'])
    },


    analyticsMetrics() {
      return [
        { value: 'pageviews', label: 'Pageviews' },
        { value: 'unique-pageviews', label: 'Unique Pageviews' },
        { value: 'pageviews-per-session', label: 'Pageviews per session' },
        { value: 'sessions', label: 'Sessions' },
        { value: 'users', label: 'Users' },
        { value: 'average-session-duration', label: 'Average session duration' },
        { value: 'bounce-rate', label: 'Bounce Rate' },
        { value: 'new-users', label: 'New Users' },
        { value: 'sessions-per-user', label: 'Sessions per user' }
      ]
    },

    analyticsv4Metrics() {
      return [
        { value: 'active-users', label: 'Active Users'},
        { value: 'screen-page-views', label: 'Screen Page Views' },
        { value: 'total-users', label: 'Total Users' },
        { value: 'screen-page-views-per-session', label: 'Screen Page Views per Session' },
        { value: 'sessions', label: 'Sessions' },
        { value: 'new-users', label: 'New Users' },
        { value: 'average-session-duration', label: 'Average Session Duration' },
        { value: 'bounce-rate', label: 'Bounce Rate' },
        { value: 'sessions-per-user', label: 'Sessions per User' },
        { value: 'engaged-sessions', label: 'Enaged Sessions'},
        { value: 'engagement-rate', label: 'Engagement Rate' },
        { value: 'session-conversion-rate', label: 'Session Conversion Rate' },
        { value: 'conversions', label: 'Conversions' },
        { value: 'event-count', label: 'Event Count' }
      ]
    },

    locationMetrics() {
      return [
        { label: 'Phone Calls', value: 'actions-phone' },
        { label: 'Website Clicks', value: 'actions-websites' },
        { label: 'Driving Directions', value: 'actions-driving-directions' },
        { label: 'Listings On Maps', value: 'views-maps' },
        { label: 'Listings on Search', value: 'views-search' },
        { label: 'Interactions', value: 'interactions' },
        { label: 'Local Post CTA Clicks', value: 'local-post-actions-call-to-action' },
        { label: 'Local Post Views On Search', value: 'local-post-views-search' },
        { label: 'Customer Photo Uploads', value: 'photos-count-customers' },
        { label: 'Business Photo Uploads', value: 'photos-count-merchant' },
        { label: 'Business Photo Views', value: 'photos-views-merchant' },
        { label: 'Customer Photo Views', value: 'photos-views-customers' }, 
        { label: 'Direct Queries', value: 'queries-direct' },
        { label: 'Indirect Queries', value: 'queries-indirect' },
        { label: 'Chain Queries', value: 'queries-chain' }
      ]
    },

    searchConsoleMetrics() {
      return [
        { label: 'Clicks', value: 'clicks' },
        { label: 'Impressions', value: 'impressions' }
      ]
    }
  },
  watch: {
    locations(selected) {
      this.locationDetails = []
      this.geogridsByLocations = []
      selected.forEach(locationId => {
        this.locationDetails.push({key: token(), id: locationId})
        this.getGeogrids(locationId)
      })
    }, 
    website(websiteId) {
      this.formData["google_analytics_metrics"] = []
      if(this.websiteVersions && this.websiteVersions["universal"] && this.websiteVersions["universal"].includes(parseInt(websiteId))) {
        this.uaMetrics = true 
      } else {
        this.uaMetrics = false 
      }

      this.analyticsEnabled = this.remoteWebsites.filter(w => w.value == websiteId)[0].analyticsEnabled 
      this.searchConsoleEnabled = this.remoteWebsites.filter(w => w.value == websiteId)[0].searchConsoleEnabled 
    }
  },
  computed: {
    clientId() {
      return window.location.href.split("/")[4]
    },
    console: () => console
  }
}
</script>

<template>
  <div v-if="!this.loading" class='wrapper w-full'>
    <h1 class="my-5">Create Report</h1>
    <FormKit
      type="form"
      v-model="formData"
      :classes="{
        outer: '$reset w-full'
      }"
      submit-label="Create Report"
      :form-class="submitted ? 'hide' : 'show'"
      @submit="submitHandler">
      <p class='py-2 my-2'>
        Select all inputs to generate a report.
      </p>
      <hr class='py-2' />
      <FormKit
        type="text"
        name="title"
        v-model="reportTitle"
        label="Report Title"
        placeholder="April 2022 Report"
        help="Title for this report"
        validation="required"
        outer-class="mb-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"
      />

      <FormKit 
        type="daterange"
        v-model="daterange"
        name="daterange"
        outer-class="mb-5"
      />

      <FormKit
        type="quill"
        name="executive_summary"
        title="Executive Summary"
        label="Executive Summary"
        v-model="executiveSummary"
        rows="10"
        placeholder="The executive summary for the report."
        help="Fill out the requisite info."
        outer-class="mb-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="w-full h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"
      />

      <FormKit
        v-model="website"
        type="select"
        label="Report Website"
        name="website"
        :options="remoteWebsites"
        outer-class="mb-5 w-2/3"
        placeholder="Select website"
      />

      <div v-if="this.website">
        <div v-if="this.uaMetrics">
          <a class='btn btn-primary-outline' v-if="analyticsEnabled" @click="checkAllAnalyticsMetrics">Select All</a>
          <FormKit
            v-model="analyticsOptions"
            type="checkbox"
            name="google_analytics_metrics"
            label="Google Analytics (UA) Metrics"
            v-if="analyticsEnabled"
            on-value="true"
            :options="analyticsMetrics()"
            help="Select up to 10 google analytics metrics to include."
            validation="required"
            outer-class="mb-5"
            label-class="block mb-1 font-bold text-sm"
            inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
            input-class="w-full h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400 analytics-select"
            help-class="text-xs text-gray-500"

          />
        </div>

        <div v-else>
          <a class='btn btn-primary-outline' v-if="analyticsEnabled" @click="checkAllAnalyticsMetrics">Select All</a>
          <FormKit
            v-model="analyticsOptions"
            type="checkbox"
            name="google_analytics_metrics"
            label="Google Analytics (GA4) Metrics"
            v-if="analyticsEnabled"
            on-value="true"
            :options="analyticsv4Metrics()"
            help="Select up to 10 google analytics metrics to include."
            validation="required"
            outer-class="mb-5"
            label-class="block mb-1 font-bold text-sm"
            inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
            input-class="w-full h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400 analytics-select"
            help-class="text-xs text-gray-500"

          />
        </div>
      </div>
      
      <FormKit
        type="quill"
        label="Analytics Summary"
        v-model="analyticsSummary"
        name="analytics_summary"
        title="Analytics Summary"
        rows="10"
        placeholder="The analytics summary for the report."
        help="Fill out the requisite info."
        outer-class="mb-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class=" border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="w-full h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"
      />

      <div class="formkit-outer my-5">
        <label class="block mb-1 font-bold text-sm">Analytic extras</label>

        <FormKit v-for="(section, index) in analyticExtras" :key="section.key" type="group" name="analytics_extras">
          <button @click.prevent="onRemoveSectionClicked('analytics', section)" type="button">Remove section</button>
          <FormKit
            v-model="section.title"
            :id="`section-title-${section.key}`"
            :key="`section-title-${section.key}`"
            type="text"
            :name="`analytic_extras[${index}][title]`"
            placeholder="Enter title"
            outer-class="mb-5 w-2/3"
            label-class="block mb-1 font-bold text-sm"
            inner-class=" border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
            input-class="w-full h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
            help-class="text-xs text-gray-500"
          />

          <FormKit
            v-model="section.file"
            :id="`section-file-${section.key}`"
            :key="`section-file-${section.key}`"
            type="file"
            :name="`analytic_extras[${index}][file]`"
            outer-class="mb-5 w-2/3"
            label-class="block mb-1 font-bold text-sm"
            inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
            input-class="w-full h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
            help-class="text-xs text-gray-500"
          />

          <FormKit
            v-model="section.summary"
            :id="`section-summary-${section.key}`"
            :key="`section-summary-${section.key}`"
            type="textarea"
            :name="`analytic_extras[${index}][summary]`"
            rows="10"
            placeholder="Enter summary"
            outer-class="mb-5 w-2/3"
            label-class="block mb-1 font-bold text-sm"
            inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
            input-class="w-full h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
            help-class="text-xs text-gray-500"
          />
        </FormKit>
        <button @click.prevent="onAddSectionClicked('analytics')" class="btn btn-white my-5 py-5" type="button">Add new section</button>
        
      </div>

      <FormKit
        v-model="searchConsoleOptions"
        type="checkbox"
        label="Search Console Metrics"
        v-if="searchConsoleEnabled"
        name="search_console_metrics"
        :options="searchConsoleMetrics()"
        help="Select which google search console fields to include."
        validation="required"
        outer-class="mb-5"
        label-class="block mb-1 font-bold text-sm"
        inner-class=" border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="w-full h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"

      />

      <FormKit
        type="quill"
        label="Search Console Summary"
        title="Search Console Summary"
        v-model="searchConsoleSummary"
        name="search_console_summary"
        rows="10"
        placeholder="The search console summary for the report."
        help="Fill out the requisite info."
        outer-class="mb-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class=" border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="w-full h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"
      />

      <div class="formkit-outer my-5">
        <label class="block mb-1 font-bold text-sm">Search Console extras</label>

        <FormKit v-for="(section, index) in searchConsoleExtras" :key="section.key" type="group" name="search_console_extras">
          <button @click.prevent="onRemoveSectionClicked('searchConsole', section)" type="button">Remove section</button>
          <FormKit
            v-model="section.title"
            :id="`section-title-${section.key}`"
            :key="`section-title-${section.key}`"
            type="text"
            :name="`search_console_extras[${index}][title]`"
            placeholder="Enter title"
            outer-class="mb-5 w-2/3"
            label-class="block mb-1 font-bold text-sm"
            inner-class=" border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
            input-class="w-full h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
            help-class="text-xs text-gray-500"
          />

          <FormKit
            v-model="section.file"
            :id="`section-file-${section.key}`"
            :key="`section-file-${section.key}`"
            type="file"
            :name="`search_console_extras[${index}][file]`"
            outer-class="mb-5 w-2/3"
            label-class="block mb-1 font-bold text-sm"
            inner-class=" border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
            input-class="w-full h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
            help-class="text-xs text-gray-500"
          />

          <FormKit
            v-model="section.summary"
            :id="`section-summary-${section.key}`"
            :key="`section-summary-${section.key}`"
            type="textarea"
            :name="`search_console_extras[${index}][summary]`"
            rows="10"
            placeholder="Enter summary"
            outer-class="mb-5 w-2/3"
            label-class="block mb-1 font-bold text-sm"
            inner-class=" border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
            input-class="w-full h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
            help-class="text-xs text-gray-500"
          />
        </FormKit>
        <button @click.prevent="onAddSectionClicked('searchConsole')" class="btn btn-white" type="button">Add new section</button>
        
      </div>

      <FormKit
        v-model="locations"
        type="select"
        multiple
        label="Select locations for the report"
        name="locations"
        :options="remoteLocations"
        help="Select all locations you want to include in the generated report."
        outer-class="my-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class=" border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="px-3 border-none text-base text-gray-700 placeholder-gray-400"
      />

      <a class='btn btn-primary-outline' @click="checkAllLocationMetrics">Select All</a>
      <FormKit
        v-model="locationOptions"
        type="checkbox"
        name="location_metrics"
        label="Google Business Profile Metrics"
        :options="locationMetrics()"
        help="Select which google business profile fields to include."
        validation="required"
        outer-class="mb-5"
        label-class="block mb-1 font-bold text-sm"
        inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="w-full h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"

      />

      <div class="formkit-outer my-5">

        <FormKit 
          v-for="locationDetail in locationDetails" 
          :key="locationDetail.key" 
          type="group" 
          :name="`location_details[${locationDetail['id']}]`">

          <p class='text-lg font-bold text-uppercase'>{{ locationTitle(locationDetail['id']) }}</p>
          <FormKit
            type="quill"
            :name="`location_summary[${locationDetail['id']}]`"
            label="Location Summary"
            title="Location Summary"
            rows="10"
            placeholder="The location summary for the report."
            help="Fill out the requisite info."
            outer-class="mb-5 w-2/3"
            label-class="block mb-1 font-bold text-sm"
            inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
            input-class="w-full h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
            help-class="text-xs text-gray-500"
          />

          <FormKit v-for="(section, index) in locationExtras[locationDetail['id']]" :key="section.key" type="group" name="location_extras">
            <button @click.prevent="onLocationRemoveSectionClicked(locationDetail['id'], section)" type="button">Remove section</button>
            <FormKit
              v-model="section.title"
              :id="`section-title-${section.key}`"
              :key="`section-title-${section.key}`"
              type="text"
              :name="`location_extras[${locationDetail['id']}][${index}][title]`"
              placeholder="Enter title"
              outer-class="mb-5 w-2/3"
              label-class="block mb-1 font-bold text-sm"
              inner-class=" border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
              input-class="w-full h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
              help-class="text-xs text-gray-500"
            />

            <FormKit
              v-model="section.file"
              :id="`section-file-${section.key}`"
              :key="`section-file-${section.key}`"
              type="file"
              :name="`location_extras[${locationDetail['id']}][${index}][file]`"
              outer-class="mb-5 w-2/3"
              label-class="block mb-1 font-bold text-sm"
              inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
              input-class="w-full h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
              help-class="text-xs text-gray-500"
            />

            <FormKit
              v-model="section.summary"
              :id="`section-summary-${section.key}`"
              :key="`section-summary-${section.key}`"
              type="textarea"
              :name="`location_extras[${locationDetail['id']}][${index}][summary]`"
              rows="10"
              placeholder="Enter summary"
              outer-class="mb-5 w-2/3"
              label-class="block mb-1 font-bold text-sm"
              inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
              input-class="w-full h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
              help-class="text-xs text-gray-500"
            />
          </FormKit>
          <button @click.prevent="onAddLocationSectionClicked(locationDetail['id'])" class="btn btn-white my-5 py-5" type="button">Add new section</button>
          <p class='text-lg pb-5'>GeoGrids</p>
          <div v-for="geogrid_configs in this.geogridsByLocations.filter(el => el.filter(item => item['location_id'] === parseInt(locationDetail['id'])))" :key="geogrid_configs['id']"> 
            <div v-for="config in geogrid_configs" :key="config['id']">
              <FormKit v-for="geogrid_keyword in config['geogrid_keywords']" :key="geogrid_keyword['id']" type="group" name="geogrid_keywords">
                <p class='text-uppercase text-lg font-bold py-10'>{{ geogrid_keyword['text'] }}</p>
                
                <div v-if="geogrid_keyword['gif_url']">
                  <ul class="list-none"> 
                    <li class="inline-block px-3 py-3">
                          <img height="100" width="100" :src="geogrid_keyword['gif_url']" />
                      </li>
                      <li class="inline-block">
                        <FormKit
                          type="checkbox"
                          label="Timelapse photo"
                          help="Do you want this Geogrid Gif to be in the report?"
                          :name="`gif_active[${geogrid_keyword.id}]`"
                        />
                      </li>
                  </ul>
                </div>
                <div v-else>
                  <p class='text-lg badge p-10'>No GIF Found</p>
                </div>

                
                <div class="whitespace-nowrap">
                  <FormKit
                    label="Select Geogrids"
                    :name="`geogrid_keyword[${locationDetail['id']}][${geogrid_keyword['id']}]`"
                    help="Select geogrids for reports"
                    type="checkbox"
                    outer-class="geogrid-scroll overflow-x-scroll overflow-y-hidden w-full"
                    inner-class=""
                    input-class="inline-block"
                    :options="geogrid_keyword['geogrid_results_options']"
                  >

                    <template #label="context">
                      <div> 
                        <img
                          :src="`${context.option.label}`"
                          width="120"
                          alt=""
                        />
                      </div>
                    </template>
                  </FormKit>
                </div>
              </FormKit>
            </div>  
          </div>
        </FormKit>
      </div>
    </FormKit>
    {{formData}}
  <div v-if="loading">
    <div class="flex justify-center items-center">
      <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</div>
</template>

<style>
  .overflow-x-scroll { overflow-x: scroll; }
  .geogrid-scroll li { display: inline-block; margin: 1rem !important; }
  .geogrid-scroll ul { overflow-x: scroll; width: 100% }
  .geogrid-scroll fieldset { border: none !important }
  .formkit-wrapper { max-width: 100% !important }
  .formkit-form { width: 100% }
  .half { flex: 0 0 50%; padding-right: 20px; }
</style>
