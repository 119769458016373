// app/javascript/controllers/lazy_video_controller.js
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["source"]

  connect() {
    if ("IntersectionObserver" in window) {
      this.observer = new IntersectionObserver(this.loadVideo.bind(this), {
        rootMargin: "50px 0px",
      });
      this.observer.observe(this.element)
    } else {
      this.loadVideo()
    }
  }

  loadVideo(entries) {
    if (!entries || entries[0].isIntersecting) {
      this.sourceTarget.src = this.sourceTarget.dataset.lazySrc
      this.element.load()
      this.sourceTarget.removeAttribute("data-lazy-src");
      if (this.observer) {
        this.observer.disconnect();
      }
    }
  }
}