<script setup>

import { ref, watch, onMounted, defineProps } from 'vue'
import axios from 'axios'
import qs from 'qs'
import VueSlimTable from 'vue-slim-tables'
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'
import { Modal } from 'flowbite'

onMounted(() => {
  //getConversations()
  //getTimeZones()
})

const ajaxUrlSourcesUrl = `/ajax/chatbots/${props.chatbotId}/work_flows`
const columns = [
  { key: 'name', title: 'Name', orderable: true },
  { key: 'triggerEvent', title: 'When this happens' },
  { key: 'actions', title: 'Do this' },
  { key: 'status', title: 'Status' },
  { key: 'id', title: '' }
]

const searchValue = ref('')
const botLeadSearchValue = ref('')
const calendarEventSearchValue = ref('')
const table = ref(null)
const botLeadsTable = ref(null)
const calendarEventsTable = ref(null)
let timeout = null

const tableSource = (params) => {
  const path = `${ajaxUrlSourcesUrl}?${qs.stringify({ ...params, search: searchValue.value })}`
  return axios.get(path).then((res) => res.data)
}


const searchTextValue = ref('')



const deselectAll = () => {
  selectedSitemapUrls.value = []
}

const selectAll = () => {
  selectedSitemapUrls.value = sitemapUrlOptions.value.slice()
}

const refetchTableData = () => {
  table.value?.refetch()
}


watch(searchValue, () => {
  clearTimeout(timeout)
  timeout = setTimeout(refetchTableData, 300)
})


const props = defineProps({
  chatbotId: String,
  inboxId: String
})

const eventLabel = (value) => {
  let label = ''

  if (value === 'calendar_event_created') {
    label = '🗓️ User books an appointment'
  } else if (value === 'calendar_event_updated') {
    label = '🗓️ User updates an appointment'
  } else if (value === 'calendar_event_deleted') {
    label = '🗓️ User cancels an appointment'
  } else if (value === 'before_calendar_event') {
    label = '🗓️ Prior to an appointment'
  } else if (value === 'bot_lead_created') {
    label = '🤖 Bot finishes a conversation with a lead'
  } else if (value === 'conversation_stagnated') {
    label = '⌛️ Conversation stagnates with user'
  } else if (value === 'conversation_created') {
    label = "👋🏻 Conversation started by user"
  }

  return label
}

const destroyWorkFlow = (id) => {
  const workFlowPath = `/ajax/chatbots/${props.chatbotId}/work_flows/${id}`

  axios.delete(workFlowPath)
  .then((res) => {
    mdtoast.success('Work Flow destroyed', { duration: 10000 })
    refetchTableData()
  })
  .catch((error) => {
    console.error(error);
  });

}

const actionLabels = {
  'send_sms': '📱 Send SMS',
  'send_email': '✉️ Send Email',
  're_engages_customer': '♻️ Re-engage Customer'
}

const renderActions = (actions) => {
  return actions.map(action => actionLabels[action.type]).join(', ');
}

const toggleWorkFlow = (id) => {
  const toggleWorkFlowPath = `/ajax/chatbots/${props.chatbotId}/work_flows/${id}/toggle`

  axios.post(toggleWorkFlowPath)
  .then((res) => {
    mdtoast.success('Work Flow toggled', { duration: 10000 })
    refetchTableData()
  })
  .catch((error) => {
    console.error(error);
  });

}


</script>

<template>


    <VueSlimTable
      ref="table"
      class="table w-full text-sm text-left text-gray-500 dark:text-gray-400"
      :columns="columns"
      :per-page="10"
      :source="tableSource">
      <template #cell:actions="{ row, value }">
        {{ renderActions(value) }}
      </template>
      <template #cell:triggerEvent="{ row, value }">
        {{ eventLabel(value) }}
      </template>
      <!-- <template #cell:id="{ row, value }">
        <a :href="`/conversations/${row['id']}`">{{ value }}</a>
      </template> -->
      <template #cell:status="{ row, value }">
        <div v-if="value == 'active'" class="text-center">
          <span class="flex w-3 h-3 float-right bg-green-500 animate-pulse rounded-full"></span>
        </div>
        <div v-else>
           <span class="flex w-3 h-3 float-right bg-red-500 rounded-full"></span>
        </div>
      </template>

      <template #cell:id="{row, value}">
        <button id="dropdownMenuIconHorizontalButton" :data-dropdown-toggle="`dropdownDotsHorizontal_${row['id']}`" class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
          <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
            <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"/>
          </svg>
        </button>

        <!-- Dropdown menu -->
        <div :id="`dropdownDotsHorizontal_${row['id']}`" class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-34 dark:bg-gray-700 dark:divide-gray-600">
            <ul class="list-none py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownMenuIconHorizontalButton">
              <li>
                <a :href="`/clients/dashboard/chats/chatbots/${props.chatbotId}/work_flows/${row['id']}/edit`" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Edit</a>
              </li>
              <li>
                <a @click.prevent="toggleWorkFlow(row['id'])" href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Toggle</a>
              </li>
              <li>
                <a @click.prevent="destroyWorkFlow(row['id'])" href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Delete</a>
              </li>
              <!-- <li>
                <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
              </li> -->
            </ul>
            <!-- <div class="py-2">
              <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Separated link</a>
            </div> -->
        </div>
      </template>

      <template #cell:createdAt="{ row, value }">
        <a @click.prevent="destroyTrainingSource(row['id'], 'url')"
         href="#" class="pb-2 text-xs text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg px-5 py-2.5 inline-flex items-center mr-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900 text-xs">
           <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"/>
          </svg>
        </a>
      </template>


    </VueSlimTable>

</template>

<style scoped>
  .vst-th {
    font-weight: 200;
  }
</style>

