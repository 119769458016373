import { Controller } from '@hotwired/stimulus'
import { createApp } from 'vue'
import App from '../vue_widgets/campaign_work_order_table.vue'

export default class extends Controller {
  static values = { clientId: String, campaignId: String, month: Number }

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    this.vue = createApp(App, { 
      clientId: this.clientIdValue,
      campaignId: this.campaignIdValue,
      month: this.monthValue })
    this.vue.mount(this.element)
  }

  disconnect() {
    this.vue?.unmount()
  }
}
