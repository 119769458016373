import { Controller } from '@hotwired/stimulus'
import { createApp } from 'vue'
import App from '../vue_widgets/lead_dialers_select.vue'

export default class extends Controller {

  static values = {
    leadDialerId: String,
    leadDialerFriendlyName: String,
    organizationId: String,
    inboxId: String
  }

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
    console.log('multi select controller connected.')

    const props = {
      leadDialerFriendlyName: this.leadDialerFriendlyNameValue,
      leadDialerId: this.leadDialerIdValue,
      organizationId: this.organizationIdValue,
      inboxId: this.inboxIdValue
    }

    this.vue = createApp(App, props)
    this.vue.mount(this.element)
  }

  disconnect() {
    this.vue?.unmount()
  }
}
