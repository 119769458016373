<script setup>

import { ref, watch, onMounted } from 'vue'
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

const selectedRepCampaigns = ref([])
const repCampaingOptions = ref([])

const props = defineProps({
  organizationId: String
})

onMounted(() => {
  getRepCampaigns()
  getActiveRepCampaings()
})

const getRepCampaigns = () => {
  axios.get(`/ajax/rep_campaigns`).then((response) => {
    repCampaingOptions.value = response.data['result']
  }).catch((error) => {
    console.log(error)
  })
}

const getActiveRepCampaings = () => {
  console.log('getActiveRepCampaings')
  axios.get(`/ajax/organizations/${props.organizationId}/rep_campaigns`).then((response) => {
    selectedRepCampaigns.value = response.data['result']
  }).catch((error) => {
    console.log(error)
  })
}


const updateRepCampaigns = () => {
  console.log('updateRepCampaigns')
  axios.post(`/ajax/organizations/${props.organizationId}/update_rep_campaigns`, {
    selectedRepCampaigns: selectedRepCampaigns.value
  }).then((response) => {
    if (response.data.success) {
      window.location.reload()
    } else {
      console.log('error on updating')
    }
  }).catch((error) => {
    console.log(error)
  })
}
</script>

<template>

  <h4 class="pt-5">
      Campaigns for this organization
    </h4>
    <label class="typo__label">Select 1 or more campaign.</label>
    <multiselect

    v-model="selectedRepCampaigns"
    :options="repCampaingOptions"
    :multiple="true"
    :close-on-select="true"
    placeholder="select campaign(s)"
    label="name"
    track-by="name"
    />

    <button @click="updateRepCampaigns" class="mt-3 btn btn-primary">
      Update Campaigns
    </button>
</template>
