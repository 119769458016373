<script setup>

import { ref, watch, onMounted } from 'vue'
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

const selectedLocations = ref([])
const locationOptions = ref([])

const props = defineProps({
  organizationId: String
})

onMounted(() => {
  getLocations()
  getActiveLocations()
})

const getLocations = () => {
  axios.get(`/ajax/locations/all_locations`).then((response) => {
    locationOptions.value = response.data['result']
  }).catch((error) => {
    console.log(error)
  })
}

const getActiveLocations = () => {
  axios.get(`/ajax/organizations/${props.organizationId}/locations`).then((response) => {
    selectedLocations.value = response.data['result']
  }).catch((error) => {
    console.log(error)
  })
}


const updateLocations = () => {
  axios.post(`/ajax/organizations/${props.organizationId}/update_locations`, {
    selectedLocations: selectedLocations.value
  }).then((response) => {
    if (response.data.success) {
      window.location.reload()
    } else {
      console.log('error on updating')
    }
  }).catch((error) => {
    console.log(error)
  })
}
</script>

<template>

  <h4 class="pt-5">
      Locations for this organization
    </h4>
    <label class="typo__label">Select 1 or more location.</label>
    <multiselect

    v-model="selectedLocations"
    :options="locationOptions"
    :multiple="true"
    :close-on-select="true"
    placeholder="select location(s)"
    label="name"
    track-by="name"
    />

    <button @click="updateLocations" class="mt-3 btn btn-primary">
      Update Locations
    </button>
</template>
