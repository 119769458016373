<script> 
import { token } from '@formkit/utils'
import axios from 'axios'

export default {
  mounted() {
    this.getCampaignTemplates()
    this.getLocations()
  },
  props: {
    clientId: String
  },
  data() {
    return {
      formData: {},
      loading: false,
      remoteCampaignTemplates: [],
      campaignTemplateId: String,
      remoteLocations: [],
      locationId: String
    }
  },
  methods: {
    submitHandler(evt) {
      axios.post(`/ajax/clients/${this.clientId}/campaigns`, this.formData)
        .then((response) => {
          
          window.location = `/clients/${response.data['clientId']}/campaigns/${response.data['campaignId']}`
        }).catch((error) => {
          console.log(error)
        })
    },

    getCampaignTemplates() {
       axios.get(`/ajax/campaign_templates`).then((response) => {
        this.remoteCampaignTemplates = response.data['results']
      }).catch((error) => {
        console.log(error)
      })
    },

    getLocations() {
       axios.get(`/ajax/clients/${this.clientId}/locations`).then((response) => {
        this.remoteLocations = response.data['results']
      }).catch((error) => {
        console.log(error)
      })
    }
  },
  watch: {
  },
  computed: {
    console: () => console,
  }
}
</script>

<template>
  <div v-if="!this.loading" class='wrapper w-full'>
    <FormKit
      type="form"
      v-model="formData"
      :classes="{
        outer: '$reset w-full'
      }"
      submit-label="Create Product"
      :form-class="submitted ? 'hide' : 'show'"
      @submit="submitHandler">
    
      <FormKit
        type="text"
        name="title"
        label="Campaign Title"
        placeholder="Title For This Campaign"
        help="Title for this campaign"
        validation="required"
        outer-class="mb-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"
      />

      <FormKit
        type="textarea"
        label="Campaign Description"
        name="description"
        rows="10"
        placeholder="Write a description for this campaign."
        help="Write a clear description for this campaign."
        validation="required"
        outer-class="mb-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"
      />

      <FormKit
        type="number"
        help="Enter a budget for this product in USD greater than 1"
        label="Campaign Budget"
        name="budget"
        value="1"
        step="1"
        validation="required"
        outer-class="mb-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"
      />

      <FormKit
        v-model="campaignTemplate"
        type="select"
        label="Campaign Template"
        name="campaign_template_id"
        placeholder="Select a Campaign Template"
        :options="remoteCampaignTemplates"
        help="Select the campaign template this campaign will be based on."
        outer-class="my-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class=" border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="px-3 border-none text-base text-gray-700 placeholder-gray-400"
      />

      <FormKit
        v-model="location"
        type="select"
        label="Campaign Location"
        name="location_id"
        placeholder="Select a location"
        :options="remoteLocations"
        help="Select the location for this campaign."
        outer-class="my-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class=" border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="px-3 border-none text-base text-gray-700 placeholder-gray-400"
      />


      
    </FormKit>
  </div>
  <div v-else>
    <div class="flex justify-center items-center">
      <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<style>
</style>
