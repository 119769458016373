import { Controller } from '@hotwired/stimulus'
import axios from 'axios'
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'


export default class extends Controller {
  static targets = [
    'icon'
  ]

  static values = {
    accountId: { type: String, default: '' }
  }

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
    console.log('location sync controller connected.')
  }

  disconnect() {
  }

  sync(evt) {
    evt.preventDefault()
    this.iconTarget.classList.add('fa-spin')

    axios.post(`/ajax/accounts/${this.accountIdValue}/sync_lead_campaigns`)
      .then((response) => {
        this.iconTarget.classList.remove('fa-spin')
        mdtoast.info('Lead campaign sync complete.', { duration: 10000 })
      }).catch((error) => {
        console.log(error)
      })
  }
}
