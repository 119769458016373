import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'
import axios from 'axios' 

const chartData = {} 

export default class extends Controller {

  static targets =  [
    'screenPageViewsChart',
    'screenPageViewsPerSessionChart',
    'sessionsChart',
    'totalUsersChart',
    'averageSessionDurationChart',
    'bounceRateChart',
    'newUsersChart',
    'sessionsPerUserChart',
    'activeUsersChart',
    'engagedSessionsChart',
    'engagementRateChart',
    'sessionConversionRateChart',
    'conversionsChart',
    'eventCountChart'
  ]

  static values = {
    clientId: String,
    reportId: String,
    enabledMetrics: Array
  }

  canvasContext(target) { 
    return this[`${target}Target`].getContext('2d')
  }

  drawCharts() {
    axios.get(`/ajax/clients/${this.clientIdValue}/reports/${this.reportIdValue}/analytics_charts_data`).then((response) => {
      this.chartData = response.data['results']
      for (const chartMetric of this.constructor.targets) {
        if (!this.enabledMetricsValue.includes(`${chartMetric.replace('Chart', '')}`)) {
          continue
        }

        let chartClass = chartMetric.replace('Chart', '')

        new Chart(this.canvasContext(chartMetric), {
          type: 'line',
          data: this.chartData[`${chartClass}`],
          options: {
            scales: {
              x: {
                ticks: {
                  callback: function(label) {
                    let realLabel = this.getLabelForValue(label)
                    let selectedDateRange = realLabel.split(";")[0]
                    return selectedDateRange
                  }
                }
              },
              xAxis2: {
                type: 'category',
                grid: { 
                  drawOnChartArea: false,
                },
                ticks: {
                  callback: function(label) {
                    let realLabel = this.getLabelForValue(label)
                    let compareDateRange = realLabel.split(";")[1]
                    return compareDateRange
                  }
                }
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            hover: {
              intersect: true 
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function(context) {
                    let label = context.dataset.label || ''

                    if (label) {
                      label += ' - '
                    }

                    if (label && context.dataset.labels[context.dataIndex]) {
                      label += context.dataset.labels[context.dataIndex]
                    }

                    if (label && context.formattedValue) {
                      label += ` - ${context.formattedValue}`
                    }

                    return label  
                  },

                  title: function(context) {
                    return context[0].dataset.label 
                  }
                }
              }
            }
          },
          bezierCurve: false
        }); 
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
    this.drawCharts()
  }

  disconnect() {
  }
}
