<script setup>

import { ref, watch, onMounted, defineProps } from 'vue'
import axios from 'axios'
import qs from 'qs'
import VueSlimTable from 'vue-slim-tables'
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'
import { Modal } from 'flowbite'

onMounted(() => {
  //getConversations()
  //getTimeZones()
})

const ajaxUrlSourcesUrl = `/ajax/chatbots/${props.chatbotId}/training_sources/url_sources`
const columns = [
  { key: 'id', title:'' },
  { key: 'aasmState', title: 'State', orderable: true },
  { key: 'url', title: 'URL' },
  { key: 'title', title: 'Title' },
  { key: 'tokenCount', title: 'Tokens' },
  { key: 'chunkCount', title: 'Chunks' },
  { key: 'characterCount', title: 'Characters' },
  { key: 'hitCount', title: 'Recall Count', orderable: true },
  { key: 'createdAt', title: 'Actions' },
]

const searchValue = ref('')
const botLeadSearchValue = ref('')
const calendarEventSearchValue = ref('')
const table = ref(null)
const botLeadsTable = ref(null)
const calendarEventsTable = ref(null)
let timeout = null

const tableSource = (params) => {
  const path = `${ajaxUrlSourcesUrl}?${qs.stringify({ ...params, search: searchValue.value })}`
  return axios.get(path).then((res) => res.data)
}

const ajaxTextSourcesUrl = `/ajax/chatbots/${props.chatbotId}/training_sources/text_sources`
const textColumns = [
  { key: 'id', title:'' },
  { key: 'aasmState', title: 'State', orderable: true },
  { key: 'title', title: 'Title' },
  { key: 'tokenCount', title: 'Tokens' },
  { key: 'chunkCount', title: 'Chunks' },
  { key: 'characterCount', title: 'Characters' },
  { key: 'hitCount', title: 'Recall Count', orderable: true },
  { key: 'createdAt', title: 'Actions' },
]

const searchTextValue = ref('')
const textTable = ref(null)

const textTableSource = (params) => {
  const path = `${ajaxTextSourcesUrl}?${qs.stringify({ ...params, search: searchTextValue.value })}`
  return axios.get(path).then((res) => res.data)
}

const processText = () => {
  const articlesUrl = `/ajax/chatbots/${props.chatbotId}/articles?type=text`
  axios.post(articlesUrl, { title: textTitle.value, text: inputText.value })
    .then((response) => {
      mdtoast.info('Text queued for training.', { duration: 10000 })
      // this.iconTarget.classList.remove('fa-spin')
    }).catch((error) => {
      console.log(error)
    })

  const targetEl = document.getElementById('textModal')
  targetEl.classList.add('hidden')
  let backdrops = document.querySelectorAll('[modal-backdrop]');
  backdrops.forEach(function(backdrop) {
    backdrop.classList.add('hidden');
  });
  refetchTextTableData()
}

const urls = ref([])
const inputUrls = ref([])
const inputText = ref('')
const textTitle = ref('')
const urlsLoading = ref(false)
const xmlSitemapUrl = ref('')
const formType = ref('urls')
const checkAll = ref(false);
const checkedCount = ref(0);
const totalCount = ref(0);
const checkAllUrls = ref(false);
const checkedCountUrls = ref(0);
const totalCountUrls = ref(0);
const sitemapProcessing = ref(false)
const submitSitemapUrls = ref(false)
const sitemapUrlOptions = ref([])
const selectedSitemapUrls = ref([])

const storeRows = (data) => {
  totalCount.value = data.totalCount;
  data.rows = data.rows.map((el) => ({ ...el, checked: checkAll.value }))
};

const toggleAllPages = () => {
  checkAll.value = !checkAll.value
  checkedCount.value = checkAll.value ? textTable.value.rows.value.length : 0
  textTable.value.rows.value = textTable.value.rows.value.map((el) => ({ ...el, checked: checkAll.value }))
};

const togglePage = (row) => {
  row.checked = !row.checked;
  checkedCount.value += row.checked ? 1 : -1;
  checkAll.value = areLocationsChecked();
};

const areLocationsChecked = () => {
  return !textTable.value.rows.value.find((row) => !row.checked) && totalCount.value <= textTable.value.perPage
};

const storeUrlRows = (data) => {
  totalCountUrls.value = data.totalCountUrls;
  data.rows = data.rows.map((el) => ({ ...el, checked: checkAllUrls.value }))
};

const toggleAllUrls = () => {
  checkAllUrls.value = !checkAllUrls.value
  checkedCountUrls.value = checkAllUrls.value ? table.value.rows.value.length : 0
  table.value.rows.value = table.value.rows.value.map((el) => ({ ...el, checked: checkAllUrls.value }))
};

const toggleUrl = (row) => {
  row.checked = !row.checked;
  checkedCountUrls.value += row.checked ? 1 : -1;
  checkAllUrls.value = areUrlsChecked();
};

const areUrlsChecked = () => {
  return !table.value.rows.value.find((row) => !row.checked) && totalCount.value <= table.value.perPage
};

const processSitemap = () => {
  sitemapProcessing.value = true

  if(submitSitemapUrls.value) {
    const articlesUrl = `/ajax/chatbots/${props.chatbotId}/articles?type=urls`
    axios.post(articlesUrl, { urls: selectedSitemapUrls.value })
      .then((response) => {
        mdtoast.info('URLS queued for training.', { duration: 10000 })
        sitemapProcessing.value = false
        deselectAll()
        refetchTableData()
      }).catch((error) => {
        console.log(error)
      })

  } else {
    const url = `/ajax/chatbots/${props.chatbotId}/training_sources/xml_sitemap_to_urls`
     axios.post(url, { url: xmlSitemapUrl.value })
      .then((response) => {
        mdtoast.info('XML Sitemap parsed successfully.', { duration: 10000 })
        sitemapUrlOptions.value = response.data.results
        sitemapProcessing.value = false
        submitSitemapUrls.value = true
      }).catch((error) => {
        console.log(error)
      })
  }
}

const processUrls = () => {
  const inputArray = inputUrls.value.split(/\r?\n/)

  urls.value = inputArray.filter(url => {
    const trimmedUrl = url.trim()
    try {
      new URL(trimmedUrl)
      return true
    } catch (_) {
      return false
    }
  })

  const articlesUrl = `/ajax/chatbots/${props.chatbotId}/articles?type=urls`
  axios.post(articlesUrl, { urls: urls.value })
    .then((response) => {
      mdtoast.info('URLS queued for training.', { duration: 10000 })
    }).catch((error) => {
      console.log(error)
    })

  const targetEl = document.getElementById('defaultModal')
  targetEl.classList.add('hidden')
  let backdrops = document.querySelectorAll('[modal-backdrop]');
  backdrops.forEach(function(backdrop) {
    backdrop.classList.add('hidden');
  });
  refetchTableData()
}


const deselectAll = () => {
  selectedSitemapUrls.value = []
}

const selectAll = () => {
  selectedSitemapUrls.value = sitemapUrlOptions.value.slice()
}

const refetchTableData = () => {
  table.value?.refetch()
}

const refetchTextTableData = () => {
  textTable.value?.refetch()
}


watch(searchValue, () => {
  clearTimeout(timeout)
  timeout = setTimeout(refetchTableData, 300)
})

watch(searchTextValue, () => {
  clearTimeout(timeout)
  timeout = setTimeout(refetchTextTableData, 300)
})



const props = defineProps({
  chatbotId: String,
  inboxId: String
})

const destroyTrainingSource = (id, type) => {
  const trainingSourcePath = `/ajax/chatbots/${props.chatbotId}/training_sources/${id}`

  axios.delete(trainingSourcePath)
  .then((res) => {
    mdtoast.success('Training Source destroyed', { duration: 10000 })
    if (type == 'text') {
      refetchTextTableData()
    } else if (type == 'url') {
      refetchTableData()
    }

  })
  .catch((error) => {
    console.error(error);
  });
}

const removeSelected = () => {
  const ids = checkAll.value ? 'all' : textTable.value.rows.value.filter((row) => row.checked).map((row) => row.id);
  axios.post(`/ajax/chatbots/${props.chatbotId}/articles/bulk_text_destroy`, { ids })
    .then(() => {
      refetchTextTableData();
      mdtoast.info('Articles destroyed', { duration: 10000 });
      checkAll.value = false
    });
};

const removeSelectedUrls = () => {
  const ids = checkAllUrls.value ? 'all' : table.value.rows.value.filter((row) => row.checked).map((row) => row.id);
  axios.post(`/ajax/chatbots/${props.chatbotId}/articles/bulk_urls_destroy`, { ids })
    .then(() => {
      refetchTableData();
      mdtoast.info('Articles destroyed', { duration: 10000 });
      checkAllUrls.value = false
    });
};
</script>

<template>
  <div>
    <div class="pb-2 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
        URLs
        <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Your LindaBot ingests these URLs to offer personalized customer support for your business.</p>
    </div>
    <button data-modal-target="defaultModal" data-modal-toggle="defaultModal" class="text-gray-900 hover:text-gray-900 border border-gray-800 hover:bg-gray-50 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-xs px-3 py-2.5 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800" type="button">
      Add URL
    </button>

    <div class="form-group w-1/3 mb-3">
      <label for="search-websites" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search URLs</label>
    <div class="relative">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
        </div>
        <input v-model="searchValue" type="search" id="search-websites" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search urls..." required>

    </div>
  </div>
  <button v-if="checkedCountUrls" class="btn btn-danger float-left" @click="removeSelectedUrls()">
      Delete selected
    </button>
    <VueSlimTable
      ref="table"
      class="table w-full text-sm text-left text-gray-500 dark:text-gray-400"
      :columns="columns"
      :per-page="10"
      :source="tableSource">
      <template #thead>
          <span class="location-head-name">
            <input type="checkbox" :checked="checkAllUrls" @click.stop="toggleAllUrls">
          </span>
          <th></th>
          <th>State</th>
          <th>Title</th>
          <th>Tokens</th>
          <th>Chunks</th>
          <th>Chars</th>
        </template>

      <template #cell:id="{ row, value}">
          <input type="checkbox" :checked="row.checked" @change="toggleUrl(row)">

        </template>
      <template #cell:aasmState="{ row, value }">
        <div v-if="value == 'trained'">
          <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">Trained</span>

        </div>
        <div v-if="value == 'processing'">
           <span class="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-blue-400">Processing</span>

        </div>

        <div v-if="value == 'queued'">
           <span class="bg-pink-100 text-pink-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-pink-400">Queued</span>

        </div>

        <div v-if="value == 'error'">
           <span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">Failed</span>

        </div>

        <div v-if="value == 'waiting'">
           <span class="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-gray-400">Waiting</span>

        </div>
      </template>

      <template #cell:createdAt="{ row, value }">
        <a @click.prevent="destroyTrainingSource(row['id'], 'url')"
         href="#" class="pb-2 text-xs text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg px-5 py-2.5 inline-flex items-center mr-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900 text-xs">
           <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"/>
          </svg>
        </a>
      </template>


    </VueSlimTable>
  </div>

  <div>
    <div class="pb-2 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
        Text Blocks
        <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Your LindaBot ingests these Text Blocks to offer personalized customer support for your business.</p>
    </div>
    <button data-modal-target="textModal" data-modal-toggle="textModal" class="text-gray-900 hover:text-gray-900 border border-gray-800 hover:bg-gray-50 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-xs px-3 py-2.5 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800" type="button">
      Add Text
    </button>

    <div class="form-group w-1/3 mb-3">
      <label for="search-texts" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search Text</label>
    <div class="relative">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
        </div>
        <input v-model="searchTextValue" type="search" id="search-texts" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search text documents..." required>

    </div>
  </div>

    <button v-if="checkedCount" class="btn btn-danger float-left" @click="removeSelected()">
      Delete selected
    </button>
    <VueSlimTable
      ref="textTable"
      class="table w-full text-sm text-left text-gray-500 dark:text-gray-400"
      :columns="textColumns"
      :per-page="10"
      :source="textTableSource">
      <template #thead>
          <span class="location-head-name">
            <input type="checkbox" :checked="checkAll" @click.stop="toggleAllPages">
          </span>
          <th></th>
          <th>State</th>
          <th>Title</th>
          <th>Tokens</th>
          <th>Chunks</th>
          <th>Chars</th>
        </template>

        <template #cell:id="{ row, value}">
          <input type="checkbox" :checked="row.checked" @change="togglePage(row)">

        </template>

        <template #cell:aasmState="{ row, value }">
        <div v-if="value == 'trained'">
          <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">Trained</span>

        </div>
        <div v-if="value == 'processing'">
           <span class="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-blue-400">Processing</span>

        </div>

        <div v-if="value == 'queued'">
           <span class="bg-pink-100 text-pink-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-pink-400">Queued</span>

        </div>

        <div v-if="value == 'error'">
           <span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">Failed</span>

        </div>

        <div v-if="value == 'waiting'">
           <span class="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-gray-400">Waiting</span>

        </div>
      </template>

      <template #cell:createdAt="{ row, value }">
        <a @click.prevent="destroyTrainingSource(row['id'], 'text')"
         href="#" class="pb-2 text-xs text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg px-5 py-2.5 inline-flex items-center mr-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900 text-xs">
           <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"/>
          </svg>
        </a>
      </template>


    </VueSlimTable>
  </div>


<div id="defaultModal" tabindex="-1" aria-hidden="true" class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-full max-w-2xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal header -->
            <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                    Add Custom Training Source
                </h3>
                <div>
                    <a href="#" @click.prevent="formType = 'urls'">URLs</a> |
                    <a href="#" @click.prevent="formType = 'sitemap'">Sitemap</a>
                </div>
                <!-- ... -->
            </div>
            <!-- Modal body -->
            <div class="p-6 space-y-6">
                <form v-if="formType === 'urls'" @submit.prevent="processUrls()">
                     <label for="urls" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter URLs</label>
                  <textarea v-model="inputUrls" id="urls" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter URLs one per line."></textarea>

                  <button type="submit" class="right my-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xs px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Train</button>
                </form>
                <form v-else @submit.prevent="processSitemap()">
                    <label for="sitemap" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter Sitemap URL</label>
                    <input v-model="xmlSitemapUrl" id="sitemap" type="text" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-4" placeholder="Enter sitemap URL.">

                    <div v-if="sitemapUrlOptions.length > 0">
                      <a class='py-4' href="#" @click.prevent="selectAll">Select All</a> | <a class='py-4' href="#" @click.prevent="deselectAll">Deselect All</a>

                      <ul class="overflow-y-auto max-h-72 mt-4 mb-2 list-none text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white w-full">
                          <li v-for="(url, index) in sitemapUrlOptions" :key="index" class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600">
                              <div class="flex items-center pl-3">
                                  <input :id="'checkbox-' + index" type="checkbox" v-model="selectedSitemapUrls" :value="url" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                                  <label :for="'checkbox-' + index" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{ url }}</label>
                              </div>
                          </li>
                      </ul>
                      <small>{{ sitemapUrlOptions.length }} URLs - {{ selectedSitemapUrls.length }} selected</small>
                    </div>



                    <button v-if="!sitemapProcessing" type="submit" class="right my-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xs px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Process Sitemap</button>

                    <button v-if="sitemapProcessing" disabled type="button" class="my-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center">
                      <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                      </svg>
                      Loading...
                  </button>
                </form>
            </div>
            <!-- Modal footer -->
            <!-- ... -->
        </div>
    </div>
</div>

<div id="textModal" tabindex="-1" aria-hidden="true" class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative w-full max-w-2xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal header -->
            <div class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                    Add Custom Training Source
                </h3>

            </div>
            <!-- Modal body -->
            <div class="p-6 space-y-6">
                <form  @submit.prevent="processText()">

                  <div class='py-4'>
                    <label for="title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Title</label>
                    <input type="text" v-model="textTitle" id="title" class="py-4 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Title" required>
                  </div>

                  <label for="urls" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter Text</label>
                  <textarea v-model="inputText" id="urls" rows="4" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter URLs one per line."></textarea>

                  <button type="submit" class="right my-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xs px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Train</button>
                </form>
            </div>
            <!-- Modal footer -->
            <!-- ... -->
        </div>
    </div>
</div>
</template>

<style scoped>
  .vst-th {
    font-weight: 200;
  }
</style>

