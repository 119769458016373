import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    console.log("Connected to lazy-youtube controller.");

    if ("IntersectionObserver" in window) {
      this.observer = new IntersectionObserver(this.loadIframe.bind(this), {
        rootMargin: "50px 0px",
        threshold: 0.1
      });
      this.observer.observe(this.element);
    } else {
      // Fallback for browsers that don't support IntersectionObserver
      this.loadIframe();
    }
  }

  loadIframe(entries) {
    if (!entries || entries[0].isIntersecting) {
      this.element.src = this.element.dataset.lazySrc;
      this.element.removeAttribute("data-lazy-src");
      if (this.observer) {
        this.observer.disconnect();
      }
    }
  }
}