import { Controller } from '@hotwired/stimulus'


const PRICES = {
  'dfy': {
    'yearly': {
      'location': 1300,
      'reputation': 1400,
      'chat': 1321,
      'lead': 999,
      'listing': 788,
      'location': 666,
      'automation': 995
    },
    'monthly': {
      'location': 130,
      'reputation': 140,
      'chat': 99,
      'lead': 99,
      'listing': 78,
      'location': 66,
      'automation': 99
    }
  },
  'diy': {
    'yearly': {
      'location': 600,
      'reputation': 700,
      'chat': 621,
      'lead': 449,
      'listing': 388,
      'location': 366,
      'automation': 495
    },
    'monthly': {
      'location': 60,
      'reputation': 70,
      'chat': 45,
      'lead': 45,
      'listing': 34,
      'location': 33,
      'automation': 45
    }
  }
}

export default class extends Controller {
  static targets = [
    'diyMonthlyBtn',
    'diyYearlyBtn',
    'dfyMonthlyBtn',
    'dfyYearlyBtn',
    'diyCard',
    'dfyCard',
    'pricingLabel',
    'intervalLabel',
    'productPrice'
  ]

  static values = {
    productType: { type: String, default: 'dfy' },
    productInterval: { type: String, default: 'monthly'},
    locationManagementEnabled: { type: Boolean, default: false },
    reputationManagementEnabled: { type: Boolean, default: false },
    leadManagementEnabled: { type: Boolean, default: false },
    listingManagementEnabled: { type: Boolean, default: false },
    chatManagementEnabled: { type: Boolean, default: false },
    automationManagementEnabled: { type: Boolean, default: false },
    checkoutTotal: { type: Number, default: 0 }, 
  }

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
    console.log('connected from the pricing controller')
    
    this.productPriceTargets.forEach(productPrice => {
      const product = productPrice.dataset.product.split("_")[0]
      productPrice.innerText = PRICES['dfy']['monthly'][product]
    })
  }

  disconnect() {
    this.vue?.unmount()
  }

  intervalSelect() {
    event.preventDefault() 
    // swap active card classes 
    if (event.target.dataset.customPricingTarget.includes("diy")) {
      this.diyCardTarget.classList.add('border-blue-600')
      this.dfyCardTarget.classList.remove('border-blue-600')
    } else {
      this.dfyCardTarget.classList.add('border-blue-600')
      this.diyCardTarget.classList.remove('border-blue-600')
    }

    //set active button state
    this.diyMonthlyBtnTarget.classList.add('bg-gray-400')
    this.diyYearlyBtnTarget.classList.add('bg-gray-400')
    this.dfyMonthlyBtnTarget.classList.add('bg-gray-400')
    this.dfyYearlyBtnTarget.classList.add('bg-gray-400')

    event.target.classList.remove('bg-gray-400')

    this.diyMonthlyBtnTarget.classList.remove('bg-blue-700')
    this.diyYearlyBtnTarget.classList.remove('bg-blue-700')
    this.dfyMonthlyBtnTarget.classList.remove('bg-blue-700')
    this.dfyYearlyBtnTarget.classList.remove('bg-blue-700')

    event.target.classList.add('bg-blue-700')

    // set product settings
    this.productTypeValue = event.target.dataset.customPricingTarget.includes("diy") ? 'diy' : 'dfy' 
    this.productIntervalValue = event.target.dataset.customPricingTarget.includes("Monthly") ? 'monthly' : 'yearly'

    // set interval label 
    this.intervalLabelTargets.forEach(label => { label.innerText = event.target.dataset.customPricingTarget.includes("Yearly") ? 'yearly' : 'monthly' }) 
  }
  
  platformSelect() {
    const platform = event.target.id.split("_")[0]
    
    this[`${platform}ManagementEnabled`] = !this[`${platform}ManagementEnabled`]
    
    const price = PRICES[this.productTypeValue][this.productIntervalValue][platform]
    this.checkoutTotalValue += (this[`${platform}ManagementEnabled`] ? price : -(price))
    
    this.pricingLabelTarget.innerText = this.checkoutTotalValue
  }
}
