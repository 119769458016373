<script setup> 
import { QuillEditor } from '@vueup/vue-quill'
//import '@vueup/vue-quill/dist/vue-quill.snow.css'

import { ref, watch } from 'vue';

const props = defineProps({
  context: Object
})

const title = String(props.context.title)
const myEditor = ref(null)
const content = ref("")

watch(content, (val) => {
   props.context.node.input({})
   props.context.node.input(myEditor.value.getHTML())
})
</script>

<template>
  <div class='pb-4 w-2/3 '>
    <p class="text-xl font-bold text-uppercase py-4">{{ title }}</p>
    <QuillEditor ref="myEditor" v-model:content="content" style="height:300px;"  :auto-height="true" toolbar="essential" contenType="text" theme="snow" />
  </div>
</template>
