<script setup>

import { ref, watch, onMounted } from 'vue'
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

const selectedInboxes = ref([])
const inboxOptions = ref([])

const props = defineProps({
  organizationId: String
})

onMounted(() => {
  getInboxes()
  getActiveInboxes()
})

const getInboxes = () => {
  axios.get(`/ajax/inboxes`).then((response) => {
    inboxOptions.value = response.data['results']
  }).catch((error) => {
    console.log(error)
  })
}

const getActiveInboxes = () => {
  axios.get(`/ajax/organizations/${props.organizationId}/inboxes`).then((response) => {
    selectedInboxes.value = response.data['result']
  }).catch((error) => {
    console.log(error)
  })
}


const updateInboxes = () => {
  axios.post(`/ajax/organizations/${props.organizationId}/update_inboxes`, {
    selectedInboxes: selectedInboxes.value
  }).then((response) => {
    if (response.data.success) {
      window.location.reload()
    } else {
      console.log('error on updating')
    }
  }).catch((error) => {
    console.log(error)
  })
}
</script>

<template>
  <h4 class="pt-5">
      Inbox for this Organization
    </h4>
    <label class="typo__label">Select an inbox.</label>
    <multiselect

    v-model="selectedInboxes"
    :options="inboxOptions"
    :multiple="true"
    :close-on-select="true"
    placeholder="select Inbox"
    label="name"
    track-by="name"
    />

    <button @click="updateInboxes" class="mt-3 btn btn-primary">
      Update Inbox
    </button>
</template>
