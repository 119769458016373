<script setup>

import { ref, watch, onMounted, defineProps } from 'vue'
import axios from 'axios'
import VueSlimTables from 'vue-slim-tables'
import 'vue-slim-tables/stylesheets';
import qs from 'qs'

const props = defineProps({
  leadCampaignId: String
})

onMounted(() => {
  getLeadInfo()
})

// const locationInfo = ref({})
const infoLoading = ref(true)
const leadInfo = ref({})
const search = ref('')

const getLeadInfo = () => {
  axios.get(`/ajax/lead_campaigns/${props.leadCampaignId}/leads/stats`).then((response) => {
    leadInfo.value = response.data
    infoLoading.value = false
  }).catch((error) => {
    console.log(error)
  })
}

const columns = [
  { title: 'CreatedLocal', key: 'createdAt' },
  { title: 'Type', key: 'leadType' },
  { title: 'Site', key: 'site' },
  { title: 'Caller', key: 'caller' },
  { title: 'Duration', key: 'duration' },
  { title: 'Message', key: 'message' },
  { title: 'Created', key: 'remoteCreatedAt' },
]
const tableSource = (params) => {
  return axios.get(
    `/ajax/lead_campaigns/${props.leadCampaignId}/leads`,
    {
      params: { ...params, query: search.value },
      paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
    }
  ).then((res) => res.data)
}
</script>

<template>
  <div class="grid grid-cols-2 gap-1 py-6">
    <div class="p-8">
      <h2 class='text-center'>Calls</h2>
      <div v-if="infoLoading">
        <div role="status" class="space-y-2.5 py-4 animate-pulse max-w-lg">
          <div class="flex items-center w-full space-x-2">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[400px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[440px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[360px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <span class="sr-only">Loading...</span>
      </div>
      </div>
      <div v-else>
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        Segment
                    </th>
                    <th scope="col" class="px-6 py-3">
                        7d
                    </th>
                    <th scope="col" class="px-6 py-3">
                        30d
                    </th>
                    <th scope="col" class="px-6 py-3">
                        90d
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white">
                        All
                    </th>
                    <td class="px-6 py-4  font-bold">
                      {{ leadInfo['phoneLeadsLastWeek'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                      {{ leadInfo['phoneLeadsLastMonth'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                      {{ leadInfo['phoneLeadsLastQuarter'] }}
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white">
                        Answered
                    </th>
                    <td class="px-6 py-4  font-bold">
                        {{ leadInfo['answeredPhoneLeadsLastWeek'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                        {{ leadInfo['answeredPhoneLeadsLastMonth'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                        {{ leadInfo['answeredPhoneLeadsLastQuarter'] }}
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white">
                        Missed
                    </th>
                    <td class="px-6 py-4  font-bold">
                        {{ leadInfo['missedPhoneLeadsLastWeek'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                        {{ leadInfo['missedPhoneLeadsLastMonth'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                      {{ leadInfo['missedPhoneLeadsLastQuarter'] }}
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white">
                        1st Time
                    </th>
                    <td class="px-6 py-4  font-bold">
                      {{ leadInfo['firstTimePhoneLeadsLastWeek'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                      {{ leadInfo['firstTimePhoneLeadsLastMonth'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                        {{ leadInfo['firstTimePhoneLeadsLastQuarter'] }}
                    </td>
                </tr>
                <tr class="">
                    <th scope="row" class="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white">
                        Repeat
                    </th>
                    <td class="px-6 py-4  font-bold">
                        {{ leadInfo['repeatPhoneLeadsLastWeek'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                        {{ leadInfo['repeatPhoneLeadsLastMonth'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                      {{ leadInfo['repeatPhoneLeadsLastQuarter'] }}
                    </td>
                </tr>
            </tbody>
        </table>
      </div>

    </div>


    <div class="p-8">
      <h2 class='text-center'>Form Submits</h2>
      <div v-if="infoLoading">
        <div role="status" class="space-y-2.5 py-4 animate-pulse max-w-lg">
          <div class="flex items-center w-full space-x-2">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[400px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[440px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[360px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <span class="sr-only">Loading...</span>
      </div>
      </div>
      <div v-else>
         <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        Segment
                    </th>
                    <th scope="col" class="px-6 py-3">
                        7d
                    </th>
                    <th scope="col" class="px-6 py-3">
                        30d
                    </th>
                    <th scope="col" class="px-6 py-3">
                        90d
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Form Submits
                    </th>
                    <td class="px-6 py-4  font-bold">
                        {{ leadInfo['formLeadsLastWeek'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                        {{ leadInfo['formLeadsLastMonth'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                        {{ leadInfo['formLeadsLastQuarter'] }}
                    </td>
                </tr>
            </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-1 gap-1">
    <h2 class='px-6'>Leads</h2>
    <VueSlimTables
        ref="table"
        :source="tableSource"
        :columns="columns"
        class="table px-8 py-2"
        :per-page="10">

        <template #cell:message="{ row, value }">
          <div v-if="row['leadType'] == 'form_lead'">
            {{ row['data'] }}
          </div>
          <div v-else>
            <audio controls>
              <source :src="row['data']['recordingUrl']" type="audio/mp3">

            Your browser does not support the audio element.
            </audio>
          </div>
        </template>

        <template #cell:leadType="{ row, value }">
          <div v-if="value == 'form_lead'">
            <span class="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">Form</span>

          </div>
          <div v-else>
            <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Call</span>

          </div>
        </template>

        <template #cell:id="{ row, value }">
        </template>

      </VueSlimTables>
  </div>
</template>
