import swal from 'sweetalert'

const DEFAULT_SWAL_PARAMS = {
  title: 'Are you sure?',
  content: 'Once deleted, you will not be able to recover this!',
  icon: 'warning',
  buttons: true,
  dangerMode: true
}

export function runSwal(callback, params = {}) {
  const content = document.createElement('p')
  content.innerHTML = params.content ?? DEFAULT_SWAL_PARAMS.content

  swal({
    ...DEFAULT_SWAL_PARAMS,
    ...params,
    content
  }).then((willDelete) => {
    if (willDelete) callback()
  })
}

export default function deleteWithSwal() {
  $(document).on('click', '.js-delete-action', (e) => {
    e.preventDefault()

    const $linkEl = $(e.target).hasClass('js-delete-action') ? $(e.target) : $(e.target).parent('.js-delete-action')
    if ($linkEl.attr('disabled')) return

    runSwal(
      () => {
        $.ajax2({
          url: $linkEl.attr('href'),
          method: 'delete',
          success: (res) => {
            if (res && res.path) {
              window.location.pathname = res.path
            } else {
              window.location.reload()
            }
          }
        })
      },
      { content: $linkEl.data('swal-text') }
    )
  })
}
