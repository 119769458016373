import { Controller } from '@hotwired/stimulus'
import axios from 'axios'


export default class extends Controller {
  static targets = [
    'icon'
  ]


  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
    console.log('inboxes sync controller connected.')
  }

  disconnect() {
  }

  sync(evt) {
    evt.preventDefault()
    this.iconTarget.classList.add('fa-spin')

    axios.post(`/ajax/lead_dialers/sync`)
      .then((response) => {
        this.iconTarget.classList.remove('fa-spin')
      }).catch((error) => {
        console.log(error)
      })
  }
}
