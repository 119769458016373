import { Controller } from '@hotwired/stimulus'
import Typed from 'typed.js'

export default class extends Controller {
  
  static targets = ['content']

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    const typed = new Typed(this.contentTarget, {
      strings: ['increasing your SEO.', 'generating more Leads.', 'converting more traffic.', 'generating more reviews.', 'creating a content marketing engine.', 'performing the tasks of an entire marketing department.'],
      typeSpeed: 75,
      loop: true,
    })
  }
}
