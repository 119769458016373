import { Controller } from '@hotwired/stimulus'
import axios from 'axios'
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'

export default class extends Controller {
  static targets = [
    'icon'
  ]

  static values = {
    locationId: { type: String, default: '' }
  }

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
    console.log('geogrid keyword sync controller connected.')
  }

  disconnect() {
  }

  sync(evt) {
    evt.preventDefault()
    this.iconTarget.classList.add('fa-spin')
    axios.post(`/ajax/locations/${this.locationIdValue}/geogrid_keywords/sync_from_remote`)
      .then((response) => {
        mdtoast.info('Geogrid keywords synced successfully.', { duration: 10000 })
        this.iconTarget.classList.remove('fa-spin')
      }).catch((error) => {
        console.log(error)
      })
  }
}
