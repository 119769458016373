<script setup>

import { ref, watch, onMounted, defineProps } from 'vue'
import axios from 'axios'
import qs from 'qs'
import ChatbotConversations from './chatbot_widget/conversations.vue'

const props = defineProps({
  inboxes: Array
})

onMounted(() => {
 getConversationStats()
})

// const locationInfo = ref({})
const infoLoading = ref(true)
const conversationStats = ref({})

const getConversationStats = () => {
  infoLoading.value = true
  const statsPromises = props.inboxes.map((inbox) => {
    return axios.get(`/ajax/inboxes/${inbox.id}/stats`)
      .then((response) => ({ id: inbox.id, stats: response.data }))
  })
  Promise.all(statsPromises)
    .then((results) => {
      results.forEach((result) => {
        conversationStats.value[result.id] = result.stats
      })
      infoLoading.value = false
    })
    .catch((error) => {
      console.log(error)
    })
}
</script>

<template>
  <div class="py-6">
    <div class="p-8" v-for="inbox in props.inboxes" :key="inbox.id">
      <h2 class="mb-6">{{ inbox.name }}</h2>
      <div v-if="infoLoading">
        <div role="status" class="space-y-2.5 py-4 animate-pulse max-w-lg">
          <div class="flex items-center w-full space-x-2">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[400px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[440px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[360px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <span class="sr-only">Loading...</span>
      </div>
      </div>
      <div v-else>
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 mb-8">
            <thead class="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        Event
                    </th>
                    <th scope="col" class="px-6 py-3">
                         7d
                    </th>
                    <th scope="col" class="px-6 py-3">
                         30d
                    </th>
                    <th scope="col" class="px-6 py-3">
                         90d
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white">
                      Opened
                  </th>
                  <td class="px-6 py-4  font-bold">
                    {{ conversationStats[inbox.id]['openConversationsLastWeek'] }}
                  </td>
                  <td class="px-6 py-4  font-bold">
                    {{ conversationStats[inbox.id]['openConversationsLastMonth'] }}
                  </td>
                  <td class="px-6 py-4  font-bold">
                    {{ conversationStats[inbox.id]['openConversationsLastQuarter'] }}
                  </td>
              </tr>
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white">
                      Resolved
                  </th>
                  <td class="px-6 py-4  font-bold">
                    {{ conversationStats[inbox.id]['closedConversationsLastWeek'] }}
                  </td>
                  <td class="px-6 py-4  font-bold">
                    {{ conversationStats[inbox.id]['closedConversationsLastMonth'] }}
                  </td>
                  <td class="px-6 py-4  font-bold">
                    {{ conversationStats[inbox.id]['closedConversationsLastQuarter'] }}
                  </td>
              </tr>

              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white">
                      Pending
                  </th>
                  <td class="px-6 py-4  font-bold">
                    {{ conversationStats[inbox.id]['pendingConversationsLastWeek'] }}
                  </td>
                  <td class="px-6 py-4  font-bold">
                    {{ conversationStats[inbox.id]['pendingConversationsLastMonth'] }}
                  </td>
                  <td class="px-6 py-4  font-bold">
                    {{ conversationStats[inbox.id]['pendingConversationsLastQuarter'] }}
                  </td>
              </tr>

              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" class="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white">
                      Snoozed
                  </th>
                  <td class="px-6 py-4  font-bold">
                    {{ conversationStats[inbox.id]['snoozedConversationsLastWeek'] }}
                  </td>
                  <td class="px-6 py-4  font-bold">
                    {{ conversationStats[inbox.id]['snoozedConversationsLastMonth'] }}
                  </td>
                  <td class="px-6 py-4  font-bold">
                    {{ conversationStats[inbox.id]['snoozedConversationsLastQuarter'] }}
                  </td>
              </tr>
            </tbody>
        </table>

        <ChatbotConversations :inbox-id="inbox.id" />
      </div>
    </div>
  </div>
</template>
