<script> 
import { token } from '@formkit/utils'
import axios from 'axios'

const trelloLists = {
  newClientAudit: '61c9c01a6729823865319ca6',
  regionalCitations: '60d10c866916ec57e58a696e',
  secondaryCitations: '60d10c866916ec57e58a696f',
  ifttt: '61645b594f172a7f67544ff1',
  geoNetworkBasic: '60d10c866916ec57e58a6970',
  socialBasic: '60d10c866916ec57e58a6972',
  googleSitesAuthorityStacks: '60d10c866916ec57e58a6977',
  das: '60d10c866916ec57e58a6978',
  myMapStacks: '60d10c866916ec57e58a6979',
  videoCitations: '61fd21a02270af5126ec8796'
}

const hasTargetKeywordsField = [
  trelloLists['regionalCitations'],
  trelloLists['newClientAudit'],
  trelloLists['secondaryCitations'],
  trelloLists['ifttt'],
  trelloLists['googleSitesAuthorityStacks'],
  trelloLists['geoNetworkBasic']
]

const hasAreasServedField = [
  trelloLists['regionalCitations'], 
  trelloLists['secondaryCitations'], 
  trelloLists['ifttt']
]

const hasMainProductServiceField = [
  trelloLists['regionalCitations'], 
  trelloLists['newClientAudit'], 
  trelloLists['secondaryCitations'], 
  trelloLists['ifttt']
]

const hasPackageOrderedField = [
  trelloLists['regionalCitations'], 
  trelloLists['newClientAudit'], 
  trelloLists['secondaryCitations'], 
  trelloLists['ifttt']
]

const hasEmailForCitationsField = [
  trelloLists['regionalCitations'], 
  trelloLists['newClientAudit'], 
  trelloLists['secondaryCitations'], 
  trelloLists['ifttt']
]

const hasUrlField = [
  trelloLists['regionalCitations'],
  trelloLists['newClientAudit'],
  trelloLists['secondaryCitations'],
  trelloLists['ifttt'],
  trelloLists['googleSitesAuthorityStacks'],
  trelloLists['geoNetworkBasic'],
  trelloLists['videoCitations'],
  trelloLists['myMapStacks'],
  trelloLists['das'],
  trelloLists['socialBasic']
]

const hasTargetCityField = [trelloLists['myMapStacks'], trelloLists['googleSitesAuthorityStacks'], trelloLists['geoNetworkBasic']]  
const hasAccountNumberField = [trelloLists['socialBasic']]
const hasHandwrittenContentOrderedField = [trelloLists['socialBasic'], trelloLists['das']]
const hasGeoLocalPersonaField = [trelloLists['googleSitesAuthorityStacks'], trelloLists['myMapStacks']]
const hasAccountSignUpNameField = [trelloLists['googleSitesAuthorityStacks'], trelloLists['myMapStacks']]
const hasGeoLocalDescriptionField = [trelloLists['googleSitesAuthorityStacks'], trelloLists['myMapStacks']]
const hasBrandNameField = [trelloLists['googleSitesAuthorityStacks'], trelloLists['myMapStacks']]
const hasPrimaryKeywordField = [trelloLists['das']]
const hasTargetAnchorsField = [trelloLists['myMapStacks']]

export default {
  mounted() {
    if (!this.editTrelloListId) {
      this.getWorkOrders()
    } 

    this.resetDefaultValues()
  },
  props:{
    clientName: String,
    clientId: String,
    editTrelloListId: String,
    workOrderId: String,
    defaultTargetKeywords: Array,
    defaultAreasServed: Array,
    defaultMainProductsServices: Array,
    defaultTargetAnchors: Array,
    orderEmail: String,
    defaultTargetCity: String,
    defaultPrimaryKeyword: String
  },
  data() {
    return {
      workOrderTitle: null,
      workOrders: [],
      trelloListId: this.editTrelloListId,
      areasServed: [],
      targetCity: null, 
      targetAnchors: [],
      mainProductService: [],
      packageOrdered: null, 
      emailForCitations: null,
      targetKeywords: [],
      handwrittenContentOrdered: true,
      accountNumber: 50,
      formData: {},
      hasTargetKeywordsField: hasTargetKeywordsField,
      hasAreasServedField: hasAreasServedField,
      hasMainProductServiceField: hasMainProductServiceField,
      hasEmailForCitationsField: hasEmailForCitationsField,
      hasPackageOrderedField: hasPackageOrderedField,
      hasUrlField: hasUrlField,
      hasTargetCityField: hasTargetCityField,
      hasAccountSignUpNameField: hasAccountSignUpNameField,
      hasAccountNumberField: hasAccountNumberField,
      hasHandwrittenContentOrderedField: hasHandwrittenContentOrderedField,
      hasGeoLocalPersonaField: hasGeoLocalPersonaField,
      hasGeoLocalDescriptionField: hasGeoLocalDescriptionField,
      hasBrandNameField: hasBrandNameField,
      hasPrimaryKeywordField: hasPrimaryKeywordField,
      hasTargetAnchorsField: hasTargetAnchorsField,
      loading: false
    }
  },
  methods: {
    submitHandler() {
      this.loading = true 
      
      if (this.workOrderId) {
        this.formData["workOrderId"] = this.workOrderId 
      }

      if (this.trelloListId) {
        this.formData["trelloListId"] = this.trelloListId
      }

      axios.post(`/ajax/clients/${this.clientId}/work_orders`, {
        data: this.formData 
      }).then((response) => {
        this.loading = false 
        window.location = `/clients/${this.clientId}/work_orders/${response.data.result.workOrderId}`
      }).catch((error) => {
        console.log(error)
      })
    },

    visibleField(field) {
      if (field == 'targetKeywords') return this.hasTargetKeywordsField.includes(this.trelloListId) 
      if (field == 'areasServed') return this.hasAreasServedField.includes(this.trelloListId)
      if (field == 'mainProductService') return this.hasMainProductServiceField.includes(this.trelloListId)
      if (field == 'emailForCitations') return this.hasEmailForCitationsField.includes(this.trelloListId)
      if (field == 'packageOrdered') return this.hasPackageOrderedField.includes(this.trelloListId)
      if (field == 'url') return this.hasUrlField.includes(this.trelloListId)
      if (field == 'targetCity') return this.hasTargetCityField.includes(this.trelloListId)
      if (field == 'accountNumber') return this.hasAccountNumberField.includes(this.trelloListId)
      if (field == 'handWrittenContentOrdered') return this.hasHandwrittenContentOrderedField.includes(this.trelloListId)
      if (field == 'geoLocalPersona') return this.hasGeoLocalPersonaField.includes(this.trelloListId)
      if (field == 'accountSignUpName') return this.hasAccountSignUpNameField.includes(this.trelloListId)
      if (field == 'geoLocalDescription') return this.hasGeoLocalDescriptionField.includes(this.trelloListId)
      if (field == 'brandName') return this.hasBrandNameField.includes(this.trelloListId)
      if (field == 'primaryKeyword') return this.hasPrimaryKeywordField.includes(this.trelloListId) 
      if (field == 'targetAnchors') return this.hasTargetAnchorsField.includes(this.trelloListId)
    },

    getWorkOrders() {
       axios.get(`/ajax/work_orders`).then((response) => {
        this.workOrders = response.data['results']
      }).catch((error) => {
        console.log(error)
      })
    },

    resetDefaultValues() {
      // Ideally this wouldn't be needed to run so much but selecting new work order types clears the formData object 
      if (this.defaultTargetKeywords) { 
        this.formData["target_keywords"] = this.defaultTargetKeywords.join('\n')
      }

      if (this.defaultTargetAnchors) {
        this.formData["target_anchors"] = this.defaultTargetAnchors.join('\n')
      }

      if (this.defaultAreasServed) { 
        this.formData["areas_served"] = this.defaultAreasServed.join('\n') 
      }

      if (this.defaultMainProductsServices) {
        this.formData["main_product_service"] = this.defaultMainProductsServices.join('\n')
      }

      if (this.orderEmail) {
        this.formData["email_for_citations"] = this.orderEmail
      }

      if (this.defaultTargetCity) {
        this.formData["target_city"] = this.defaultTargetCity
      }



      this.formData["handwritten_content"] = true 
      this.formData["account_number"] = 50 
      this.formData["primary_keyword"] = this.defaultPrimaryKeyword
      this.formData["brand_name"] = this.clientName
    }
  },
  watch: {
    trelloListId: function(_val) {
      this.resetDefaultValues()
    }
  },
  computed: {
    clientPath() {
      return `/clients/${this.clientId}`
    },

    console: () => console
  }
}
</script>

<template>
  <div class='wrapper w-full'>
    <FormKit
      type="form"
      v-model="formData"
      :classes="{
        outer: '$reset w-full'
      }"
      submit-label="Create Work Order"
      :form-class="submitted ? 'hide' : 'show'"
      @submit="submitHandler">
     
     <FormKit
        type="text"
        name="title"
        v-model="workOrderTitle"
        label="Work Order Title"
        placeholder="Leave Blank For Auto Title"
        help="Title for this work order"
        outer-class="mb-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"
      />

      <FormKit
        v-model="trelloListId"
        v-if="!this.workOrderId"
        type="select"
        label="Work Order Type"
        name="trello_list_id"
        :value="trelloListId"
        :options="workOrders"
        outer-class="mb-5 w-2/3"
        placeholder="Select Work Order"
        help="The Work Order type."
      />

      <FormKit
        type="textarea"
        v-model="targetKeywords"
        v-if="visibleField('targetKeywords')"
        label="Target Keywords"
        name="target_keywords"
        rows="10"
        value="keyword 1"
        placeholder="1 Keyword Per Line"
        help="Enter your target keywords for this work order, one line per keyword"
      />
      
      <FormKit
        type="textarea"
        v-model="areasServed"
        v-if="visibleField('areasServed')"
        label="Areas Served"
        name="areas_served"
        rows="10"
        value="keyword 1"
        placeholder="Enter Areas Served"
        help="Enter your target areas for this work order"
      />

      <FormKit
        type="textarea"
        v-model="mainProductService"
        v-if="visibleField('mainProductService')"
        name="main_product_service"
        label="Main Products/Services Offered "
        placeholder="Enter main product/service"
        help="Enter your main product/service for this work order"
      />

   <!--    <FormKit
        type="text"
        v-model="packageOrdered"
        v-if="visibleField('packageOrdered')"
        name="package_ordered"
        label="Which packgage was purchased for this order?"
        placeholder="Enter package"
        help="Enter the package quantity assocated with this order"
      /> -->

      <FormKit
        type="email"
        v-model="emailForCitations"
        v-if="visibleField('emailForCitations')"
        name="email_for_citations"
        label="Email for Citations"
        placeholder="email@domain.com"
        help="Enter your email for the citation order."
      />

      <FormKit
        type="text"
        v-model="url"
        v-if="visibleField('url')"
        name="url"
        label="URL"
        placeholder="https://target-url.com/url"
        help="Enter the target URL for this work order."
      />

      <FormKit
        type="text"
        v-model="targetCity"
        v-if="visibleField('targetCity')"
        name="target_city"
        label="Target City"
        placeholder="Target City"
        help="Enter the target city for this work order."
      />

      <FormKit
        type="text"
        v-model="accountNumber"
        v-if="visibleField('accountNumber')"
        name="account_number"
        label="Number of accounts ordered"
        placeholder="(25, 50, 100)"
        help="Enter the number of social accounts for this work order."
      />

      <FormKit
        v-model="handwrittenContentOrdered"
        v-if="visibleField('handWrittenContentOrdered')"
        type="checkbox"
        label="Have you ordered handwritten content?"
        help="Do you have hand written content ordered for this work order?"
        name="handwritten_content"
      />

      <FormKit
        type="text"
        v-model="geoLocalPersona"
        v-if="visibleField('geoLocalPersona')"
        name="geo_local_persona"
        label="Geo Local Persona"
        placeholder="Geo local persona"
        help="This persona to be used with this work order."
      />

      <FormKit
        type="text"
        v-model="accountSignUpName"
        v-if="visibleField('accountSignUpName')"
        name="account_sign_up_name"
        label="Name to sign up accounts under"
        placeholder=""
        help="Enter the default name to sign up accounts with."
      />

      <FormKit
        type="textarea"
        v-model="geoLocalDescription"
        v-if="visibleField('geoLocalDescription')"
        label="Geo Local Description"
        name="geo_local_description"
        rows="10"
        placeholder="Enter the geo local description"
        help="Enter your geo local description for this work order."
      />

      <FormKit
        type="text"
        v-model="brandName"
        v-if="visibleField('brandName')"
        name="brand_name"
        label="Brand Name"
        placeholder="Brand Name"
        help="Enter the brand name to use with this order."
      />

      <FormKit
        type="text"
        v-model="primaryKeyword"
        v-if="visibleField('primaryKeyword')"
        name="primary_keyword"
        label="Primary Keyword"
        placeholder="Primary Keyword"
        help="Enter the target keyword for this work order."
      />

      <FormKit
        type="textarea"
        v-model="targetAnchors"
        v-if="visibleField('targetAnchors')"
        label="Target Anchors"
        name="target_anchors"
        rows="10"
        placeholder="1 Anchor Per Line"
        help="Enter your target anchors for this work order, one line per keyword"
      />
    </FormKit>
    {{formData}}
  </div>
</template>

<style>

</style>
