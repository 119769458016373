<template>
  <div>
    <div class="pb-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
      Conversations
      <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">These conversations are generated via the AI chatbot.</p>
    </div>
    <div class="form-group w-1/3 mb-3">
      <label for="search-websites" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search Conversations</label>
    <div class="relative">
      <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
        <svg
          aria-hidden="true"
          class="w-5 h-5 text-gray-500 dark:text-gray-400"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
      </div>
      <input
        v-model="searchValue"
        type="search"
        id="search-websites"
        class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Search conversations..."
        required>
    </div>
  </div>

  <VueSlimTable
    ref="table"
    class="table w-full text-sm text-left text-gray-500 dark:text-gray-400"
    :columns="columns"
    :source="tableSource"
    @click.prevent>
    <template #cell:createdAt="{ row, value }">
      <a :href="`/conversations/${row['id']}`">{{ value }}</a>
    </template>
    <template #cell:aasmState="{ value }">
      {{ aasmStateMap[value] }}
    </template>
    <template #cell:contactGathered="{ value }">
      <div v-if="value == true">
        <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">Yes</span>
      </div>
      <div v-if="value == false">
        <span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">No</span>
      </div>
    </template>
    <template #cell:totalPromptTokens="{ row }">
      {{ row['totalPromptTokens'] }} / {{ row['totalCompletionTokens'] }}
    </template>
    <template #cell:id="{ row }">
      <a
        href="#"
        class="pb-2 text-xs text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg px-5 py-2.5 inline-flex items-center mr-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900 text-xs"
        @click.prevent="destroyConversation(row['id'])">
        <svg
          class="w-4 h-4"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 18 20">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z" />
        </svg>
      </a>
    </template>
    <template #pagination>
      <div></div>
    </template>
  </VueSlimTable>
  </div>
</template>

<script setup>
import axios from 'axios'
import qs from 'qs'
import VueSlimTable from 'vue-slim-tables'
import { ref, watch } from 'vue'

const props = defineProps({
  chatbotId: String,
  inboxId: String
})

const ajaxConversationsUrl = `/ajax/inboxes/${props.inboxId}/conversations`
const columns = [
  { key: 'createdAt', title: 'Created', orderable: true },
  { key: 'aasmState', title: 'Conversation Control', orderable: true },
  { key: 'sessionId', title: 'Session ID' },
  { key: 'sender', title: 'Contact' },
  { key: 'messages', title: 'Msg Count' },
  { key: 'contactGathered', title: 'Contact Created' },
  { key: 'totalPromptTokens', title: 'Tokens'},
  { key: 'id', title: 'Actions' }
]
const aasmStateMap = {
  openai_control: '🤖 AI',
  user_control: '👨🏼‍🦰 Human',
  appointment_control: '🗓️ Scheduling',
  calendar_event_control: '🗓️ Scheduling',
  event_update_control: '🗓️ + Updating Event',
  event_cancelation_control: '🗓️ ❌ Canceling Event',
  dynamic_intent_params_control: '🦾 Dynamic Intent',
  dynamic_intent_control: '🦾 Dynamic Intent'
}

const table = ref(null)
const searchValue = ref('')

const tableSource = (params) => {
  const path = `${ajaxConversationsUrl}?${qs.stringify({ ...params, search: searchValue.value })}`
  return axios.get(path).then((res) => res.data)
}

const refetchTableData = () => {
  table.value?.refetch()
}

const destroyConversation = (conversationId) => {
  const conversationPath = `/conversations/${conversationId}`
  axios.delete(conversationPath)
  .then((res) => {
    mdtoast.success('Conversation destroyed', { duration: 10000 })
    refetchTableData()
  })
  .catch((error) => {
    console.error(error);
  });

}

watch(searchValue, () => {
  clearTimeout(timeout)
  timeout = setTimeout(refetchTableData, 300)
})
</script>
