import { Controller } from '@hotwired/stimulus'
import { createApp } from 'vue'
import App from '../vue_widgets/geogrid_settings_table.vue'

export default class extends Controller {
  static values = {
    locationId: { type: String, default: '' }
  }
  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
    console.log('geogrid settings table controller connected.')
    this.vue = createApp(App, { locationId: this.locationIdValue })
    this.vue.mount(this.element)
  }

  disconnect() {
    this.vue?.unmount()
  }
}
