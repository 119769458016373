<!-- OrganizationNotes.vue -->
<template>
  <div class="p-4">
    <h2 class="text-2xl font-bold mb-4">Admin Notes</h2>
    
    <!-- Note Form -->
    <form @submit.prevent="addNote" class="mb-6">
      <div class="mb-4">
        <label for="title" class="block text-sm font-medium text-gray-700">Title</label>
        <input v-model="newNote.title" type="text" id="title" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" :disabled="isAddingNote">
      </div>
      <div class="mb-4">
        <label for="content" class="block text-sm font-medium text-gray-700">Content</label>
        <textarea v-model="newNote.content" id="content" rows="3" class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" :disabled="isAddingNote"></textarea>
      </div>
      <button type="submit" class="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed" :disabled="isAddingNote || !isFormValid">
        {{ isAddingNote ? 'Adding Note...' : 'Add Note' }}
      </button>
    </form>

    <!-- Error Alert -->
    <div v-if="error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
      <strong class="font-bold">Error!</strong>
      <span class="block sm:inline">{{ error }}</span>
    </div>

    <!-- Loading State -->
    <div v-if="isLoading" class="flex justify-center items-center py-8">
      <div class="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
    </div>

    <!-- Notes List -->
    <div v-else-if="notes.length > 0">
      <div v-for="note in notes" :key="note.id" class="bg-white shadow overflow-hidden sm:rounded-lg mb-4">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">{{ note.title }}</h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">Added by {{ note.author }} on {{ formatDate(note.createdAt) }}</p>
        </div>
        <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl class="sm:divide-y sm:divide-gray-200">
            <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt class="text-sm font-medium text-gray-500">Content</dt>
              <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ note.content }}</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <p v-else class="text-gray-500">No notes available.</p>

    <!-- Pagination -->
    <div v-if="totalPages > 1" class="flex justify-center mt-4">
      <button
        v-for="page in totalPages"
        :key="page"
        @click="changePage(page)"
        class="mx-1 px-3 py-1 rounded"
        :class="currentPage === page ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'"
      >
        {{ page }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import axios from 'axios'

const props = defineProps({
  organizationId: {
    type: Number,
    required: true
  }
})

const notes = ref([])
const newNote = ref({ title: '', content: '' })
const isLoading = ref(false)
const isAddingNote = ref(false)
const error = ref(null)
const currentPage = ref(1)
const totalPages = ref(1)
const perPage = 10

const isFormValid = computed(() => {
  return newNote.value.title.trim() !== '' && newNote.value.content.trim() !== ''
})

const fetchNotes = async (page = 1) => {
  isLoading.value = true
  error.value = null
  try {
    const response = await axios.get(`/ajax/organizations/${props.organizationId}/admin_notes`, {
      params: { page, per_page: perPage }
    })
    notes.value = response.data.notes
    totalPages.value = response.data.total_pages
    currentPage.value = page
  } catch (err) {
    error.value = 'Failed to fetch notes. Please try again.'
    console.error('Error fetching notes:', err)
  } finally {
    isLoading.value = false
  }
}

const addNote = async () => {
  if (!isFormValid.value) return

  isAddingNote.value = true
  error.value = null
  try {
    const response = await axios.post(`/ajax/organizations/${props.organizationId}/admin_notes`, {
      admin_note: newNote.value
    })
    notes.value.unshift(response.data)
    newNote.value = { title: '', content: '' }
    // Optionally refetch the first page to ensure correct ordering
    await fetchNotes(1)
  } catch (err) {
    error.value = 'Failed to add note. Please try again.'
    console.error('Error adding note:', err)
  } finally {
    isAddingNote.value = false
  }
}

const changePage = (page) => {
  if (page !== currentPage.value) {
    fetchNotes(page)
  }
}

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleString()
}

onMounted(() => {
  fetchNotes()
})

watch(() => props.organizationId, () => {
  fetchNotes()
})
</script>