import { createVNode, render } from 'vue'
import { UiSlidePanel } from '../components/ui/slide_panel'

const wrapper = document.createElement('div')
document.body.append(wrapper)

const panelVNode = createVNode(UiSlidePanel)
render(panelVNode, wrapper)

const { open, close } = panelVNode.component.exposed

export default function useSlidePanel() {
  return { open, close }
}
