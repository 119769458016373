import { Controller } from '@hotwired/stimulus'
import { createApp } from 'vue'
import App from '../vue_widgets/report.vue'
import DateRangeInput from '../vue_widgets/date_range_input.vue'
import RichEditor from '../vue_widgets/quill_editor.vue'
import axios from 'axios'
import { plugin, defaultConfig } from '@formkit/vue'
import { generateClasses } from '@formkit/tailwindcss'
import '@formkit/themes/genesis'

export default class extends Controller {
  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    const dateRangeInputOptions = {
      type: 'input',
      schema: [],
      component: DateRangeInput,
      props: ['daterange'],
      features: []
    }

    const richEditorOptions = {
      type: 'input',
      schema: [],
      component: RichEditor,
      props: ['title'],
      features: []
    }

    this.vue = createApp(App).use(plugin, defaultConfig({
      inputs: { 
        daterange: dateRangeInputOptions,
        quill: richEditorOptions
      } 
    }))
    this.vue.mount(this.element)
  }

  disconnect() {
    this.vue?.unmount()
  }
}
