<script setup>

import { ref, watch, onMounted } from 'vue'
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

const service = ref([])
const availableServiceOptions = ref([])
const location = ref([])
const locationOptions = ref([])
const hideAddress = ref(false)

const props = defineProps({
  organizationId: String
})

onMounted(() => {
  getAvailableServices()
  getLocations()
})

const getAvailableServices = () => {
  axios.get(`/ajax/available_services`).then((response) => {
    availableServiceOptions.value = response.data['availableServices']
  }).catch((error) => {
    console.log(error)
  })
}

const getLocations = () => {
  axios.get(`/ajax/organizations/${props.organizationId}/locations`).then((response) => {
    locationOptions.value = response.data['result']
  }).catch((error) => {
    console.log(error)
  })
}

const updateSubscription = () => {
  axios.post(`/ajax/organizations/${props.organizationId}/listing_subscribe`, {
    selectedService: service.value, selectedLocation: location.value, hideAddress: hideAddress.value,
  }).then((response) => {
    if (response.data.success) {
      window.location.reload()
    } else {
      console.log('error on updating')
    }
  }).catch((error) => {
    console.log(error)
  })
}
</script>

<template>
  <h4 class="pt-5">
      Available Yext Packages
    </h4>
    <label class="typo__label">Select a package.</label>
    
    <multiselect
    v-model="service"
    :options="availableServiceOptions"
    :multiple="false"
    :close-on-select="true"
    placeholder="select Service"
    label="name"
    track-by="name"
    class="my-8"
    />

    <multiselect
    v-model="location"
    :options="locationOptions"
    :multiple="false"
    :close-on-select="true"
    placeholder="select Location"
    label="name"
    track-by="name"
    />

    <div class="flex items-center my-4">
      <input id="link-checkbox" type="checkbox" v-model="hideAddress" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
      <label for="link-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Business is a SAB (hide address).</label>
    </div>

    <button @click="updateSubscription" class="mt-3 btn btn-primary">
      Subscribe
    </button>
</template>
