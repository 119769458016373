import { Controller } from "@hotwired/stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static values = { requestId: String, analysisReady: Boolean }
  static targets = ['message', 'grade']

  connect() {
    this.subscription = consumer.subscriptions.create({ channel: `PublicGeogridAnalysisChannel`, requestId: this.requestIdValue }, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this)
    })

  }

  disconnect() {
    this.subscription.unsubscribe()
  }

  _connected() {
  }

  _disconnected() {
  }

  _received(data) {
    let waitingMessage = document.getElementById("waiting_message");
    if (waitingMessage) {
      waitingMessage.classList.add('hidden');
      waitingMessage.style.display = 'none';
    }

    let locationVisibilityCardSkeleton = document.getElementById("location-visibility-card-skeleton");

    if (locationVisibilityCardSkeleton) {
      locationVisibilityCardSkeleton.id = "location-visibility-card";
      locationVisibilityCardSkeleton.classList.remove('bg-gray-300', 'animate-pulse');
      locationVisibilityCardSkeleton.classList.add('bg-gray-100', 'dark:bg-gray-800');

      locationVisibilityCardSkeleton.innerHTML = `
        <div>
          <h4 class='text-center'>Location Visibility Grade</h4>
          <div style="font-size: 10rem;" class="text-5xl font-bold text-center grade-value ${this.gradeColor(data.grade)}">${data.grade}</div>
        </div>
        <div class="mt-4 p-4 bg-gray-200 dark:bg-gray-700 rounded-lg">
          <h5 class="font-semibold">Explanation</h5>
          <p class="text-sm">This score measures how often your Google Listing shows up in Search.</p>
        </div>
      `;
    }

    let messageTarget = this.messageTarget;
    if (messageTarget) {
      messageTarget.innerHTML = data.text;
      messageTarget.classList.remove('hidden', 'opacity-0');
      messageTarget.classList.add('opacity-100');
      messageTarget.style.textAlign = 'center';  // Ensuring the text is centered
    }
  }

  gradeColor(grade) {
    if (grade === 'A') {
      return 'text-green-700'
    } else if (grade === 'B') {
      return 'text-blue-700'
    } else if (grade === 'C') {
      return 'text-yellow-500'
    } else if (grade === 'D') {
      return 'text-orange-500'
    } else if (grade === 'F') {
      return 'text-red-700'
    }
  }

  openChat() {
    window.$chatwoot.toggle('open')
  }

}
