import { Controller } from '@hotwired/stimulus'
import { createApp } from 'vue'
import App from '../vue_widgets/edit_chatbot_widget.vue'

export default class extends Controller {

  static values = {
    organizationId: String,
    chatbotName: String,
    chatbotId: String,
    businessName: String,
    businessCity: String,
    businessState: String,
    description: String,
    gptModel: String,
    timeZone: String,
    googleCalendars: Array,
    appointmentConfirmation: String,
    serviceSystemPrompt: String,
    leadValues: Array,
    officeHoursCallsStart: Number,
    officeHoursCallsEnd: Number,
    outOfOfficeCallsMessage: String,
    agentNumber: String,
    recordingOption: Boolean,
    officeHoursStart: Number,
    officeHoursEnd: Number,
    outOfOfficeMessage: String,
    eventDescription: String,
    eventTitle: String,
    callIntent: Boolean,
    officeIntent: Boolean,
    serviceIntent: Boolean,
    appointmentIntent: Boolean,
    webhookUrl: String,
    phoneRequired: Boolean,
    emailRequired: Boolean,
    companyRequired: Boolean,
    slackWebhookUrl: String,
    slackMessageNotifications: Boolean,
    slackEventNotifications: Boolean,
    temperature: Number,
    rateLimitMessage: String,
    minuteRateLimit: Number,
    businessScheduleDescription: String,
    holidayScheduleDescription: String,
    daysOff: Array,
    botType: String,
    currentPromotions: String,
    servicesOfferedDescription: String,
    serviceAreaDescription: String,
    pricingDescription: String,
    knowledgeSeekingPrompt: String,
    inbox: Object,
    apiKeyRequired: Boolean,
    apiKey: String,
    contentModeration: Boolean,
    phoneCapable: Boolean
  }

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
    console.log('location mgmt widget controller connected.')

    const props = {
      organizationId: this.organizationIdValue,
      chatbotId: this.chatbotIdValue,
      currentChatbotName: this.chatbotNameValue,
      currentBusinessName: this.businessNameValue,
      currentBusinessCity: this.businessCityValue,
      currentBusinessState: this.businessStateValue,
      currentDescription: this.descriptionValue,
      currentGptModel: this.gptModelValue,
      currentTimeZone: this.timeZoneValue,
      currentGoogleCalendars: this.googleCalendarsValue,
      currentAppointmentConfirmation: this.appointmentConfirmationValue,
      currentServiceSystemPrompt: this.serviceSystemPromptValue,
      currentLeadValues: this.leadValuesValue,
      currentOfficeHoursCallsStart: this.officeHoursCallsStartValue,
      currentOfficeHoursCallsEnd: this.officeHoursCallsEndValue,
      currentOutOfOfficeCallsMessage: this.outOfOfficeCallsMessageValue,
      currentAgentNumber: this.agentNumberValue,
      currentRecordingOption: this.recordingOptionValue,
      currentOfficeHoursStart: this.officeHoursStartValue,
      currentOfficeHoursEnd: this.officeHoursEndValue,
      currentOutOfOfficeMessage: this.outOfOfficeMessageValue,
      currentEventDescription: this.eventDescriptionValue,
      currentEventTitle: this.eventTitleValue,
      callIntent: this.callIntentValue,
      officeIntent: this.officeIntentValue,
      serviceIntent: this.serviceIntentValue,
      appointmentIntent: this.appointmentIntentValue,
      currentWebhookUrl: this.webhookUrlValue,
      currentPhoneRequired: this.phoneRequiredValue,
      currentEmailRequired: this.emailRequiredValue,
      currentCompanyRequired: this.companyRequiredValue,
      currentSlackWebhookUrl: this.slackWebhookUrlValue,
      currentSlackEventNotifications: this.slackEventNotificationsValue,
      currentSlackMessageNotifications: this.slackMessageNotificationsValue,
      currentTemperature: this.temperatureValue,
      currentRateLimitMessage: this.rateLimitMessageValue,
      currentMinuteRateLimit: this.minuteRateLimitValue,
      currentBusinessScheduleDescription: this.businessScheduleDescriptionValue,
      currentHolidayScheduleDescription: this.holidayScheduleDescriptionValue,
      currentDaysOff: this.daysOffValue,
      currentBotType: this.botTypeValue,
      currentCurrentPromotions: this.currentPromotionsValue,
      currentServiceAreaDescription: this.serviceAreaDescriptionValue,
      currentServicesOfferedDescription: this.servicesOfferedDescriptionValue,
      currentPricingDescription: this.pricingDescriptionValue,
      currentKnowledgeSeekingPrompt: this.knowledgeSeekingPromptValue,
      currentInbox: this.inboxValue,
      apiKey: this.apiKeyValue,
      apiKeyRequired: this.apiKeyRequiredValue,
      currentContentModeration: this.contentModerationValue,
      phoneCapable: this.phoneCapableValue
    }

    this.vue = createApp(App, props)
    this.vue.mount(this.element)
  }

  disconnect() {
    this.vue?.unmount()
  }
}
