import { Controller } from '@hotwired/stimulus'
import axios from 'axios'


export default class extends Controller {
  static targets = [
    'icon'
  ]

  static values = {
    organizationId: { type: String, default: '' }
  }

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
    console.log(`inboxes sync controller connected: ${this.organizationIdValue}`)
  }

  disconnect() {
  }

  sync(evt) {
    evt.preventDefault()
    this.iconTarget.classList.add('fa-spin')
    axios.post(`/ajax/inboxes/sync`)
      .then((response) => {
        window.location.reload()
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.iconTarget.classList.remove('fa-spin')
      })
  }
}
