import { Controller } from '@hotwired/stimulus'
import { createApp } from 'vue'
import App from '../vue_widgets/local_seo_guides_table.vue'
export default class extends Controller {

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    this.vue = createApp(App)
    this.vue.mount(this.element)
  }

  disconnect() {
  }


}
