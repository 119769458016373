<script setup>

import { ref, watch, onMounted, computed, nextTick, reactive, defineProps, markRaw } from 'vue'
import axios from 'axios'
import qs from 'qs'
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'
import { VueFlow, useVueFlow } from '@vue-flow/core'
import '@vue-flow/core/dist/style.css';
import '@vue-flow/core/dist/theme-default.css'
import ApptNode from './nodes/appointment_node.vue'
import CallNode from './nodes/call_node.vue'
import ServiceNode from './nodes/service_node.vue'
import HumanHandoffNode from './nodes/human_handoff_node.vue'
import DynamicNode from './nodes/dynamic_node.vue'

onMounted(() => {
  fetchCustomIntents()
  console.log(`from bot editor: ${props.officeHoursCallsStart}`)
})

const fetchCustomIntents = () => {
  axios.get(`/ajax/chatbots/${props.chatbotId}/custom_intents`).then((response) => {
    customNodes.value = response.data.customIntents || []

    customNodes.value.forEach((node) => {
      if (node.enabled) {
        const newNode = { ...node, id: elements.value.length + 1, position: { x: 200, y: 600 } }
        const edgeLine = { id: `e1-${newNode.id}`, source: '1', target: `${newNode.id}`, animated: true, style: { stroke: 'green' }, labelBgStyle: { fill: 'green' }, type: 'default' }

        elements.value.push(newNode)
        elements.value.push(edgeLine)
      }
    })
  }).catch((error) => {
    console.log(error)
  })
}

const customNodes = ref([])

const elements = ref([
  { id: '1', type: 'custom', label: 'Node 1', position: { x: 250, y: 5 } },
  { id: 2, type: 'customAppt', label: 'Schedule Appt', position: { x: 10, y: 400 }, },
  { id: 3, type: 'customCall', label: 'Generate Call', position: { x: 450, y: 400 }, },
  { id: 4, type: 'customService', template: markRaw(ServiceNode), label: 'Answer Questions', position: { x: 850, y: 400 }, },
  { id: 5, type: 'customHandoff', label: 'Human Handoff', position: { x: 1300, y: 400 }, },
  { id: 'e1-3', source: '1', target: '3', animated: true, style: { stroke: 'green' }, labelBgStyle: { fill: 'green' }, type: 'default' },
  { id: 'e1-2', source: '1', target: '2', animated: true, style: { stroke: 'green' }, labelBgStyle: { fill: 'green' }, type: 'default' },
  { id: 'e1-4', source: '1', target: '4', animated: true, style: { stroke: 'green' }, labelBgStyle: { fill: 'green' }, type: 'default' },
  { id: 'e1-5', source: '1', target: '5', animated: true, style: { stroke: 'green' }, labelBgStyle: { fill: 'green' }, type: 'default' },
])

const edges = ref([

])

const { findNode, onConnect, addEdges, addNodes, project, vueFlowRef } = useVueFlow({
  nodes: elements.value
})


const props = defineProps({
  chatbotId: String,
  chatbotName: String,
  appointmentConfirmationMessage: String,
  eventTitle: String,
  eventDescription: String,
  calendarOptions: Array,
  selectedCalendars: Array,
  officeHoursCallsStart: Number,
  officeHoursCallsEnd: Number,
  outOfOfficeCallsMessage: String,
  officeHoursStart: Number,
  officeHoursEnd: Number,
  outOfOfficeMessage: String,
  agentNumber: String,
  callRecording: String,
  businessName: String,
  businessCity: String,
  businessState: String,
  serviceSystemPrompt: String,
  leadValues: Array,

})

const onDragStart = (event, nodeType) => {
  if (event.dataTransfer) {
    event.dataTransfer.setData('application/vueflow', nodeType)
    event.dataTransfer.effectAllowed = 'move'
  }
}


onConnect((params) => {
  console.log(`ON CONNECT: ${params}`)
  })

const onDrop = (event) => {
  let customNode = customNodes.value.find((node) => node.type == event.dataTransfer.getData('application/vueflow'))

  const { left, top } = vueFlowRef.value.getBoundingClientRect()

  const position = project({
    x: event.clientX - left,
    y: event.clientY - top,
  })

  let type = event.dataTransfer.getData('application/vueflow')
  const newNode = { ...customNode, id: elements.value.length + 1, position }
  const edgeLine = { id: `e1-${newNode.id}`, source: '1', target: `${newNode.id}`, animated: true, style: { stroke: 'green' }, labelBgStyle: { fill: 'green' }, type: 'default' }

  elements.value.push(newNode)
  elements.value.push(edgeLine)

  nextTick(() => {
    const node = findNode(newNode.id)
    const stop = watch(
      () => node?.dimensions,
      (dimensions) => {
        if (dimensions?.width > 0 && dimensions?.height > 0) {
          node.position = { x: node.position?.x - node.dimensions?.width / 2, y: node.position?.y - node.dimensions?.height / 2 }
          stop()
        }
      },
      { deep: true, flush: 'post' },
    )
  })
}

const onDragOver = (event) => {
  event.preventDefault()

  if (event.dataTransfer) {
    event.dataTransfer.dropEffect = 'move'
  }
}

const dynamicSlotName = (element) => {
  return `node-${element.type}`
}

const dynamicElements = computed(() => {
  return elements.value.filter(el => {
    return customNodes.value.some(n => n.type === el.type)
  })
})

const customNodeInUse = (customNode) => {
  return elements.value.some(element =>
    element.type === customNode.type
  )
}

const nodeClass = (node) => {
  if (customNodeInUse(node)) {
    return "block max-w-sm p-6 bg-gray-50 border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 cursor-not-allowed"
  } else {
    return "block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 cursor-move"
  }

}

const handleNodeDestroyed = (data) => {
  elements.value = elements.value.filter((el) => el.label !== data.name)
  customNodes.value = customNodes.value.filter((el) => el.label !== data.name)
}

const toggleTrigger = ref(false)

const toggleAll = () => {
  toggleTrigger.value = !toggleTrigger.value
}

/* Custom Intent Form */

const customIntentType = ref('')
const customIntentName = ref('')
const customIntentDescription = ref('')
const customIntentRequestUrl = ref('')
const customIntentRequestType = ref('get')
const customIntentHeaders = ref([{key: '', value: ''}])
const customIntentParameters = ref([{key: '', value: ''}])
const customIntentLoading = ref(false)
const fulfillmentResponse = ref('')
const topMenu = ref('')

const addHeader = () => {
  customIntentHeaders.value.push({ key: '', value: '' })
}

const addParameter = () => {
  customIntentParameters.value.push({ key: '', value: '' })
}

const deleteParameter = (index) => {
  customIntentParameters.value.splice(index, 1)
}

const deleteHeader = (index) => {
  customIntentHeaders.value.splice(index, 1)
}

const createCustomIntent = () => {
  axios.post(`/ajax/chatbots/${props.chatbotId}/custom_intents`, {
    intent_type: customIntentType.value,
    intent_name: customIntentName.value,
    intent_description: customIntentDescription.value,
    intent_request_url: customIntentRequestUrl.value,
    intent_request_type: customIntentRequestType.value,
    intent_headers: customIntentHeaders.value,
    intent_parameters: customIntentParameters.value,
    fulfillment_response: fulfillmentResponse.value,
  }).then((response) => {
    customNodes.value.push({
      label: response.data.customIntent.name,
      type: response.data.customIntent.customType,
      enabled: response.data.customIntent.enabled,
      customIntentId: response.data.customIntent.id,
      description: response.data.customIntent.description,
      intentType: response.data.customIntent.intentType,
      requestType: response.data.customIntent.requestType,
      url: response.data.customIntent.url,
      requestHeaders: response.data.customIntent.requestHeaders,
      requestParameters: response.data.customIntent.requestParameters,
      fulfillmentResponse: response.data.customIntent.fulfillmentResponse,

    })
    const targetEl = document.getElementById('intent-drawer')
    targetEl.classList.add('hidden')
    let backdrops = document.querySelectorAll('[modal-backdrop]')
    backdrops.forEach(function(backdrop) {
      backdrop.classList.add('hidden')
    })
    mdtoast.info('Local attributes updated.', { duration: 10000 })
  }).catch((error) => {
    console.log(error)
  })
}
</script>

<template>
  <div class='h-screen flex'>

    <div class="w-1/5 bg-gray-100 p-4">
      <div class='w-full text-center'>
        <h1 class="mb-4 text-xl font-extrabold text-gray-900 dark:text-white md:text-xl lg:text-xl"><span class="text-transparent bg-clip-text bg-gradient-to-r to-blue-600 from-sky-400">Custom</span> Intents.</h1>

      </div>
      <ul class='list-none'>
        <li class='text-center relative h-16 mb-4' v-for="customElement in customNodes" :key="customElement.type">
          <div :draggable="!customNodeInUse(customElement)" @dragstart="onDragStart($event, customElement.type)" :class="nodeClass(customElement)">
            <ul class='list-none'>
              <li class='inline-block'>
                  <!-- Placeholder for any content you might want to add -->
              </li>
              <li class='inline-block text-xs ml-4'>
                  {{ customElement.label }}
              </li>
              <li v-if="customNodeInUse(customElement)" class='absolute top-0 right-0 mt-1 mr-1'>
                  <span class="bg-red-100 text-red-800 w-3 h-3 block rounded-full dark:bg-red-900 dark:text-red-300"></span>
              </li>
              <li v-else class='absolute top-0 right-0 mt-1 mr-1'>
                  <span class="bg-green-100 text-green-800 w-3 h-3 block rounded-full dark:bg-green-900 dark:text-green-300"></span>
              </li>
            </ul>
          </div>
        </li>

        <div class="">
          <button class="overflow-y-auto text-white bg-blue-700 hover:bg-blue-800 mt-2 w-full focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" type="button" data-drawer-target="intent-drawer" data-drawer-show="intent-drawer" data-drawer-placement="top" aria-controls="intent-drawer">
            Create Intent
          </button>

          <button @click.prevent="toggleAll()" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
            <svg class="w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 1h4m0 0v4m0-4-5 5.243M5 15H1m0 0v-4m0 4 5.243-5"/>
          </svg>

          </button>
        </div>
      </ul>
    </div>

    <div class="w-4/5 p-4"  @drop="onDrop">
      <VueFlow v-model="elements"  @dragover="onDragOver" :default-viewport="{ zoom: 0.5 }"
    fit-view-on-init >

        <template v-for="element in dynamicElements" :key="element.id" v-slot:[dynamicSlotName(element)]="slotProps">
           <DynamicNode
              @nodeDestroyed="handleNodeDestroyed"
              :title="element.label"
              :name="element.label"
              :chatbotId="props.chatbotId"
              :enabled="element.enabled"
              :customIntentId="element.customIntentId"
              :description="element.description"
              :intentType="element.intentType"
              :requestType="element.requestType"
              :url="element.url"
              :requestHeaders="element.requestHeaders"
              :requestParameters="element.requestParameters"
              :fulfillmentResponse="element.fulfillmentResponse"
              :toggleTrigger="toggleTrigger"
            />
        </template>

        <template #node-customAppt="{ data }">
          <ApptNode
          :chatbotId="props.chatbotId"
          :appointmentConfirmationMessage="appointmentConfirmationMessage"
          :eventTitle="eventTitle"
          :eventDescription="eventDescription"
          :currentSelectedCalendars="selectedCalendars"
          :calendarOptions="calendarOptions"
          :toggleTrigger="toggleTrigger"
          />
        </template>

        <template #node-customCall="{ data }">
          <CallNode
            :chatbotId="props.chatbotId"
            :officeHoursCallsStart="officeHoursCallsStart"
            :officeHoursCallsEnd="officeHoursCallsEnd"
            :outOfOfficeCallsMessage="outOfOfficeCallsMessage"
            :agentNumber="agentNumber"
            :callRecording="callRecording"
            :toggleTrigger="toggleTrigger"
            />
        </template>

        <template #node-customHandoff="{ data }">
          <HumanHandoffNode
            :chatbotId="props.chatbotId"
            :officeHoursStart="officeHoursStart"
            :officeHoursEnd="officeHoursEnd"
            :outOfOfficeMessage="outOfOfficeMessage"
            :toggleTrigger="toggleTrigger"
            />
        </template>

        <template #node-customService="{ data }">
          <ServiceNode
            :chatbotId="props.chatbotId"
            :businessName="businessName"
            :businessCity="businessCity"
            :businessState="businessState"
            :serviceSystemPrompt="serviceSystemPrompt"
            :leadValues="leadValues"
            :toggleTrigger="toggleTrigger"
            />
        </template>

        <template #node-custom="{ data }">

            <div class="w-96 p-8 max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 w-96">
                <!-- <div class="flex justify-end px-4 pt-4">
                    <button id="dropdownButton" data-dropdown-toggle="dropdown" class="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5" type="button">
                        <span class="sr-only">Open dropdown</span>
                        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
                            <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"/>
                        </svg>
                    </button>
                    <div id="dropdown" class="z-10 hidden text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                        <ul class="py-2" aria-labelledby="dropdownButton">
                        <li>
                            <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Edit</a>
                        </li>
                        <li>
                            <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Export Data</a>
                        </li>
                        <li>
                            <a href="#" class="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Delete</a>
                        </li>
                        </ul>
                    </div>
                </div> -->
                <div class="flex flex-col items-center pb-10">
                    <img class="w-24 h-24 mb-3 rounded-full shadow-lg" :src="`/avatars/${props.chatbotId}.png`" alt="Bonnie image"/>
                    <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">{{props.chatbotName}}</h5>
                    <span class="text-sm text-gray-500 dark:text-gray-400">{{ props.businessName }}</span>

                </div>
            </div>

        </template>

        <div ref="topMenu" id="intent-drawer" class="max-h-screen overflow-y-scroll border-b border-gray-500 fixed top-0 left-0 right-0 z-40 w-full p-4 transition-transform -translate-y-full bg-white dark:bg-gray-800" tabindex="-1" aria-labelledby="drawer-top-label">

          <div class="w-1/3 mx-auto">
            <h5 id="drawer-top-label" class="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400">
              <svg class="w-4 h-4 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
            Add New Custom Intent
            </h5>
          </div>



          <button type="button" data-drawer-hide="intent-drawer" aria-controls="intent-drawer" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white" >
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
               <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
            <span class="sr-only">Close menu</span>
          </button>

          <form action="#" @submit.prevent="createCustomIntent()">
            <div class="w-1/3 mx-auto">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
            <input v-model="customIntentName" type="text" name="name" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"  required="">
            <small class='text-gray-400 float-left'>The name of the custom intent.</small>
          </div>

          <div class="w-1/3 mx-auto pt-8">
            <textarea v-model="customIntentDescription" id="custom_intent_description" rows="5" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Description of the custom intent like 'User is requesting information about insurance policy'."></textarea>
            <small class='text-gray-400'>Description of the custom intent.</small>
          </div>

          <div class="w-1/3 mx-auto py-4 my-4">
            <select  class="pt-4 mt-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" v-model="customIntentType" required>
              <option value='' selected>
                Select a Type
              </option>
              <option value='api_enriched'>
                API Enriched
              </option>
              <option value='knowledge_enriched'>
                Knowledge Enriched
              </option>
            </select>
            <small class='text-gray-400'>Choose API and Knowledge enriched intents.</small>
          </div>

          <div v-if="customIntentType == 'api_enriched'" class="w-1/3 mx-auto pb-4 mb-4 flex">
            <div class='w-1/3'>
              <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Method</label>
              <select  class="pt-4 mt-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 " v-model="customIntentRequestType" required>

              <option value='get'>
                GET
              </option>
              <option value='post'>
                POST
              </option>
              <option value='patch'>
                PATCH
              </option>
              <option value='delete_request'>
                DELETE
              </option>
              </select>
            <small class='text-gray-400'>HTTP method.</small>
            </div>
            <div class='w-2/3 pl-2'>
               <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">URL</label>
              <input v-model="customIntentRequestUrl" type="text" name="name" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"  required="">
              <small class='text-gray-400 float-left'>The URL of the API request.</small>
            </div>
          </div>

          <div v-if="customIntentType == 'knowledge_enriched'" class="w-1/3 mx-auto pb-4 mb-4">
            <div class="flex items-center p-4 mb-4 text-sm text-yellow-800 border border-yellow-300 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300 dark:border-yellow-800" role="alert">
              <svg class="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
              </svg>
              <span class="sr-only">Info</span>
              <div>
                <span class="font-medium">In Progress!</span> Check back soon.
              </div>
            </div>
          </div>

          <div v-if="customIntentType == 'api_enriched'" class="w-1/3 mx-auto pb-4 mb-4">
            <ul class="list-none">
              <li class="inline-block">
                <h6 class="text-lg dark:text-white">Request Headers</h6>
              </li>
              <li class="inline-block pl-2">
                <a href="#" @click.prevent="addHeader">
                  <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 5.757v8.486M5.757 10h8.486M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                  </svg>
                </a>
              </li>
            </ul>

            <div v-for="(value, index) in customIntentHeaders" :key="index" class="grid grid-cols-2 gap-4 mb-4 py-2">
              <div>
                <label :for="`header_key_${index}`" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Key</label>
                <input v-model="value.key" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" type="text" placeholder="Name">
                <small class='text-gray-400'>Header Key.</small>
              </div>
              <div>
                <label :for="`header_value_${index}`" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Value</label>
                <input v-model="value.value" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pt-2.5 px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" type="text" placeholder="Name">
                <small class='text-gray-400'>Header Value.</small>
              </div>
              <a href="#" @click.prevent="deleteHeader(index)" class="text-red-700 text-xs pt-0 mt-0">Delete</a>
            </div>

            <ul class="list-none">
              <li class="inline-block">
                <h6 class="text-lg dark:text-white">Request Parameters</h6>
              </li>
              <li class="inline-block pl-2">
                <a href="#" @click.prevent="addParameter">
                  <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 5.757v8.486M5.757 10h8.486M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                  </svg>
                </a>
              </li>
            </ul>

            <div v-for="(value, index) in customIntentParameters" :key="index" class="grid grid-cols-1 gap-4 mb-4 py-4">
              <div>
                <label :for="`parameter_key_${index}`" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Key</label>
                <input v-model="value.key" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" type="text" placeholder="Name">
                <small class='text-gray-400'>Parameter Key.</small>
              </div>

              <div>
                <label :for="`parameter_field_type_${index}`" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Field Type</label>
                <input v-model="value.fieldType" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" type="text" placeholder="string or boolean">
                <small class='text-gray-400'>Field Type.</small>
              </div>
              <div>
                <label :for="`parameter_description_${index}`" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                <textarea v-model="value.description" id="knowledge_prompt" rows="5" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Description of the parameter in natural language that the bot will extract from the user to use in the API request."></textarea>
                <small class='text-gray-400'>Parameter Value.</small>
              </div>
              <a href="#" @click.prevent="deleteParameter(index)" class="text-red-700 text-xs pt-0 mt-0">Delete</a>
            </div>
            <div class="w-full">
              <label for="fulfillment_response" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Fulfillment Response</label>
              <textarea v-model="fulfillmentResponse" id="fulfillment_response" rows="5" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Use {{response}} to inject the API response and use the prompt to tell Linda's AI how to use that data to respond to a user."></textarea>
              <small class='text-gray-400'>The fulfillment response to be used to respond to the customer with API enriched data.</small>
            </div>


            <button type="submit" class="py-4 my-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Create</button>
          </div>


        </form>

        </div>
      </VueFlow>
    </div>
  </div>
</template>

<style scoped>


</style>
