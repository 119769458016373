import { Controller } from '@hotwired/stimulus'
import axios from 'axios'

export default class extends Controller {
  static targets = [
    'form',
    'email',
    'errorLabel'
  ]

  static values = {
    email: { type: String, default: 'false' }
  }

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
    console.log('newsletter controller connected.')

    
  }

  disconnect() {
  }

  submit(evt) {
    evt.preventDefault()
    let email = this.emailTarget.value 
    axios.post(`/newsletter_subscribers`, { email: email })
      .then((response) => {
        window.location = `/newsletter_subscribers/${response.data.subscriberId}/confirmation`      
      }).catch((error) => {
        this.errorLabelTarget.classList.remove('hidden')
      })
  }
}
