<script setup>

import { ref, watch, onMounted, reactive, defineProps } from 'vue'
import axios from 'axios'
import qs from 'qs'
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'

onMounted(() => {

})

const props = defineProps({
  chatbotId: String,
  businessName: String,
  businessCity: String,
  businessState: String,
  serviceSystemPrompt: String,
  webhookUrl: String,
  leadValues: Array,
  toggleTrigger: Boolean
})

const localLeadValues = ref([])

onMounted(() => {
  localLeadValues.value = props.leadValues
})

watch(props, (newProps) => {
  if (newProps.toggleTrigger !== minimized.value) {
    minimized.value = !minimized.value
  }
})

const savingStates = reactive({
  businessName: false,
  businessCity: false,
  businessState: false,
  systemServicePrompt: false,
  webhookUrl: false,
  leadValues: false
})

const editModes = reactive({
  businessName: false,
  businessCity: false,
  businessState: false,
  serviceSystemPrompt: false,
  webhookUrl: false,
  leadValues: false
})

const state = reactive({
  businessName: props.businessName,
  businessCity: props.businessCity,
  businessState: props.businessState,
  serviceSystemPrompt: props.serviceSystemPrompt,
  webhookUrl: props.webhookUrl,
  leadValues: localLeadValues.value
})

const initialState = reactive({
  businessName: props.businessName,
  businessCity: props.businessCity,
  businessState: props.businessState,
  serviceSystemPrompt: props.serviceSystemPrompt,
  webhookUrl: props.webhookUrl,
  leadValues: localLeadValues.value
})

const toggleEditMode = (field) => {
  editModes[field] = !editModes[field]
  if (!editModes[field]) {
    state[field] = initialState[field]
  }
}

const removeLeadValue = (index) => {
  localLeadValues.value.splice(index, 1)
}

const addLeadValue = () => {
  localLeadValues.value.push({ name: '', type: '', description: '' })
}

const updateField = async (field, value) => {
  savingStates[field] = true

  let payloadValue = value;
  if (field === 'leadValues') {
    payloadValue = value.map((lv) => ({ name: lv.name, dataType: lv.dataType, description: lv.description }));
  }

  try {
    const response = await axios.post(`/ajax/chatbots/${props.chatbotId}/update_fields`, {
      field: field,
      value: payloadValue
    })

    if (response.status === 200) {
      initialState[field] = value
      editModes[field] = false
      mdtoast.success(`${field} updated.`, { timeout: 10000 })
    } else {
      console.error("Failed to update field:", response.data)
    }
  } catch (error) {
    console.error("Error updating field:", error)
  } finally {
    savingStates[field] = false
  }
}

const saveChanges = (field) => {
  if (field === 'leadValues') {
    updateField(field, localLeadValues.value);
  } else {
    updateField(field, state[field]);
  }
}

const minimized = ref(false)

const minimize = () => {
  minimized.value = !minimized.value
}

</script>

<template>
  <div class="w-96 max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
    <div class="absolute top-2 right-2 group z-10">
      <!-- Trigger -->
      <div class="cursor-pointer w-6 text-right relative">
        ...
        <div class="absolute inset-0 z-0 group-hover:block"></div>
      </div>

      <!-- Content -->
      <div class="absolute right-0 mt-0 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 hidden group-hover:block">
        <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <a href="#" @click.prevent="minimize()" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Toggle</a>
        </div>
      </div>
    </div>
      <div class="flex flex-col p-4">
          <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white pt-8 px-8 text-center">🦾 Service Intent</h5>
          <span class="text-sm text-gray-500 dark:text-gray-400 text-center">Answering Questions</span>
          <div class="text-center">
            <label class="relative inline-flex items-center cursor-pointer mt-4">
              <input type="checkbox" value="" class="sr-only peer" checked>
              <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">⚡️</span>
            </label>
          </div>

            <div class="p-3" v-if="!minimized">
              <label for="name" class="block mb-2 text-sm font-medium text-gray-400 dark:text-white">Business Name <a @click="toggleEditMode('businessName')" class="text-blue-500 cursor-pointer">Edit</a></label>
              <input v-if="editModes.businessName" v-model="state.businessName" type="text" name="business_name" id="business_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Business Name" required="">
              <p v-else>{{ state.businessName }}</p>
              <div v-if="!savingStates.businessName">
                <button  class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2" v-if="editModes.businessName" @click="saveChanges('businessName')">Save</button><br v-if="editModes.businessName">
              </div>
              <div v-if="savingStates.businessName">
                <button disabled  v-if="editModes.businessName" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2">
                  <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
                  </svg>
                  Saving...
                </button>
              </div>
              <small class='text-gray-400'>The name of the business the Chatbot is representing.</small>
            </div>

            <div class="p-3" v-if="!minimized">
              <label for="name" class="block mb-2 text-sm font-medium text-gray-400 dark:text-white">Business City <a @click="toggleEditMode('businessCity')" class="text-blue-500 cursor-pointer">Edit</a></label>
              <input v-if="editModes.businessCity" v-model="state.businessCity" type="text" name="business_city" id="business_city" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Business City" required="">
              <p v-else>{{ state.businessCity }}</p>
              <div v-if="!savingStates.businessCity">
                <button  class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2" v-if="editModes.businessCity" @click="saveChanges('businessCity')">Save</button><br v-if="editModes.businessCity">
              </div>
              <div v-if="savingStates.businessCity">
                <button disabled  v-if="editModes.businessCity" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2">
                  <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
                  </svg>
                  Saving...
                </button>
              </div>
              <small class='text-gray-400'>The city of the business the Chatbot is representing.</small>
            </div>

            <div class="p-3" v-if="!minimized">
              <label for="name" class="block mb-2 text-sm font-medium text-gray-400 dark:text-white">Business State <a @click="toggleEditMode('businessState')" class="text-blue-500 cursor-pointer">Edit</a></label>
              <input v-if="editModes.businessState" v-model="state.businessState" type="text" name="business_state" id="business_state" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Business State" required="">
              <p v-else>{{ state.businessState }}</p>
              <div v-if="!savingStates.businessState">
                <button  class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2" v-if="editModes.businessState" @click="saveChanges('businessState')">Save</button><br v-if="editModes.businessState">
              </div>
              <div v-if="savingStates.businessState">
                <button disabled  v-if="editModes.businessState" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2">
                  <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
                  </svg>
                  Saving...
                </button>
              </div>
              <small class='text-gray-400'>The city of the business the Chatbot is representing.</small>
            </div>

            <div class="p-3" v-if="!minimized">
              <label for="business_state" class="block mb-2 text-sm font-medium text-gray-400 dark:text-white">Prompt <a @click="toggleEditMode('serviceSystemPrompt')" class="text-blue-500 cursor-pointer">Edit</a></label>
              <textarea v-if="editModes.serviceSystemPrompt" v-model="state.serviceSystemPrompt" rows="6" type="textarea" name="business_state" id="business_state" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Prompt" required=""></textarea>
              <p v-else>{{ state.serviceSystemPrompt }}</p>
              <div v-if="!savingStates.serviceSystemPrompt">
                <button  class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2" v-if="editModes.serviceSystemPrompt" @click="saveChanges('serviceSystemPrompt')">Save</button><br v-if="editModes.serviceSystemPrompt">
              </div>
              <div v-if="savingStates.serviceSystemPrompt">
                <button disabled  v-if="editModes.serviceSystemPrompt" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2">
                  <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
                  </svg>
                  Saving...
                </button>
              </div>
              <small class='text-gray-400'>The city of the business the Chatbot is representing.</small>
            </div>

            <div class="p-3" v-if="!minimized">
                <label for="name" class="block mb-2 pt-4 text-sm font-medium text-gray-400 dark:text-white">Webhook Url <a @click="toggleEditMode('webhookUrl')" class="text-blue-500 cursor-pointer">Edit</a></label>
                <input v-if="editModes.webhookUrl" v-model="state.webhookUrl" type="text" name="webhook_url" id="webhook_url" class="mb-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="https://webhook-url.com/3323-121321-123123-12312" required="">
                <p v-else>{{ state.webhookUrl }}</p>
                <div v-if="!savingStates.webhookUrl">
                  <button  class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2" v-if="editModes.webhookUrl" @click="saveChanges('webhookUrl')">Save</button><br v-if="editModes.webhookUrl">
                </div>
                <div v-if="savingStates.webhookUrl">
                  <button disabled  v-if="editModes.webhookUrl" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2">
                    <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
                    </svg>
                    Saving...
                  </button>
                </div>
                <small class='text-gray-400'>After lead qualifiers are extracted, Linda will webhook all data to this URL.</small>
            </div>

            <div class="p-3" v-if="!minimized">
              <label for="business_state" class="block mb-2 text-sm font-medium text-gray-400 dark:text-white">Lead Qualification <a @click="toggleEditMode('leadValues')" class="text-blue-500 cursor-pointer">Edit</a></label>

              <div v-if="editModes.leadValues">
                <transition-group name="list">
                  <div v-for="(value, index) in localLeadValues" :key="index" class="grid grid-cols-1 gap-4 mb-4 py-4">
                    <div>
                      <label :for="`lead_value_${index}`" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Lead Qualifier</label>
                      <input v-model="value.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" type="text" placeholder="Name">
                      <small class='text-gray-400'>The name of the Lead Qualifier to extract.</small>
                    </div>
                    <div>
                      <label :for="`lead_option_${index}`" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Qualification Type</label>
                      <select v-model="value.dataType" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option disabled value="">Select type</option>
                        <option value="string">String</option>
                        <option value="boolean">Boolean</option>
                      </select>
                      <small class='text-gray-400'>The answer type.</small>
                    </div>
                    <div>
                      <label :for="`lead_description_${index}`" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Qualifier Description</label>
                      <textarea v-model="value.description" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Description"></textarea>
                      <small class='text-gray-400'>A description of the lead qualifier for Linda Bot to extract from the conversation.</small>
                    </div>
                    <button @click.prevent="removeLeadValue(index)" class="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs w-1/6 px-5 py-2.5 text-center mr-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">
                      <svg class="w-4 h-4 my-0 mx-auto" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"/>
                      </svg>
                    </button>
                  </div>

                  <button @click.prevent="addLeadValue" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2">
                    Add Lead Qualifier
                  </button>
                <div>
                  <div v-if="!savingStates.leadValues">
                <button  class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2" v-if="editModes.leadValues" @click="saveChanges('leadValues')">Save</button><br v-if="editModes.leadValues">
              </div>
              <div v-if="savingStates.leadValues">
                <button disabled  v-if="editModes.leadValues" type="button" class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 my-2">
                  <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
                  </svg>
                  Saving...
                </button>
              </div>
                </div>
              </transition-group>
            </div>
            <div v-else>
              <div class="p-4">
                <dl class="space-y-4">
                    <template v-for="lead in localLeadValues" :key="lead.name">
                        <dt class="text-sm font-medium text-gray-900 dark:text-white">
                            {{ lead.name }}
                        </dt>
                        <dd class="ml-2 text-sm text-gray-500 dark:text-gray-400">
                            <strong>Data Type:</strong> {{ lead.dataType }}<br>
                            <strong>Description:</strong> {{ lead.description }}
                        </dd>
                    </template>
                </dl>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<style scoped>


</style>
