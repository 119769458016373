import { Controller } from '@hotwired/stimulus'
import '@dmuy/toast/dist/mdtoast.css'
import axios from "axios"
import mdtoast from '@dmuy/toast'

export default class extends Controller {
  static targets = ["button"]

  enroll() {
    this.buttonTarget.disabled = true;
    this.buttonTarget.innerHTML = 'Loading...'

    axios.post('/ajax/night_school_enrollment')
      .then(response => {

        this.displayFlashMessage("success", response.data.message)
        this.updateButtonToLink()
      })
      .catch(error => {
        const message = error.response && error.response.data.error ? error.response.data.error : 'Unknown error occurred'
        this.displayFlashMessage("error", message)
      })
      .finally(() => {
        if (!this.buttonTarget.href) {
          this.buttonTarget.disabled = false
          this.buttonTarget.innerHTML = 'Enroll'
        }
      })
  }

  displayFlashMessage(type, message) {
    if (type === "success") {
      mdtoast.success(message, { duration: 10000 })
    } else if (type === "error") {
      mdtoast.error(message, { duration: 10000 })
    }
  }

  updateButtonToLink() {
    this.buttonTarget.outerHTML = `<a href="https://school.linda.co/home" target="_blank" class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Go to Night School</a>`
  }
}