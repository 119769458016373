import Rails from '@rails/ujs'

import { initFlowbite } from 'flowbite'

import * as ActiveStorage from '@rails/activestorage'
import LocalTime from 'local-time'

import '~/init/axios.js'
import '~/init/password_visibility_toggle.js'

import '@rails/actiontext'
import '~/channels/index.js'
import '~/controllers/index.js'

// Jumpstart Pro & other Functionality
// import.meta.globEager('~/vendor/**/*.js')
import '~/vendor/jumpstart/turbo_native/bridge.js'
import '~/vendor/jumpstart/actiontext.js'
import '~/vendor/jumpstart/confirm.js'
import '~/vendor/jumpstart/direct_uploads.js'
import '~/vendor/jumpstart/lazysrc.js'

window.Rails = Rails

window.document.addEventListener('DOMContentLoaded', () => {
  initFlowbite()
})

ActiveStorage.start()
LocalTime.start()
Rails.start()
