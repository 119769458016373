import { Controller } from '@hotwired/stimulus'
import axios from 'axios'
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'


export default class extends Controller {
  static targets = [
    'input',
    'submit'
  ]

  static values = {
    organizationId: String
  }


  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
    console.log('location api key controller connected.')

  }

  disconnect() {
  }

  update(evt) {
    evt.preventDefault()

    axios.post(`/ajax/organizations/${this.organizationIdValue}/update_lv_api_key`, { api_key: this.inputTarget.value })
      .then((response) => {

        mdtoast.info('Added API Key successfully.', { duration: 10000 })
      }).catch((error) => {
        mdtoast.error(error, { duration: 10000 })
      })
  }
}
