<script setup>

import { ref, watch, onMounted, defineProps } from 'vue'
import axios from 'axios'
import VueSlimTables from 'vue-slim-tables'
import 'vue-slim-tables/stylesheets';
import qs from 'qs'

const props = defineProps({
  inboxId: String
})

onMounted(() => {
 getConversationStats()
})

// const locationInfo = ref({})
const infoLoading = ref(true)
const conversationStats = ref({})
const search = ref('')

const getConversationStats = () => {
  axios.get(`/ajax/inboxes/${props.inboxId}/stats`).then((response) => {
    conversationStats.value = response.data
    infoLoading.value = false
  }).catch((error) => {
    console.log(error)
  })
}

const columns = [
  { title: '', key: 'id' },
  { title: 'started', key: 'initiatedAt' },
  { title: 'status', key: 'status' },
  { title: 'sender', key: 'sender' },
  { title: 'email', key: 'email' },
  { title: 'phone', key: 'phoneNumber' },
  

  //{ title: 'Messages', key: 'messages' },
  // { title: 'Site', key: 'site' },
  // { title: 'Caller', key: 'caller' },
  // { title: 'Duration', key: 'duration' },
  // { title: 'Message', key: 'message' },
  // { title: 'Created', key: 'remoteCreatedAt' },
  
]
const tableSource = (params) => {
  return axios.get(
    `/ajax/inboxes/${props.inboxId}/conversations`,
    {
      params: { ...params, query: search.value },
      paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
    }
  ).then((res) => res.data)
}
</script>

<template>
  <div class="grid grid-cols-2 gap-1 py-6">
    <div class="p-8">
      <h2 class='text-center'>Conversations</h2>
      <div v-if="infoLoading">
        <div role="status" class="space-y-2.5 py-4 animate-pulse max-w-lg">
          <div class="flex items-center w-full space-x-2">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[400px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[480px]">
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[440px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-32"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
          </div>
          <div class="flex items-center w-full space-x-2 max-w-[360px]">
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
              <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-80"></div>
              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-full"></div>
          </div>
          <span class="sr-only">Loading...</span>
      </div>
      </div>
      <div v-else>
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3">
                        Event
                    </th>
                    <th scope="col" class="px-6 py-3">
                         7d
                    </th>
                    <th scope="col" class="px-6 py-3">
                         30d
                    </th>
                    <th scope="col" class="px-6 py-3">
                         90d
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white">
                        Opened
                    </th>
                    <td class="px-6 py-4  font-bold">
                      {{ conversationStats['openConversationsLastWeek'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                      {{ conversationStats['openConversationsLastMonth'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                      {{ conversationStats['openConversationsLastQuarter'] }}
                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white">
                        Resolved
                    </th>
                    <td class="px-6 py-4  font-bold">
                      {{ conversationStats['closedConversationsLastWeek'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                      {{ conversationStats['closedConversationsLastMonth'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                      {{ conversationStats['closedConversationsLastQuarter'] }}
                    </td>
                </tr>

                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white">
                        Pending
                    </th>
                    <td class="px-6 py-4  font-bold">
                      {{ conversationStats['pendingConversationsLastWeek'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                      {{ conversationStats['pendingConversationsLastMonth'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                      {{ conversationStats['pendingConversationsLastQuarter'] }}
                    </td>
                </tr>

                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" class="px-6 py-4 font-medium  text-gray-900 whitespace-nowrap dark:text-white">
                        Snoozed
                    </th>
                    <td class="px-6 py-4  font-bold">
                      {{ conversationStats['snoozedConversationsLastWeek'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                      {{ conversationStats['snoozedConversationsLastMonth'] }}
                    </td>
                    <td class="px-6 py-4  font-bold">
                      {{ conversationStats['snoozedConversationsLastQuarter'] }}
                    </td>
                </tr>
                
            </tbody>
        </table>
      </div>

      

    </div>
  </div>
  <div class="grid grid-cols-1 gap-1 py-6">
    <div>
        <VueSlimTables
        ref="table"
        :source="tableSource"
        :columns="columns"
        class="table px-8 py-2"
        :per-page="10">

        <template #cell:status="{ row, value }">
          <div v-if="value === 'open'">
            <span class="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">Open</span>

          </div>
          
          <div v-if="value === 'resolved'">
            <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Resolved</span>

          </div>

          <div v-if="value === 'pending'">
            <span class="bg-pink-100 text-pink-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300">Pending</span>

          </div>

          <div v-if="value === 'snoozed'">
            <span class="bg-indigo-100 text-indigo-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300">Snoozed</span>

          </div>
        </template>

        <template #cell:id="{ row, value }">
          <a :href="`/conversations/${value}`">View</a>
        </template>
      </VueSlimTables>
      </div>
  </div>
  
</template>
