import { Controller } from '@hotwired/stimulus'


const LINDA_LOCAL_MONTHLY_PRICE = '$199'
const LINDA_LOCAL_YEARLY_PRICE = '$1,920'
const MANAGED_LINDA_LOCAL_MONTHLY_PRICE = '$349'
const MANAGED_LINDA_LOCAL_YEARLY_PRICE = '$3,350'

const INACTIVE_BTN_CLASSES = "relative ml-0.5 whitespace-nowrap rounded-md border border-transparent py-2 px-6 text-sm font-medium text-blue-200 hover:bg-blue-800 focus:z-10 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-700"
const ACTIVE_BTN_CLASSES = "relative whitespace-nowrap rounded-md border-blue-700 bg-white py-2 px-6 text-sm font-medium text-blue-700 shadow-sm hover:bg-blue-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-700"
const ACTIVE_INTERVAL_BTN_CLASSES   = "relative whitespace-nowrap rounded-md border-blue-700 bg-white py-2 px-6 text-sm font-medium text-blue-700 shadow-sm hover:bg-blue-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-700"
const INACTIVE_INTERVAL_BTN_CLASSES ="relative ml-0.5 whitespace-nowrap rounded-md border border-transparent py-2 px-6 text-sm font-medium text-blue-200 hover:bg-blue-800 focus:z-10 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-700"
const YEARLY_DFY_ADDITIONAL_LABEL = 'Additional Location $4,999'
const MONTHLY_DFY_ADDITIONAL_LABEL = 'Additional Location $499'
const YEARLY_DIY_ADDITIONAL_LABEL = 'Additional Location $1,499'
const MONTHLY_DIY_ADDITIONAL_LABEL = 'Additional Location $249'

const PRICES = {
  'monthly': {
    'linda_local': LINDA_LOCAL_MONTHLY_PRICE,
    'managed_linda_local': MANAGED_LINDA_LOCAL_MONTHLY_PRICE,
  },
  'yearly': {
    'linda_local': LINDA_LOCAL_YEARLY_PRICE,
    'managed_linda_local': MANAGED_LINDA_LOCAL_YEARLY_PRICE,
  }
}

export default class extends Controller {
  static targets = [
    'localBtn',
    'localPricingLabel',
    'monthlyAdditional',
    'monthlyAutomatePrice',
    'monthlyBtn',
    'monthlyLocalPrice',
    'monthlyTotalPrice',
    'setupLabel',
    'intervalLabel',
    'managedBtn',
    'managedPricingLabel',
    'yearlyAdditional',
    'yearlyBtn',
    'yearlyLocalPrice',
    'yearlyPrice',
    'yearlyTotalPrice',
  ]

  static values = {
    productInterval: { type: String, default: 'monthly' }
  }

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
  }

  disconnect() {
    this.vue?.unmount()
  }

  updateSignUpUrls() {
    this.managedBtnTarget.href = `/clients/sign_up?selected_plan=managed_linda_local&plan_interval=${this.productIntervalValue}`
    this.localBtnTarget.href = `/clients/sign_up?selected_plan=linda_local&plan_interval=${this.productIntervalValue}`
  }

  updatePricingLabels() {
    this.localPricingLabelTargets.forEach(t => { t.innerText = PRICES[this.productIntervalValue]['linda_local'] })
    this.managedPricingLabelTargets.forEach(t => { t.innerText = PRICES[this.productIntervalValue]['managed_linda_local'] })
  }


  monthlySelect() {
    this.productIntervalValue = 'monthly'

    // update pricing labels
    this.updatePricingLabels()

    this.updateSignUpUrls()

    // shuffle button classes
    this.monthlyBtnTarget.classList = ''
    this.monthlyBtnTarget.classList = ACTIVE_INTERVAL_BTN_CLASSES
    this.yearlyBtnTarget.classList = ''
    this.yearlyBtnTarget.classList = INACTIVE_INTERVAL_BTN_CLASSES

    // update interval label
    this.intervalLabelTargets.forEach(label => { label.textContent = 'monthly' })
  }

  yearlySelect() {
    this.productIntervalValue = 'yearly'

    // update pricing labels
    this.updatePricingLabels()

    this.updateSignUpUrls()

    // shuffle button classes
    this.yearlyBtnTarget.classList = ''
    this.yearlyBtnTarget.classList = ACTIVE_INTERVAL_BTN_CLASSES
    this.monthlyBtnTarget.classList = ''
    this.monthlyBtnTarget.classList = INACTIVE_INTERVAL_BTN_CLASSES
    // update interval label
    this.intervalLabelTargets.forEach(label => { label.textContent = 'yearly' })
  }
}
