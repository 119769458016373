<template>
  <div>

    <div class="stats mt-2">
      <dl class="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-3">
        <div class="relative flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
          <!-- Spinner overlay -->
          <div v-if="dataLoading" class="absolute inset-0 flex items-center justify-center">
            <div role="status" class="-translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 transform">
                <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span class="sr-only">Loading...</span>
            </div>
          </div>

         <div v-if="!dataLoading" class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
            <dt class="text-sm font-medium leading-6 text-gray-500">Conversations</dt>
            <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">{{ eventStats['conversations'] }}</dd>
        </div>
      </div>
      <div class="relative flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
          <!-- Spinner overlay -->
          <div v-if="dataLoading" class="absolute inset-0 flex items-center justify-center">
            <div role="status" class="-translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 transform">
                <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span class="sr-only">Loading...</span>
            </div>
          </div>

         <div v-if="!dataLoading" class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
            <dt class="text-sm font-medium leading-6 text-gray-500">Events</dt>
            <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">{{ eventStats['events'] }}</dd>
        </div>
      </div>
      <div class="relative flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
          <!-- Spinner overlay -->
          <div v-if="dataLoading" class="absolute inset-0 flex items-center justify-center">
            <div role="status" class="-translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 transform">
                <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span class="sr-only">Loading...</span>
            </div>
          </div>

         <div v-if="!dataLoading" class="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
            <dt class="text-sm font-medium leading-6 text-gray-500">Qualifications</dt>
            <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">{{ eventStats['botLeads'] }} </dd>
        </div>
      </div>


    </dl>
    </div>
    <div class='text-center'>
          <h5 class="inline-flex items-center py-4 mb-4 text-base font-semibold text-gray-500 dark:text-gray-400">
    <svg class="w-4 h-4 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 18">
      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 12v5m5-9v9m5-5v5m5-9v9M1 7l5-6 5 6 5-6"/>
    </svg>
    Conversation Insights</h5>

    </div>

    <div class="relative w-full h-[250px] mb-8">
      <canvas ref="chartCanvas" class="absolute inset-0 my-0 mx-auto w-full"></canvas>
    </div>

    <div class='text-center'>
      <h5 class="inline-flex items-center py-4  text-base font-semibold text-gray-500 dark:text-gray-400">
        <svg class="w-4 h-4 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 18">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 12v5m5-9v9m5-5v5m5-9v9M1 7l5-6 5 6 5-6"/>
        </svg>
        ACAS Insights
      </h5>
      <p class="mb-3 text-gray-500 dark:text-gray-400 mb-4 text-xs">The Autonomous Conversation Acceptance Score measures how much friction consumers face conversing with your bot towards their goal.</p>
    </div>
    <div class="relative w-full h-[250px]">
      <canvas ref="acasChartCanvas" class="absolute inset-0 my-0 mx-auto w-full"></canvas>
    </div>

  </div>
</template>

<script setup>
import { ref, onMounted, watchEffect, defineProps } from 'vue';
import axios from 'axios';
import '@dmuy/toast/dist/mdtoast.css';
import mdtoast from '@dmuy/toast';
import { Chart, BarController, CategoryScale, Title } from 'chart.js';

Chart.register(BarController, CategoryScale, Title);

const chartData = ref(null);
const eventStats = ref({});
const chartCanvas = ref(null);

const props = defineProps({
  chatbotId: String
});

let myChart = null;
let dataLoading = ref(true)

const acasChartData = ref()
const acasChartCanvas = ref(null);
let myAcasChart = null;

onMounted(async () => {
  try {
    const insightsResponse = await axios.get(`/ajax/chatbots/${props.chatbotId}/stats/conversation_insights`)
    chartData.value = insightsResponse.data;
    setupConversationChart()

    const eventInsights = await axios.get(`/ajax/chatbots/${props.chatbotId}/stats/event_insights`)
    eventStats.value = eventInsights.data

    const acasInsights = await axios.get(`/ajax/chatbots/${props.chatbotId}/stats/acas_insights`)
    acasChartData.value = acasInsights.data;
    setupAcasChart()

    dataLoading.value = false
  } catch (error) {
    console.error(error);
    mdtoast('Failed to fetch data', { type: mdtoast.ERROR, interaction: true, interactionTimeout: 5000, actionText: 'OK' });
  }

});

const setupConversationChart = () => {
  const labels = chartData.value.insights.labels;
  const dataValues = chartData.value.insights.data;

  const ctx = chartCanvas.value.getContext('2d');
  if(myChart) myChart.destroy(); // Destroy any previous instance of the chart
  myChart = new Chart(ctx, {
    type: 'bar',
    data: {
      labels: labels,
      datasets: [{
        label: 'Conversations',
        data: dataValues,
        backgroundColor: 'rgba(26, 86, 219, 0.2)',
        borderColor: 'rgba(26, 86, 219, 1)',
        borderWidth: 1
      }]
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 1,
            suggestedMax: 50
          }
        }
      }
    }
  });
}

const setupAcasChart = () => {
  const labels = acasChartData.value.insights.labels
  const dataValues = acasChartData.value.insights.data

  const ctx = acasChartCanvas.value.getContext('2d')

  if(myAcasChart) myAcasChart.destroy(); // Destroy any previous instance of the chart
  myAcasChart = new Chart(ctx, {
    type: 'line',
    data: {
      labels: labels,
      datasets: [{
        label: 'Daily Average ACAS',
        data: dataValues,
        backgroundColor: 'rgba(26, 86, 219, 0.2)',
        borderColor: 'rgba(26, 86, 219, 1)',
        borderWidth: 1
      }]
    },
    options: {
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 1,
            suggestedMax: 50
          }
        }
      }
    }
  });
}

// Watch the chart data and re-setup the chart if it changes
watchEffect(() => {
  if(chartData.value) setupConversationChart();
})

watchEffect(() => {
  if(acasChartData.value) setupAcasChart();
})

</script>