<script setup>

import { ref, watch, reactive, computed, onMounted, defineProps } from 'vue'
import axios from 'axios'
import qs from 'qs'
import { token } from '@formkit/utils'
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'
import vue3Datepicker from 'vue3-datepicker';
import consumer from "../channels/consumer"
import VueMultiselect from 'vue-multiselect'

onMounted(() => {
  getCalendars()
  getTimeZones()
  getInboxes()

  consumer.subscriptions.create({ channel: `BotCreationChannel`, client: props.clientId }, {
    received(data) {

      const stepIndex = steps.value.findIndex(step => step.id === data.id)
      if (stepIndex !== -1) {
        steps.value[stepIndex].state = data.state
      }
    }
  })
})

const selectedInbox = ref([])
const inboxes = ref([])
const getInboxes = () => {
  axios.get(`/ajax/organizations/${props.organizationId}/inboxes`).then((response) => {

    inboxes.value = response.data.result
  }).catch((error) => {
    console.log(error)
  })
}

const steps = ref([
  { id: 'pre_processed', name: 'Pre-processed inputs', state: 'waiting' },
  { id: 'created_container', name: 'Created Agent container', state: 'waiting' },
  { id: 'initiated_agent', name: 'Initiated Agent', state: 'waiting' },
  { id: 'prepared_environment', name: 'Preparing Agent environment', state: 'waiting' },
  { id: 'provision_demo', name: 'Provision demo instance', state: 'waiting' },
  { id: 'generate_avatar', name: 'Generate AI Avatar', state: 'waiting' },
  { id: 'init_inbox', name: 'Initialize Agent inside Inbox', state: 'waiting' },
  { id: 'enrich_agent', name: 'Enrich Agent with custom data types', state: 'waiting' },
  { id: 'setup_actions', name: 'Setup Agent actions', state: 'waiting' },
  { id: 'provisioned_agent', name: 'Provisioned Agent', state: 'waiting' }
])

const props = defineProps({
  organizationId: String,
  clientId: String,
  ownApiKey: Boolean
})

const calendars = ref([])
const loadingCalendars = ref(true)
const selectedCalendars = ref([])

const apiKey = ref('')

const phoneRequired = ref(false)
const emailRequired = ref(false)
const companyRequired = ref(false)

const currentPromotions = ref('')

const chatbotType = ref('lead_qualification')

const timeZones = ref([])
const selectedTimeZone = ref('')
// TODO: implement business location support for full platform users
// const businessLocations = ref([])
// const loadingBusinessLocations = ref(true)
// const selectedBusinessLocation = ref()

// checkbox states
const apptChecked = ref(false)
const phoneChecked = ref(false)
const serviceChecked = ref(false)
const officeChecked = ref(false)

const slackEventNotifications = ref(false)
const slackMessageNotifications = ref(false)

const appointConfirmation = ref('')
const eventDescription = ref('')
const eventTitle = ref('')

const officeHoursStart = ref(0)
const officeHoursEnd = ref(23)
const officeHoursCallsStart = ref(0)
const officeHoursCallsEnd = ref(23)

const outOfOfficeMessage = ref('')
const outOfOfficeCallsMessage = ref('')

const serviceSystemPrompt = ref('')
const webhookUrl = ref('')

const callRecording = ref()
const phoneNumber = ref('')

const chatbotName = ref('')
const description = ref('')
const businessName = ref('')
const businessCity = ref('')
const businessState = ref('')
const gptModel = ref('')

const slackWebhookUrl = ref('')

const formLoading = ref(false)

const businessScheduleDescription = ref('')
const holidayScheduleDescription = ref('')
const daysOff = reactive([])

const serviceAreaDescription = ref('')
const servicesOfferedDescription = ref('')
const pricingDescription = ref('')

const getTimeZones = () => {
  axios.get(`/ajax/time_zones`).then((response) => {
    timeZones.value = response.data
  }).catch((error) => {
    console.log(error)
  })
}

const getCalendars = () => {
  axios.get(`/ajax/organizations/${props.organizationId}/google_calendars`).then((response) => {

    loadingCalendars.value = false
    calendars.value = response.data.results
  }).catch((error) => {
    console.log(error)
  })
}

const leadValues = ref([{ name: '', type: '', description: '' }])

const addLeadValue = () => {
  leadValues.value.push({ name: '', type: '', description: '' })
}

const removeLeadValue = (index) => {
  leadValues.value.splice(index, 1)
}

const addDayOff = () => {
    daysOff.push({date: new Date()});
};

const removeDayOff = (index) => {
    daysOff.splice(index, 1);
};

const submitted = ref(false)
const submitHandler = async () => {
  if (selectedInbox.value.length == 0) {
    mdtoast.error('Please select an inbox.', { duration: 10000 })
    return false
  }

  if (gptModel.value == '') {
    mdtoast.error('Please select a GPT Model.', { duration: 10000 })
    return false
  }

  if (selectedTimeZone.value == '') {
    mdtoast.error('Please select a Time Zone.', { duration: 10000 })
    return false
  }
  formLoading.value = true
  axios.post('/ajax/chatbots', {
    chatbot_name: chatbotName.value,
    description: description.value,
    business_name: businessName.value,
    business_city: businessCity.value,
    business_state: businessState.value,
    appointment_intent: apptChecked.value,
    appointment_confirmation_message: appointConfirmation.value,
    phone_intent: phoneChecked.value,
    service_intent: serviceChecked.value,
    calendar_ids: selectedCalendars.value,
    time_zone: selectedTimeZone.value,
    office_intent: officeChecked.value,
    office_hours_start: officeHoursStart.value,
    office_hours_end: officeHoursEnd.value,
    office_hours_calls_start: officeHoursCallsStart.value,
    office_hours_calls_end: officeHoursCallsEnd.value,
    out_of_office_message: outOfOfficeMessage.value,
    out_of_office_calls_message: outOfOfficeCallsMessage.value,
    call_recording: callRecording.value,
    agent_number: phoneNumber.value,
    service_system_prompt: serviceSystemPrompt.value,
    lead_values: leadValues.value,
    gpt_model: gptModel.value,
    event_description: eventDescription.value,
    event_title: eventTitle.value,
    email_required: emailRequired.value,
    company_required: companyRequired.value,
    phone_required: phoneRequired.value,
    slack_webhook_url: slackWebhookUrl.value,
    slack_event_notifications: slackEventNotifications.value,
    slack_message_notifications: slackMessageNotifications.value,
    days_off: daysOff,
    business_schedule_description: businessScheduleDescription.value,
    holiday_schedule_description: holidayScheduleDescription.value,
    chatbot_type: chatbotType.value,
    current_promotions: currentPromotions.value,
    services_offered_description: servicesOfferedDescription.value,
    service_area_description: serviceAreaDescription.value,
    pricing_description: pricingDescription.value,
    selected_inbox: selectedInbox.value,
    api_key: apiKey.value
  }).then((response) => {
    mdtoast.info('Chatbot Provisioned.', { duration: 10000 })
    window.location = `/clients/dashboard/chats/chatbots/${response.data.id}`
    formLoading.value = false
  }).catch((error) => {
    console.log(error)
  })
}




let currentStep = ref(0);
let botSteps = ['Main Information', 'Settings', 'Information', 'Hours', 'Actions', 'Confirmation'];

const stepClass = (index) => {
  if (index < currentStep.value) {
    return 'w-full p-4 text-green-700 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:border-green-800 dark:text-green-400';
  } else if (index === currentStep.value) {
    return 'w-full p-4 text-blue-700 bg-blue-100 border border-blue-300 rounded-lg dark:bg-gray-800 dark:border-blue-800 dark:text-blue-400';
  } else {
    return 'w-full p-4 text-gray-900 bg-white border border-gray-300 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400';
  }
}

const next = () => {
  if (currentStep.value < botSteps.length - 1) {
    currentStep.value += 1;
  }
}

const prev = () => {
  if (currentStep.value > 0) {
    currentStep.value -= 1;
  }
}

const chatbotTypeName = (id) => {
  if (id == 0) {
    return 'Hybrid'
  } else if (id == 1) {
    return 'Knowledge Enriched'
  } else if (id == 2) {
    return 'Lead Qualification'
  }
}

const requiredContacts = () => {
  return { phoneRequired: phoneRequired.value, emailRequired: emailRequired.value, companyRequired: companyRequired.value }
}

const slackSettings = () => {
  return { url: slackWebhookUrl.value, messageNotifications: slackMessageNotifications.value, eventNotifications: slackEventNotifications.value }
}

const botActions = () => {
  return { appointment: apptChecked.value, phone: phoneChecked.value, humanHandoff: officeChecked.value }
}

const setCurrentNav = (index) => {
  currentStep.value = index
}
</script>

<template>
  <div v-if="formLoading" class='text-center'>
    <h2 class="mb-2 text-lg font-semibold text-gray-900 dark:text-white"></h2>
      <ul class="max-w-md space-y-2 text-gray-500 list-inside dark:text-gray-400">
        <li v-for="(step, index) in steps" :key="index" class="flex items-center">
          <svg v-if="step.state === 'completed'" class="w-4 h-4 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
          </svg>
          <div v-else-if="step.state === 'waiting'" role="status">
                        <svg aria-hidden="true" class="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>

            <span class="sr-only">Loading...</span>
          </div>
          <div v-else-if="step.state === 'error'" class="text-red-500">
            Error
          </div>
          {{ step.name }}
        </li>
      </ul>
  </div>
  <div v-else>
    <div class="flex">
      <div class="w-1/4">
        <!-- Steps go here -->
         <ol class="space-y-2 w-72 list-none">
          <template v-for="(step, index) in botSteps" :key="index">
            <li>
              <div :class="stepClass(index)">
                <div class="flex items-center justify-between">
                  <span class="sr-only">{{step}}</span>
                  <a href="#" @click.prevent="setCurrentNav(index)"><p class="font-medium">{{index + 1}}. {{step}}</p></a>

                  <!-- Check icon for completed steps -->
                  <svg v-if="index < currentStep" class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                  </svg>
                  <!-- Arrow icon for current step -->
                  <svg v-else-if="index === currentStep" class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                  </svg>
                  <!-- No icon for upcoming steps -->
                  <span v-else></span>

                </div>
              </div>
            </li>
          </template>
        </ol>
      </div>

      <div class="w-3/4 bg-white p-4">
        <form @submit.prevent="submitHandler()" action="/" name='chatbot'>
          <transition name="fade" appear>
              <div v-if="botSteps[currentStep] === 'Main Information'">
                <div class="grid gap-4 sm:grid-cols-2 sm:gap-6">
                  <div class="sm:col-span-2">
                    <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                    <input v-model="chatbotName" type="text" name="name" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Type Chatbot name" required>
                    <small class='text-gray-400'>The name of the chatbot will be seen during conversations with customers.</small>
                  </div>
                  <div class="sm:col-span-2">
                    <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                    <textarea v-model="description" id="description" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Your chatbot description"></textarea>
                    <small class='text-gray-400'>The description is for internal usage only.</small>
                  </div>
                </div>
                <div class="sm:col-span-2 py-4" v-if="props.ownApiKey">
                  <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">OpenAI API Key</label>
                  <input v-model="apiKey" type="text" name="name" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="API Key" required>
                  <small class='text-gray-400'>The OpenAI API key you want to use with this bot.</small>
                </div>
                <div class="sm:col-span-2">
                  <label class="mt-4">
                    GPT Model
                    <select v-model="gptModel" required>
                      <option value='' selected>
                        Select a Model
                      </option>
                      <option value='gpt-3.5-turbo-0613'>
                        gpt-3.5-turbo-0613
                      </option>
                      <option value='gpt-4-0613'>
                        gpt-4-0613
                      </option>
                    </select>
                  </label>
                  <small class='text-gray-400'>Choose between the latest GPT3.5 and GPT4 models.</small>
                </div>
                 <div class="sm:col-span-2 py-4">
                    <label for="inbox_id" class="pt-4 block mb-2 text-sm font-medium text-gray-900 dark:text-white">Inbox</label>
                    <VueMultiselect
                        class="mt-2"
                        v-model="selectedInbox"
                        :options="inboxes"
                        placeholder="Select inbox"
                        label="name"
                        track-by="value"
                        :allow-empty="false"
                        >
                    </VueMultiselect>
                </div>
                <div class="sm:col-span-2 pt-4">
                  <label for="contact_requirements" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Required Contact Details</label>
                  <ul class="list-none grid w-full gap-6 md:grid-cols-3">
                      <li>
                          <input v-model="phoneRequired" type="checkbox" id="phone-required-option" value="" class="hidden peer" required="">
                          <label for="phone-required-option" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                              <div class="block">
                                  ☎️ 📱
                                  <div class="pt-4 w-full text-lg font-semibold">Phone Number</div>
                                  <div class="w-full text-sm">Contact Phone</div>
                              </div>
                          </label>
                          <small class='text-gray-400'>Collect Phone Number from user.</small>
                      </li>
                      <li>
                          <input v-model="emailRequired"  type="checkbox" id="email-option" value="" class="hidden peer">
                          <label for="email-option" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                              <div class="block">
                                  📧 ✉️
                                  <div class="pt-4 w-full text-lg font-semibold">Email</div>
                                  <div class="w-full text-sm">Contact Email Address</div>
                              </div>
                          </label>
                          <small class='text-gray-400'>Collect Email from user.</small>
                      </li>
                      <li>
                          <input v-model="companyRequired"  type="checkbox" id="company-option" value="" class="hidden peer">
                          <label for="company-option" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                              <div class="block">
                                  🏤 🏢
                                  <div class="pt-4 w-full text-lg font-semibold">Company</div>
                                  <div class="w-full text-sm">Contact Company</div>
                              </div>
                          </label>
                          <small class='text-gray-400'>Collect Company from user.</small>
                      </li>
                  </ul>
                </div>
              </div>
            </transition>
              <transition name="fade" appear>
              <div v-if="botSteps[currentStep] === 'Settings'">
                <div class="sm:col-span-2">
                  <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Slack Webhook URL</label>
                  <input v-model="slackWebhookUrl" type="text" name="name" id="name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="https://yoursubdomain.slack.com/.......">
                  <small class='text-gray-400'>The webhook URL for slack notifications (optional).</small>
                </div>

                <div class="sm:col-span-2 pt-4">
                  <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Slack Notification Settings</label>
                    <ul class="list-none grid w-full gap-6 md:grid-cols-3">
                        <li>
                            <input v-model="slackMessageNotifications" type="checkbox" id="slack-messages" value="" class="hidden peer">
                            <label for="slack-messages" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                <div class="block">
                                    💬 ✅
                                    <div class="pt-4 w-full text-lg font-semibold">Messages</div>
                                    <div class="w-full text-sm">All Messages</div>
                                </div>
                            </label>
                        </li>
                        <li>
                            <input v-model="slackEventNotifications"  type="checkbox" id="slack-events" value="" class="hidden peer">
                            <label for="slack-events" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                <div class="block">
                                    ⚡️ ✅
                                    <div class="pt-4 w-full text-lg font-semibold">Events</div>
                                    <div class="w-full text-sm">All Events</div>
                                </div>
                            </label>
                        </li>
                      </ul>
                  </div>
                  <div class="sm:col-span-2 pt-4">
                    <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bot Type</label>
                    <ul class="list-none grid w-full gap-6 md:grid-cols-3">
                      <li>
                          <input v-model="chatbotType" type="radio" id="chatbot-hybrid" value="hybrid" class="hidden peer">
                          <label for="chatbot-hybrid" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                              <div class="block">
                                🤖 📚

                                  <div class="pt-4 w-full text-lg font-semibold">Hybrid</div>
                                  <div class="w-full text-sm">Knowledge Enriched + Lead Qual</div>
                              </div>
                          </label>
                      </li>
                      <li>
                          <input v-model="chatbotType" type="radio" id="chatbot-know" value="knowledge" class="hidden peer">
                          <label for="chatbot-know" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                              <div class="block">
                                  📖 🤓
                                  <div class="pt-4 w-full text-lg font-semibold">Knowledge Enriched</div>
                                  <div class="w-full text-sm">Document backed answers</div>
                              </div>
                          </label>
                      </li>
                      <li>
                          <input v-model="chatbotType" type="radio" id="chatbot-lead" value="lead_qualification" class="hidden peer">
                          <label for="chatbot-lead" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                              <div class="block">
                                  💬 🔍
                                  <div class="pt-4 w-full text-lg font-semibold">Lead Qualification</div>
                                  <div class="w-full text-sm">Qualifying Lead as primary goal</div>
                              </div>
                          </label>
                      </li>
                    </ul>
                  </div>
              </div>
              </transition>
              <transition name="fade" appear>
              <div v-if="botSteps[currentStep] === 'Information'">
                <div class="sm:col-span-2 pb-2">
                    <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Pricing Description</label>
                    <textarea v-model="pricingDescription" id="pricing_description" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Any pricing specific context you want to respond to the user with."></textarea>
                    <small class='text-gray-400'>This will help the bot answer pricing related questions.</small>
                </div>

                <div class="sm:col-span-2 py-4">
                    <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Service Area Description</label>
                    <textarea v-model="serviceAreaDescription" id="service_area_description" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="A verbal description of the business' service area."></textarea>
                    <small class='text-gray-400'>This will help the bot answer Service Area related questions.</small>
                </div>

                <div class="sm:col-span-2 pb-4">
                    <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Services Offered Description</label>
                    <textarea v-model="servicesOfferedDescription" id="pricing_description" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Any product or service specific information you want to give the user."></textarea>
                    <small class='text-gray-400'>This will help the bot answer services/product related questions.</small>
                </div>
                  <div class="sm:col-span-2 py-2">
                      <label for="current_promotions" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Current Promotions</label>
                      <textarea v-model="currentPromotions" id="current_promotions" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="We are currently offering 25% off when you refer a friend and 10% for all returning customers."></textarea>
                      <small class='text-gray-400'>What should the bot say when a user is looking for your current discounts/promotions.</small>
                  </div>
              </div>


            </transition>

                <transition name="fade" mode="out-in">
                <div v-if="botSteps[currentStep] === 'Hours'">
                  <div class="sm:col-span-2">
                    <label>
                      Time Zones
                      <select v-model="selectedTimeZone" required>
                        <option v-for="timeZone in timeZones" :key="timeZone" :value="timeZone">
                          {{ timeZone }}
                        </option>
                      </select>
                    </label>
                    <small class='text-gray-400'>The time zone of the chatbot controls when human hand off and conference call generation work.</small>
                  </div>

                  <div class="sm:col-span-2 py-4">
                      <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Business Hours</label>
                      <textarea v-model="businessScheduleDescription" id="business_hours" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="We are open Monday through Friday 8am to 5pm EST and Sunday from 10am to 4pm EST."></textarea>
                      <small class='text-gray-400'>A written description of your company's operating hours.</small>
                  </div>

                  <div class="sm:col-span-2">
                      <label for="description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Holiday Hours</label>
                      <textarea v-model="holidayScheduleDescription" id="holiday_hours" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="We are closed on Thanksgiving and Federal Holidays but open on Christmas and New Years."></textarea>
                      <small class='text-gray-400'>A written description of your company's holiday operating hours.</small>
                  </div>


                  <div class="col-span-2 py-4">
                    <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Days Off Selection</label>
                    <transition-group name="list">
                      <div v-for="(dayOff, index) in daysOff" :key="index" class="mb-4 py-4">
                          <vue3-datepicker v-model="dayOff.date" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Select a date"></vue3-datepicker>
                          <button @click.prevent="removeDayOff(index)" class="mt-4 text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">
                              Remove
                          </button>
                      </div>
                      <button @click.prevent="addDayOff" class="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
                          Add Day Off
                      </button>
                  </transition-group>
                </div>
              </div>
            </transition>
              <transition name="fade" mode="out-in" appear>
              <div v-if="botSteps[currentStep] === 'Actions'">
                <div class="sm:col-span-2">
                  <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Bot Actions</label>
                  <ul class="list-none grid w-full gap-6 md:grid-cols-3">
                    <li>
                       <input type="checkbox" id="appt-option" v-model="apptChecked" class="hidden peer">
                        <label :class="apptChecked ? 'checked' : ''" for="appt-option" class="checkbox-label inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                            <div class="block">
                                📅 🗓️
                                <div class="w-full pt-4 text-lg font-semibold">Appointments</div>
                                <div class="w-full text-sm">Calendar Scheduling</div>
                            </div>
                        </label>
                    </li>
                    <li>
                        <input type="checkbox" id="phone-option" v-model="phoneChecked" class="hidden peer">
                        <label :class="phoneChecked ? 'checked' : ''" for="phone-option" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                            <div class="block">
                                📱 🤳🏻
                                <div class="w-full pt-4 text-lg font-semibold">Phone Calls</div>
                                <div class="w-full text-sm">Create phone calls</div>
                            </div>
                        </label>
                    </li>
                    <li>
                        <input type="checkbox" id="service-option" v-model="serviceChecked" class="hidden peer">
                        <label for="service-option" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                            <div class="block">
                                🤖 🦾
                                <div class="w-full pt-4 text-lg font-semibold">Lead Qualification</div>
                                <div class="w-full text-sm">Qualify leads.</div>
                            </div>
                        </label>
                    </li>
                    <li>
                        <input type="checkbox" id="office-option" v-model="officeChecked" class="hidden peer">
                        <label for="office-option" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                            <div class="block">
                                ⌨️ 👨🏽
                                <div class="w-full pt-4 text-lg font-semibold">Back Office</div>
                                <div class="w-full text-sm">Human hand off.</div>
                            </div>
                        </label>
                    </li>

                </ul>

                <div class="col-span-2 py-4">
                  <div v-if="apptChecked && loadingCalendars" class="col-span-2 py-4">
                    Loading Calendars...
                  </div>
                  <div v-else-if="apptChecked" class="col-span-2 py-4">
                    <div v-if="calendars.length == 0">
                      <a href="/clients/calendars" class="text-blue-600 py-4">Add a calendar to schedule appointments.</a>
                    </div>
                    <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Appointment Calendars</label>
                    <label>

                      <select v-model="selectedCalendars" multiple>
                        <option v-for="calendar in calendars" :key="calendar.value" :value="calendar.value">
                          {{ calendar['name'] }}
                        </option>
                      </select>
                    </label>

                    <div class="py-4">
                      <label for="appt_confirmation_message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Appointment Confirmation Message</label>
                      <textarea v-model="appointmentConfirmationMessage" id="appt_confirmation_message" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Your appointment is confirmed. You will receive an email shortly."></textarea>
                      <small class='text-gray-400'>The message the bot says after a customer successfully schedules an appointment.</small>
                    </div>

                    <div class="pb-4">
                      <label for="event_title" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Event Title</label>
                      <input v-model="eventTitle" type="text" name="event_title" id="event_title" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Title of the event in google calendar" required="">
                      <small class='text-gray-400'>The title of the calendar event.</small>
                    </div>


                    <div >
                      <label for="event_description" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Event Description</label>
                      <textarea v-model="eventDescription" id="event_description" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="The event description that attendees will seein their calendar."></textarea>
                      <small class='text-gray-400'>This will be the calendar event description/summary.</small>
                    </div>
                  </div>
                </div>

                <div class="col-span-2">

                  <div v-if="serviceChecked" class="col-span-2 py-2">
                    <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Service Information</label>
                    <div>
                    <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Business Name</label>
                      <input v-model="businessName" type="text" name="business_name" id="business_name" class="mb-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Business Name" required="">
                    </div>
                    <div>
                      <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Business City</label>
                      <input v-model="businessCity" type="text" name="business_city" id="business_state" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mb-4 focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Business City" required="">
                    </div>
                    <div>
                      <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Business State</label>
                      <input v-model="businessState" type="text" name="business_state" id="business_state" class="mb-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Business State" required="">
                    </div>
                    <div >
                      <label for="service_system_prompt" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Service System Prompt</label>
                      <textarea v-model="serviceSystemPrompt" id="service_system_prompt" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="You are a helpful, polite chatbot for Growth Foundry, a Digital Marketing firm in Hanover, PA (USA)."></textarea>
                    </div>
                    <div class="py-4">
                      <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Lead Qualifiers</label>
                      <transition-group name="list">
                      <div v-for="(value, index) in leadValues" :key="index" class="grid grid-cols-3 gap-4 mb-4">
                        <input v-model="value.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" type="text" placeholder="Name">

                        <select v-model="value.dataType" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                          <option disabled value="">Select type</option>
                          <option value="string">String</option>
                          <option value="boolean">Boolean</option>
                        </select>

                        <textarea v-model="value.description" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Description"></textarea>

                        <button @click.prevent="removeLeadValue(index)" class="text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900">
                          Remove
                        </button>
                      </div>

                      <button @click.prevent="addLeadValue" class="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
                        Add Lead Value
                      </button>

                      <div>
                        <label for="name" class="block mb-2 pt-4 text-sm font-medium text-gray-900 dark:text-white">Webhook Url</label>
                        <input v-model="webhookUrl" type="text" name="webhook_url" id="webhook_url" class="mb-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="https://webhook-url.com/3323-121321-123123-12312" >
                      </div>
                      </transition-group>
                    </div>
                  </div>
                </div>

                <div class="col-span-2">

                  <div v-if="officeChecked" class="col-span-2">
                    <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Human Handoff Office Hours</label>
                    <div>
                    <label for="office_hours_start" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Office Hours Start</label>
                      <input v-model="officeHoursStart" min="0" max="23" type="number" name="office_hours_start" id="office_hours_start" class="mb-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Business Name" required="">
                    </div>
                    <div>
                      <label for="office_hours_end" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Office Hours End</label>
                      <input v-model="officeHoursEnd" min="0" max="23" type="number" name="office_hours_end" id="office_hours_end" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mb-4 focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Business City" required="">
                    </div>

                    <div >
                      <label for="out_of_office_message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Out of Office Message</label>
                      <textarea v-model="outOfOfficeMessage" id="out_of_office_message" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="We are out of the office. Please try again at {{office_hours_start}} AM"></textarea>
                  </div>

                  </div>
                </div>

                <div class="col-span-2 py-4">

                  <div v-if="phoneChecked" class="col-span-2">
                    <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone Bridge</label>
                    <div>
                      <label for="office_hours_calls_start" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Office Hours Start</label>
                        <input v-model="officeHoursCallsStart" min="0" max="23" type="number" name="office_hours_calls_start" id="office_hours_calls_start" class="mb-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required="">
                      </div>
                      <div>
                        <label for="office_hours_calls_end" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Office Hours End</label>
                        <input v-model="officeHoursCallsEnd" min="0" max="23" type="number" name="office_hours_calls_end" id="office_hours_calls_end" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg mb-4 focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" required="">
                      </div>

                      <div >
                        <label for="out_of_office_calls_message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Out of Office Message</label>
                        <textarea v-model="outOfOfficeCallsMessage" id="out_of_office_calls_message" rows="8" class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="We are out of the office. Please try again at {{office_hours_call_start}} AM"></textarea>
                    </div>

                      <div class="pt-8" >
                        <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
                        <input v-model="phoneNumber" type="text" name="phone" id="phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Enter Your Phone Number (+13105551234 e64 format)">
                    </div>

                    <div class='pt-8'>
                      <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Record Calls</label>
                      <div class="flex items-center pl-4 border border-gray-200  my-2 rounded dark:border-gray-700">
                          <input v-model="callRecording" id="bordered-radio-1" type="radio" value="false" name="record-calls" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                          <label for="bordered-radio-1" class="w-full py-4 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Do Not Record</label>
                      </div>
                      <div class="flex items-center pl-4 border border-gray-200 my-2 rounded dark:border-gray-700">
                          <input v-model="callRecording" id="bordered-radio-2" type="radio" value="true" name="record-calls" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                          <label for="bordered-radio-2" class="w-full py-4 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Record All Calls</label>
                      </div>
                    </div>

                    </div>
                  </div>
                </div>
              </div>
            </transition>
              <transition name="fade" mode="out-in" appear>
              <div v-if="botSteps[currentStep] === 'Confirmation'">
                <dl>
                  <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Name</dt>
                  <dd class="font-light text-gray-500 dark:text-gray-400 mb-4">{{ chatbotName }}</dd>
                  <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Description</dt>
                  <dd class="font-light text-gray-500 dark:text-gray-400 mb-4">{{ description }}</dd>
                  <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Type</dt>
                  <dd class="font-light text-gray-500 dark:text-gray-400 mb-4">{{ chatbotType }}</dd>
                  <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Model</dt>
                  <dd class="font-light text-gray-500 dark:text-gray-400 mb-4">{{ gptModel }}</dd>
                  <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Required Contacts</dt>
                  <dd class="font-light text-gray-500 dark:text-gray-400 mb-4">{{ requiredContacts() }}</dd>
                  <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Slack Settings</dt>
                  <dd class="font-light text-gray-500 dark:text-gray-400 mb-4">{{ slackSettings() }}</dd>
                  <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Welcome Message</dt>
                  <dd class="font-light text-gray-500 dark:text-gray-400 mb-4">{{ welcomeIntentMessage }}</dd>
                  <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Fallback Message</dt>
                  <dd class="font-light text-gray-500 dark:text-gray-400 mb-4">{{ fallbackIntentMessage }}</dd>
                  <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Time Zone</dt>
                  <dd class="font-light text-gray-500 dark:text-gray-400 mb-4">{{ selectedTimeZone }}</dd>
                  <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Business Hours</dt>
                  <dd class="font-light text-gray-500 dark:text-gray-400 mb-4">{{ businessScheduleDescription }}</dd>
                  <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Holiday Schedule</dt>
                  <dd class="font-light text-gray-500 dark:text-gray-400 mb-4">{{ holidayScheduleDescription }}</dd>
                  <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Days Off</dt>
                  <dd class="font-light text-gray-500 dark:text-gray-400 mb-4">{{ daysOff.length || 0 }}</dd>
                  <dt class="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Actions</dt>
                  <dd class="font-light text-gray-500 dark:text-gray-400 mb-4">{{ botActions() }}</dd>
              </dl>
              </div>


          </transition>

          <div class='py-2' v-if="currentStep === botSteps.length - 1">
            <button type="submit" class="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-green-700 rounded-lg focus:ring-4 focus:ring-green-200 dark:focus:ring-green-900 hover:bg-green-800">
                Create Bot
            </button>

          </div>

          <div class='my-4'>
            <button class="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"   @click.prevent="prev()" :disabled="currentStep === 0">Previous</button>
            <button class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" @click.prevent="next()" :disabled="currentStep === botSteps.length - 1">Next</button>
          </div>

        </form>
      </div>
    </div>
  </div>

</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s, transform 0.1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
}

.fade-enter-active {
  transition-delay: 0.1s;
}

.fade-enter {
  transform: scale(0.95);
}

.fade-enter-to,
.fade-leave {
  transform: scale(1);
}

.fade-leave-active {
  transform: scale(0.95);
}
</style>