<script setup>

import { ref, watch, onMounted } from 'vue'
import axios from 'axios'
import mdtoast from '@dmuy/toast'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

const selectedCampaign = ref([])
const campaignOptions = ref([])

const props = defineProps({
  organizationId: String
})

onMounted(() => {
  getCampaigns()
  getActiveCampaign()
})

const getCampaigns = () => {
  axios.get('/ajax/lead_campaigns').then((response) => {
    campaignOptions.value = response.data.results
  }).catch((error) => {
    console.log(error)
  })
}

const getActiveCampaign = () => {
  axios.get(`/ajax/organizations/${props.organizationId}/lead_campaign`).then((response) => {
    selectedCampaign.value = response.data.result
  })
}

const updateCampaign = () => {
  axios.patch(`/ajax/organizations/${props.organizationId}/lead_campaign`, {
    selectedCampaign: selectedCampaign.value
  }).then((response) => window.location.reload()
  ).catch((error) => {
    mdtoast.error(error.response.data?.message || 'Unknown error')
  })
}
</script>

<template>
  <h4 class="pt-5">
      Lead Campaigns for this organization
    </h4>
    <label class="typo__label">Select 1 or more campaign.</label>
    <multiselect

    v-model="selectedCampaign"
    :options="campaignOptions"
    :multiple="false"
    :close-on-select="true"
    placeholder="select campaign(s)"
    label="name"
    track-by="name"
    />

    <button @click="updateCampaign" class="mt-3 btn btn-primary">
      Update Campaigns
    </button>
</template>
