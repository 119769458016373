<script> 
import { token } from '@formkit/utils'
import axios from 'axios'

export default {
  mounted() {
    this.getProducts()
  },
  data() {
    return {
      formData: {},
      loading: false,
      remoteProducts: [],
      products: []
    }
  },
  methods: {
    submitHandler(evt) {
      axios.post(`/ajax/campaign_templates`, this.formData)
        .then((response) => {
          
          window.location = `/campaign_templates/${response.data['campaignTemplateId']}`
        }).catch((error) => {
          console.log(error)
        })
    },

    getProducts() {
       axios.get(`/ajax/products`).then((response) => {
        this.remoteProducts = response.data['results']
      }).catch((error) => {
        console.log(error)
      })
    },
  },
  watch: {
  },
  computed: {
    console: () => console,
  }
}
</script>

<template>
  <div v-if="!this.loading" class='wrapper w-full'>
    <FormKit
      type="form"
      v-model="formData"
      :classes="{
        outer: '$reset w-full'
      }"
      submit-label="Create Product"
      :form-class="submitted ? 'hide' : 'show'"
      @submit="submitHandler">
    
      <FormKit
        type="text"
        name="title"
        label="Campaign Title"
        placeholder="Title For This Campaign"
        help="Title for this campaign"
        validation="required"
        outer-class="mb-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"
      />

      <FormKit
        type="textarea"
        label="Campaign Description"
        name="description"
        rows="10"
        placeholder="Write a description for this campaign."
        help="Write a clear description for this campaign."
        validation="required"
        outer-class="mb-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class="border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="h-10 px-3 border-none text-base text-gray-700 placeholder-gray-400"
        help-class="text-xs text-gray-500"
      />

      <FormKit
        v-model="products"
        type="select"
        multiple
        label="Select products for this campaign template"
        name="products"
        :options="remoteProducts"
        help="Select all products you want to include in the campaign template."
        outer-class="my-5 w-2/3"
        label-class="block mb-1 font-bold text-sm"
        inner-class=" border border-gray-400 rounded-lg mb-1 overflow-hidden focus-within:border-blue-500"
        input-class="px-3 border-none text-base text-gray-700 placeholder-gray-400"
      />


      
    </FormKit>
  </div>
  <div v-else>
    <div class="flex justify-center items-center">
      <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<style>
</style>
