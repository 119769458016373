import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'
import axios from 'axios' 

import './custom/draw_charts.js'

const chartData = {} 

export default class extends Controller {
  static targets = [
    'actionsDrivingDirectionsChart', 
    'actionsPhoneChart',
    'actionsWebsiteChart',
    'localPostActionsCallToActionChart',
    'localPostViewsSearchChart',
    'photosCountCustomersChart',
    'photosCountMerchantChart',
    'photosViewsCustomersChart',
    'photosViewsMerchantChart',
    'queriesDirectChart',
    'queriesIndirectChart',
    'queriesChainChart',
    'viewsMapsChart',
    'viewsSearchChart'
  ]

  static values = {
    clientId: String,
    reportId: String,
    locationDatumId: String,
    enabledMetrics: Array
  }

  canvasContext(target) { 
    return this[`${target}Target`].getContext('2d')
  }

  drawCharts() {
    axios.get(`/ajax/clients/${this.clientIdValue}/reports/${this.reportIdValue}/location_charts_data?location_datum_id=${this.locationDatumIdValue}`).then((response) => {
      this.chartData = response.data['results']

      for (const chartMetric of this.constructor.targets) {
        if (!this.enabledMetricsValue.includes(`${chartMetric.replace('Chart', '')}`)) {
          continue
        }
        
        new Chart(this.canvasContext(chartMetric), {
          type: 'line',
          data: this.chartData[`${chartMetric.replace('Chart', '')}`],
          options: {
            responsive: true,
            maintainAspectRatio: false,
          }
        }); 
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    this.drawCharts()
  }

  disconnect() {
  }
}
