// overlay_controller.js
import { Controller } from "@hotwired/stimulus"
import axios from 'axios'
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'

export default class extends Controller {
  static targets = ["modal", "overlay", "triggerPoint", "form", "submitButton", "inputField"];

  connect() {
    this.observer = new IntersectionObserver(this.onIntersection.bind(this), {
      threshold: 0,
    });
    console.log("connected 2 overlay")
    this.observer.observe(this.triggerPointTarget);

    this.checkInitialPosition();
    this.checkIfFormSubmitted();

  }

  onIntersection(entries) {
  for (const entry of entries) {
    if (entry.isIntersecting && localStorage.getItem('formSubmitted') !== 'true') {
      this.showOverlayAndModal();
    }
  }
}

  showOverlayAndModal() {
    this.overlayTarget.classList.remove('hidden');
    this.modalTarget.classList.remove('hidden');
  }

  checkInitialPosition() {
    if (window.scrollY + window.innerHeight >= this.triggerPointTarget.offsetTop) {
      this.showOverlayAndModal();
    }
  }

  submitForm(event) {
    event.preventDefault();
    this.submitButtonTarget.disabled = true;

    this.inputFieldTargets.forEach(input => {
      input.disabled = true;
    });

    const formData = new FormData(this.formTarget);

    const data = {
      email: this.formTarget.querySelector('#email').value,
      name: this.formTarget.querySelector('#name').value
    };

    axios.post("/ajax/leads/thirty_min_marketer", data, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',

      }
    })
    .then(response => {
      if (response.data.success) {
        this.hideOverlayAndModal();
        mdtoast.success('You have subscribed successfully. Thanks and we will talk soon.', { duration: 10000 })
        localStorage.setItem('formSubmitted', 'true');
      } else {
        // handle errors or show a notification to the user
      }
    })
    .catch(error => {
      console.error("Error submitting form:", error);
    });
  }

  hideOverlayAndModal() {

    this.overlayTarget.classList.add('hidden');
    this.modalTarget.classList.add('hidden');
  }

  checkIfFormSubmitted() {
    if (localStorage.getItem('formSubmitted') === 'true') {
      this.hideOverlayAndModal();
    }
  }
}