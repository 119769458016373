<script setup>

import { ref, watch, onMounted, defineProps } from 'vue'
import axios from 'axios'
import qs from 'qs'
import VueSlimTable from 'vue-slim-tables'
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'
import { Modal } from 'flowbite'

onMounted(() => {
  //getConversations()
  //getTimeZones()
})

const ajaxUrlSourcesUrl = `/ajax/guides`
const columns = [
  { key: 'id', title:'' },
  { key: 'title', title: 'Title' },
  { key: 'slug', title: 'Slug' },
  { key: 'metaDescription', title: 'Meta Description' },
  { key: 'published', title: 'Published' },

]

const searchValue = ref('')

const table = ref(null)

let timeout = null

const tableSource = (params) => {
  const path = `${ajaxUrlSourcesUrl}?${qs.stringify({ ...params, search: searchValue.value })}`
  return axios.get(path).then((res) => res.data)
}

watch(searchValue, () => {
  clearTimeout(timeout)
  timeout = setTimeout(refetchTableData, 300)
})

</script>

<template>
  <div>
    <div class="pb-2 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
        Guides
        <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Your Local SEO Indusry Guides</p>
    </div>


    <div class="form-group w-1/3 mb-3">
      <label for="search-websites" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search Guides</label>
    <div class="relative">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
        </div>
        <input v-model="searchValue" type="search" id="search-websites" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search urls..." required>

    </div>
  </div>
  <button v-if="checkedCountUrls" class="btn btn-danger float-left" @click="removeSelectedUrls()">
      Delete selected
    </button>
    <VueSlimTable
      ref="table"
      class="table w-full text-sm text-left text-gray-500 dark:text-gray-400"
      :columns="columns"
      :per-page="10"
      :source="tableSource">
      <template #thead>
          <span class="location-head-name">
            <input type="checkbox" :checked="checkAllUrls" @click.stop="toggleAllUrls">
          </span>
          <th></th>
          <th>State</th>
          <th>Title</th>
          <th>Tokens</th>
          <th>Chunks</th>
          <th>Chars</th>
        </template>

      <template #cell:id="{ row, value}">
          <input type="checkbox" :checked="row.checked" @change="toggleUrl(row)">

        </template>
      <template #cell:aasmState="{ row, value }">
        <div v-if="value == 'trained'">
          <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">Trained</span>

        </div>
        <div v-if="value == 'processing'">
           <span class="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-blue-400">Processing</span>

        </div>

        <div v-if="value == 'queued'">
           <span class="bg-pink-100 text-pink-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-pink-400">Queued</span>

        </div>

        <div v-if="value == 'error'">
           <span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">Failed</span>

        </div>

        <div v-if="value == 'waiting'">
           <span class="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-gray-400">Waiting</span>

        </div>
      </template>

      <template #cell:createdAt="{ row, value }">
        <a @click.prevent="destroyTrainingSource(row['id'], 'url')"
         href="#" class="pb-2 text-xs text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg px-5 py-2.5 inline-flex items-center mr-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900 text-xs">
           <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h16M7 8v8m4-8v8M7 1h4a1 1 0 0 1 1 1v3H6V2a1 1 0 0 1 1-1ZM3 5h12v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V5Z"/>
          </svg>
        </a>
      </template>


    </VueSlimTable>
  </div>

</template>

<style scoped>
  .vst-th {
    font-weight: 200;
  }
</style>

