import { Controller } from '@hotwired/stimulus'
import { createApp } from 'vue'
import App from '../vue_widgets/edit_work_flow.vue'

export default class extends Controller {

  static values = {
    chatbotId: String,
    selectedEvent: String,
    name: String,
    sendImmediately: Boolean,
    timeAfterEvent: Number,
    timeAfterEventUnit: String,
    timeBeforeEvent: Number,
    timeBeforeEventUnit: String,
    actions: Array,
    retryAttempts: Number,
    lastMessage: String,
    timeUnitSinceLastMessage: String,
    timeSinceLastMessage: Number,
  }

  connect(context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return
    console.log('location mgmt widget controller connected.')

    const props = {
      chatbotId: this.chatbotIdValue,
      currentSelectedEvent: this.selectedEventValue,
      currentName: this.nameValue,
      currentSendImmediately: this.sendImmediatelyValue,
      currentTimeAfterEvent: this.timeAfterEventValue,
      currentTimeAfterEventUnit: this.timeAfterEventUnitValue,
      currentTimeBeforeEvent: this.timeBeforeEventValue,
      currentTimeBeforeEventUnit: this.timeBeforeEventUnitValue,
      currentActions: this.actionsValue,
      currentRetryAttempts: this.retryAttemptsValue,
      currentLastMessage: this.lastMessageValue,
      currentTimeSinceLastMessage: this.timeSinceLastMessageValue,
      currentTimeUnitSinceLastMessage: this.timeUnitSinceLastMessageValue

    }

    this.vue = createApp(App, props)
    this.vue.mount(this.element)
  }

  disconnect() {
    this.vue?.unmount()
  }
}
