import { Controller } from '@hotwired/stimulus'
import axios from 'axios'
import '@dmuy/toast/dist/mdtoast.css'
import mdtoast from '@dmuy/toast'

export default class extends Controller {
  static targets = [
    'checkbox',
    'label'
  ]

  static values = {
    enabled: { type: Boolean, default: false },
    pageType: { type: String, default: '' },
    organizationId: { type: String, default: '' },
    enabledText: { type: String, default: '' },
    disabledText: { type: String, default: '' },
    endpoint: { type: String, default: '' }
  }

  connect(_context) {
    if (document.documentElement.hasAttribute('data-turbo-preview')) return

    if (this.enabledValue) {
      this.checkboxTarget.checked = true
      this.labelTarget.innerText = this.enabledTextValue
    }
  }

  disconnect() {
  }

  toggle(evt) {
    evt.preventDefault()

    axios.get(`/organizations/${this.organizationIdValue}/${this.endpointValue}`)
      .then((response) => {
        if (response.data.current) {
          this.labelTarget.innerText = this.enabledTextValue
          mdtoast.success(this.enabledTextValue, { duration: 10000 })
        } else {
          this.labelTarget.innerText = this.disabledTextValue
          mdtoast.error(this.disabledTextValue, { duration: 10000 })
        }
      }).catch((error) => {
        console.log(error) // eslint-disable-line no-console
      })
  }
}
